import React, {useRef} from 'react';
import { Grid } from '@material-ui/core';
import { ProcessorInfoProps } from '../../common/dragonpay/ProcessorInfo';
import { numberWithCommas } from '../../common/Util';

const GCashImage = require('../../assets/gcash-image.png');
const PayMayaImage = require('../../assets/paymaya_logo.png');
const GrabPayImage = require('../../assets/grabpay-image.png');
const CardImage = require('../../assets/card_image.png');

const PaymentMethodView = (props: { processor: any }) => {
  const {processor} = props;
  const child1 = useRef(null);

  const renderImage = ( ) => {
    switch (processor?.procId?._text) {
      case 'CC':
        return <img alt={processor.longName._text} src={CardImage} style={{ marginLeft: 7, height: 20, position: "absolute", marginTop: 0 }}/>;
      case 'GCSH':
        return <img alt={processor.longName._text} src={GCashImage} style={{ marginLeft: 7, height: 20, position: "absolute", marginTop: 0 }}/>;
      case 'PYMY':
        return <img alt={processor.longName._text} src={PayMayaImage} style={{ marginLeft: 7, height: 20, position: "absolute", marginTop: 0 }}/>;
      case 'GRPY':
        return <img alt={processor.longName._text} src={GrabPayImage} style={{ marginLeft: 7, height: 20, position: "absolute", marginTop: 0 }}/>;
      default:
        return <img alt={processor.longName._text} src={processor?.logo._text} style={{ alignSelf: 'center', marginLeft: 7, height: 20, position: "absolute", marginTop: 0 }}/>;
    }
  }

  return (
    <>
      <Grid item md={12} xs={12} style={{ marginBottom: 10, marginTop: 20 }}>
        <b>Payment Method: </b>
        {processor.longName._text}
        {renderImage()}
      </Grid>
      <div style={{  
        padding: 10,
        borderRadius: 10,
        borderColor: 'gray',
        borderWidth: 1,
        borderStyle: 'solid'
      }}>
        <Grid item md={12} xs={12} style={{ marginBottom: 10, marginTop: 8 }}>
          <b>Remarks: </b>
          <span style={{ fontSize: '1em', color: 'black'}}> 
            <span id="remark" ref={child1} dangerouslySetInnerHTML={{ __html: processor.remarks._text }} style={{ fontSize: '1em', color: 'black'}} />
          </span> 
        </Grid>
        <Grid item md={12} xs={12} style={{ marginBottom: 10, marginTop: 8 }}>
          <b>Max Amount: </b>
          ₱{numberWithCommas(processor.maxAmount._text)}
        </Grid>
        <Grid item md={12} xs={12} style={{ marginBottom: 10, marginTop: 8 }}>
          <b>Min. Amount: </b>
          ₱{numberWithCommas(processor.minAmount._text)}
        </Grid>
      </div>
    </>
  );
};

export default PaymentMethodView;
