import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import { numberWithCommas } from '../../common/Util';

const StyledTitle = styled.div`
  font-size: 1.5rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  margin-bottom: 10px;
  padding-top: 20px;
`;

const AreaChart: React.FC<{ areaPercentage: any }> = ({ areaPercentage }) => {
  let data = [];

  if (areaPercentage?.success) {
    data = areaPercentage.data.reverse().map((item: any) => {
      return {
        id: item.Area === 'Outside CLC' ? item.Area : `Area ${item.Area}`,
        label: item.Area === 'Outside CLC' ? item.Area : `Area ${item.Area}`,
        value: item.total
      };
    });
  }

  return (
    <Paper>
      <StyledTitle>Percentage Per Area</StyledTitle>
      <div style={{ height: 700 }}>
        <ResponsivePie
          data={data}
          margin={{ top: 20, right: 60, bottom: 80, left: 60 }}
          innerRadius={0.1}
          padAngle={0.7}
          cornerRadius={3}
          colors={[
            '#61cdbb',
            '#f47560',
            '#3498db',
            '#f1c40f',
            '#95a5a6',
            '#9b59b6',
            '#2ecc71',
            '#f39c12',
            '#e74c3c',
            '#D4A281',
            '#f47983'
          ]}
          // colors={{ scheme: 'nivo' }}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0]] }}
          radialLabelsSkipAngle={10}
          radialLabelsTextXOffset={6}
          radialLabelsTextColor='#333333'
          radialLabelsLinkOffset={0}
          radialLabelsLinkDiagonalLength={16}
          radialLabelsLinkHorizontalLength={24}
          radialLabelsLinkStrokeWidth={1}
          radialLabelsLinkColor={{ from: 'color' }}
          slicesLabelsSkipAngle={10}
          slicesLabelsTextColor='#333333'
          animate={true}
          sliceLabel={d => `₱ ${numberWithCommas(d.value)}`}
          motionStiffness={90}
          motionDamping={15}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
            }
          ]}
          // fill={[
          //   {
          //     match: {
          //       id: 'compliant'
          //     },
          //     id: 'dots'
          //   },
          //   {
          //     match: {
          //       id: 'compliant'
          //     },
          //     id: 'lines'
          //   },
          //   {
          //     match: {
          //       id: 'non-compliant'
          //     },
          //     id: 'lines'
          //   }
          // ]}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              translateY: 56,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000'
                  }
                }
              ]
            }
          ]}
        />
      </div>
    </Paper>
  );
};

export default AreaChart;
