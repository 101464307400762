import axios from 'axios';
import { PUBLIC_KEY } from '../contant';

export const AttachToPaymentIntent = async (
  clientKey: string,
  paymentMethodId: string
) => {
  // Get the payment intent id from the client key
  var paymentIntentId = clientKey.split('_client')[0];

  try {
    return await axios
      .post(
        'https://api.paymongo.com/v1/payment_intents/' +
          paymentIntentId +
          '/attach',
        {
          data: {
            attributes: {
              client_key: clientKey,
              payment_method: paymentMethodId
            }
          }
        },
        {
          headers: {
            Authorization: `Basic ${PUBLIC_KEY}`
          }
        }
      )
      .then(function (response) {
        return { success: true, data: response.data.data };
      });
  } catch (error) {
    return {
      success: false,
      statusCode: error.response.status,
      url: error.response.config.url,
      errors: error.response.data.errors
    };
  }
};
