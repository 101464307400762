import { CLIENT_URL } from '../contant';

export const numberWithCommas = (x: any) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const twoDecimalPlaces = (x: any): number => {
  return parseFloat(parseFloat(x).toFixed(2));
};

export const redirectToSuccessPage = () => {
  const url = CLIENT_URL + '/success';
  window.location.href = url;
};

export const redirectToThankYouPage = () => {
  const url = CLIENT_URL + '/thank-you';
  window.location.href = url;
};
