import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  color: #31a27c;
  font-size: 14px;
  .footer-container {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
  img {
    margin-left: 0.2rem;
    width: 5rem;
    vertical-align: middle;
    margin-bottom: 2px;
  }
`;
const PaymongoFooter = () => {
  return (
    <StyledFooter>
      <div className='footer-container'>
        <div className='left'>
          <span>Secured Payment</span>
        </div>
        <div className='right'>
          <span>Powered by Dragonpay</span>
          {/* <img alt='' src={require('../../assets/paymongo-green.png')} /> */}
        </div>
      </div>
    </StyledFooter>
  );
};

export default PaymongoFooter;
