import React from 'react';
import {
  Button,
  makeStyles
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles((theme: any) => ({
  delete: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  deleteIcon: {
    marginLeft: 5
  }
}));

export interface DeleteConfirmModalProps {
  open: boolean;
  title?: string;
  label?: string;
  onCancel: () => void;
  onDelete: () => void;
}

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({ open, onCancel, onDelete, title, label }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onCancel}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title || 'Confirm Delete'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {label || 'Are you sure you want to delete this item?'}
        </DialogContentText>
        </DialogContent>
        <DialogActions style={{padding: 10}}>
          <Button onClick={onCancel} variant="outlined">
            Cancel
          </Button>
          <Button
            className={classes.delete}
            onClick={() => onDelete()}
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmModal;