import { PaymongoPaymentMethodEnum } from '../interface/paymentMethod.enum';

export const genTransactionRef = (id: any, paymentMethod: string) => {
  var s = id + '';
  while (s.length < 10) s = '0' + s;
  return paymentMethod.substr(0, 2).toUpperCase() + s;
};

export const generateShortRef = (id: any, paymentMethod: string) => {
  return paymentMethod.substr(0, 2).toUpperCase() + id;
};

export const generateReferanceNo = (id: any, paymentMethod: any) => {
  let transactionRef;

  if (
    paymentMethod === PaymongoPaymentMethodEnum.payMaya
    // TODO: GCash - use this if not Paymongo
    // paymentMethod === PaymentMethodEnum.gcash
  ) {
    transactionRef = generateShortRef(id, paymentMethod);
  } else {
    transactionRef = genTransactionRef(id, paymentMethod);
  }

  return transactionRef;
};
