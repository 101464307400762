import { format } from 'date-fns';

const DATE_FORMAT = 'MMM d, yyyy';
const DATETIME_FORMAT = `${DATE_FORMAT} h:mm a`;

export const formatSize = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const formatDate = (
  value: string | Date | null | undefined,
  withTime: boolean = false
) => {
  if (!value) return '';
  const dateFormat = withTime ? DATETIME_FORMAT : DATE_FORMAT;
  return format(
    typeof value === 'string' ? new Date(value) : value,
    dateFormat
  );
};

export const formatExportDate = (value: string | Date | null | undefined) => {
  if (!value) return '';
  const dateFormat = 'yyyy-MM-dd';
  return format(
    typeof value === 'string' ? new Date(value) : value,
    dateFormat
  );
};
