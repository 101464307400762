import React, { useContext } from 'react';
import { RouteProps, Route, Redirect } from 'react-router';
import { GuardFunction } from '../guards/guard';
import { UserContext } from '../user/User.context';

type AdminRouteProps = RouteProps & {
  guards?: Array<GuardFunction>;

  // Will use to redirect when all guards failed to activate
  // By default it will redirect to `/login`
  redirectTo?: string;
};

function AdminRoute(props: AdminRouteProps) {
  // console.log('Admin Route render');
  // const { guards = [] } = props;
  //  guards.some(guard => guard().canActivate());

  const userContext = useContext(UserContext);
  const { user } = userContext;
  const isAllowed = !!user && !!user.role && user.role === 'Admin';

  if (!isAllowed) {
    return <Redirect to='/404' />;
  }
  return <Route {...props} />;
}

export default AdminRoute;
