import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, TextField } from '@material-ui/core';
import * as Yup from 'yup';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useLogin, LoginRequest } from '../user/User.hooks';
import * as Styled from './Login.styled';
import logo from '../assets/logo';
import { UserRole } from '../secure-portal/users/user.interface';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid e-mail address.')
    .required('E-mail address is required.'),
  password: Yup.string().required('Password is required.')
});

const Login: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { user, isLoading, error, doLogin } = useLogin();

  useEffect(() => {
    if (error) {
      setErrorMessage('Login failed. Please double check your credentials.');
    }
  }, [error]);

  if (user) {
    if (user && user.role) {
      if (user.role === UserRole.admin) {
        return <Redirect to='/portal/dashboard' />;
      } else if (user.role === UserRole.controller || UserRole.accounting)
        return <Redirect to='/portal/dashboard' />;
    } else {
      setErrorMessage('You do not have sufficient permissions.');
    }
  }

  return (
    <Styled.StyledLoginContainer>
      <Styled.StyledLoginCard>
        <Avatar style={styles.lockIcon}> 
          <LockOutlinedIcon />
        </Avatar>
        <img src={logo} alt='CLC' className='logo' />
        <h2 style={{ color: 'gray' }}>Login</h2>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={loginSchema}
          validateOnBlur={true}
          onSubmit={(form: LoginRequest) => {
            setErrorMessage(undefined);
            doLogin(form.email, form.password);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Styled.StyledLoginForm>
              <TextField
                variant='outlined'
                type='email'
                name='email'
                label='Email'
                className='form-field'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email && !!errors.email}
                helperText={errors.email}
              />
              <TextField
                variant='outlined'
                type='password'
                name='password'
                label='Password'
                className='form-field'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={touched.password && !!errors.password}
                helperText={errors.password}
              />
              {!isLoading && error && <p className='error'>{errorMessage}</p>}
              <Button
                variant='contained'
                disableElevation
                type='submit'
                disabled={isLoading}
              >
                Login
              </Button>
            </Styled.StyledLoginForm>
          )}
        </Formik>

        {/* <Link className='forgot-password-link' href='/password/recover'>
          Forgot Password?
        </Link> */}
      </Styled.StyledLoginCard>
    </Styled.StyledLoginContainer>
  );
};

const styles = {
  lockIcon: {
    marginBottom: 20,
    backgroundColor: '#2196f3'
  }
};

export default Login;
