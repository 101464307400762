import { GCashTransaction, ExportGCashTransactionDto } from './gcash.interface';
import { useApiGet, useApiPost, ApiResult } from '../../common/api/Api.hooks';
import { PaginatedList } from '../../common/PaginatedList.interface';
import { reloadApp } from './../../user/User.hooks';
import { useEffect } from 'react';

interface ApiResponse {
  success: boolean;
  data?: any;
}

export function useFindGCashTransaction() {
  const { data, isLoading, error, get } = useApiGet<
    PaginatedList<GCashTransaction>
  >('transaction/gcash');
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  return { transactionList: data, isLoading, error, get };
}

export function useExportTransactions(): [
  ApiResult<any>,
  (date: ExportGCashTransactionDto) => void
] {
  const { post: apiPost, error, ...result } = useApiPost<
    ExportGCashTransactionDto
  >('transaction/export-gcash');
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  const post = (date: ExportGCashTransactionDto) => apiPost(date);
  return [result, post];
}
