import { useApiGet, useApiPost, useApiPut, useApiDelete } from '../../common/api/Api.hooks';
import { PaginatedList } from '../../common/PaginatedList.interface';
import { Church } from './church.interface';

export function useFindChurch() {
  const { data, isLoading, error, get } = useApiGet<PaginatedList<Church>>('church');
  return { churchList: data, isLoading, error, get };
}

export function useCreateChurch() {
  const { data, isLoading, error, post } = useApiPost<Church>('church');
  return { response: data, isLoading, error, post };
}

export function useUpdateChurch(id: number) {
  const { data, isLoading, error, put } = useApiPut<Church>(`church/${id}`);
  return { response: data, isLoading, error, put };
}

export function useDeleteChurch(id: number) {
  const { data, isLoading, error, del } = useApiDelete<Church>(`church/${id}`);
  return { response: data, isLoading, error, del };
}

export function useFindAllChurch() {
  const { data, isLoading, error, get } = useApiGet<PaginatedList<Church>>('church/all');
  return { list: data, isLoading, error, get };
}
