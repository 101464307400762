import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Paper, IconButton, InputBase, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useDebounce } from './Debounce.hooks';

const StyledSearchBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin: 0 20px;
  .search {
    flex: 1;
    display: flex;
    padding: 5px;
    max-width: 420px;
    .MuiInputBase-root {
      width: 100%;
    }
  }
`;

export interface SearchBoxProps {
  value?: string;
  onSearch?: (event: any) => void;
  onSubmit?: () => void;
  onSelect?: (selected: any) => void;
}

const TransactionSearchBox: React.FC<SearchBoxProps> = ({
  value,
  onSearch,
  onSubmit,
  onSelect
}) => {
  // Create a ref that store the onSearch handler
  const searchHandler = useRef<(event: any) => void>();
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    searchHandler.current = onSearch;
  }, [onSearch]);

  const [forceSearch, setForceSearch] = useState(false);
  const [search, setSearch] = useState(value);
  const [searchType, setSearchType] = useState('id');
  const debouncedSearch = useDebounce(search ? search : '', 300);
  useEffect(() => {
    if ((forceSearch || debouncedSearch) && searchHandler.current) {
      searchHandler.current(debouncedSearch);
    }
  }, [debouncedSearch, forceSearch]);
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearchType(event.target.value as string);
    onSelect && onSelect(event.target.value as string);
  };

  return (
    <StyledSearchBox className='search-box'>
      <Paper className='search'>
        <IconButton aria-label='search' size='small'>
          <SearchIcon />
        </IconButton>
        <InputBase
          id='search-textfield'
          placeholder='Search'
          type='search'
          value={search || ''}
          onChange={handleSearch}
          onKeyPress={(event: any) => {
            event.key === 'Enter' && setForceSearch(true);
            event.key === 'Enter' && onSubmit && onSubmit();
          }}
        />
        <Select
          value={searchType}
          onChange={handleSelectChange}
          style={{ width: 150, marginLeft: 10 }}
        >
          <MenuItem value={'id'}>Transaction ID</MenuItem>
          <MenuItem value={'reference_number'}>Reference No.</MenuItem>
          <MenuItem value={'last_name'}>Last Name</MenuItem>
          <MenuItem value={'first_name'}>First Name</MenuItem>
          <MenuItem value={'email'}>Email</MenuItem>
          <MenuItem value={'church_name'}>Church</MenuItem>
          <MenuItem value={'area'}>Area</MenuItem>
          <MenuItem value={'contact_number'}>Contact Number</MenuItem>
        </Select>
      </Paper>
      <Button
        variant='contained'
        color='primary'
        style={{ marginLeft: 10 }}
        onClick={() => onSubmit && onSubmit()}
      >
        Search
      </Button>
    </StyledSearchBox>
  );
};

export default TransactionSearchBox;
