import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  CircularProgress
} from '@material-ui/core';
import PageTemplate from '../../common/page-template/PageTemplate';
import PageTemplateCardOver from '../../common/page-template/PageTemplateCardOver';
import PageTemplateHeader, {
  PageTemplateHeaderActions
} from '../../common/page-template/PageTemplateHeader';

import { useFindUsers } from '../../user/User.hooks';
import UserCreateDialog from './UserCreateDialog';
import SearchBox from '../../common/SearchBox';
import { User } from '../../user/User.interface';

const CenterText = styled.div`
  text-align: center;
`;

const UserListPage: React.FC = () => {
  const [searchHolder, setSearchHolder] = useState<any>('');
  const [search, setSearch] = useState<any>('');
  const [searchType, setSearchType] = useState<any>('id');
  const [page, setPage] = useState<any>();
  const [limit, setLimit] = useState<any>();
  const [order, setOrder] = useState<any>();
  const [orderDirection, setOrderDirection] = useState<any>();

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const { userList, get } = useFindUsers(true);

  const handleSort = (column: string) => {
    setOrder(column);
    setOrderDirection(orderDirection === 'ASC' ? 'DESC' : 'ASC');
  };

  const getSortDirection = (column: string): 'asc' | 'desc' => {
    return order === column && orderDirection === 'DESC' ? 'desc' : 'asc';
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  return (
    <PageTemplate>
      <PageTemplateHeader>
        <h2>Users</h2>
        <SearchBox value={search} onSearch={value => setSearch(value)} />
        <PageTemplateHeaderActions>
          <Button
            variant='contained'
            color='primary'
            onClick={() => setIsOpenDialog(true)}
          >
            Add User
          </Button>
        </PageTemplateHeaderActions>
      </PageTemplateHeader>
      <PageTemplateCardOver>
        <TableContainer style={{ minHeight: '70vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={order === 'firstName'}
                    direction={getSortDirection('firstName')}
                    onClick={() => handleSort('firstName')}
                  >
                    First Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === 'lastName'}
                    direction={getSortDirection('lastName')}
                    onClick={() => handleSort('lastName')}
                  >
                    Last Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === 'email'}
                    direction={getSortDirection('email')}
                    onClick={() => handleSort('email')}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell>Role</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {userList &&
                userList.map((user: User) => (
                  <TableRow hover key={user.id}>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PageTemplateCardOver>

      {isOpenDialog && (
        <UserCreateDialog
          open={isOpenDialog}
          onCancel={() => setIsOpenDialog(false)}
          onCreated={() => {
            setIsOpenDialog(false);
            get(); // refresh data
          }}
        />
      )}
    </PageTemplate>
  );
};

export default UserListPage;
