import React, { useContext } from 'react';
import { RouteProps, Route, Redirect } from 'react-router';
import { GuardFunction } from '../guards/guard';
import { UserContext } from '../user/User.context';
import { UserRole } from '../secure-portal/users/user.interface';

type ProtectedRouteProps = RouteProps & {
  guards?: Array<GuardFunction>;

  // Will use to redirect when all guards failed to activate
  // By default it will redirect to `/login`
  redirectTo?: string;
};

function ProtectedRoute(props: ProtectedRouteProps) {
  // console.log('ProtectedRoute render');
  // const { guards = [] } = props;
  //  guards.some(guard => guard().canActivate());

  const userContext = useContext(UserContext);
  const { user } = userContext;
  const isAllowed =
    (!!user && !!user.role && user.role === UserRole.admin) ||
    (!!user && !!user.role && user.role === UserRole.controller) ||
    (!!user && !!user.role && user.role === UserRole.accounting);

  if (!isAllowed) {
    return <Redirect to='/login' />;
  }
  return <Route {...props} />;
}

export default ProtectedRoute;
