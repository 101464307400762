import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';
import { formatDate } from '../../common/formatter';
import { numberWithCommas } from '../../common/Util';

const StyledText = styled(Typography)`
  font-size: 15px;
`;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DragonpayDetailDialogProps {
  transaction: any;
  open: boolean;
  onCancel: () => void;
}

const DragonpayDetailDialog: React.FC<DragonpayDetailDialogProps> = ({
  transaction,
  open,
  onCancel
}) => {
  const handleClose = () => {
    onCancel();
  };

  // const Logo = () => {
  //   return (
  //     <Grid item md={12} xs={12}>
  //       <div style={{ textAlign: 'center', marginBottom: 20 }}>
  //         <img
  //           alt=''
  //           src={require('../../assets/paymongo-green.png')}
  //           style={{ height: 30 }}
  //         />
  //       </div>
  //     </Grid>
  //   );
  // };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          ID: {transaction?.txnid}
        </DialogTitle>
        <DialogContent dividers>
          {/* <Logo /> */}
          { transaction?.referenceNumber && (
            <StyledText gutterBottom>
            <b>Reference Number: </b>
            <span style={{ textTransform: 'uppercase' }}>
              {transaction.referenceNumber}
            </span>
          </StyledText>
          )}
          <StyledText gutterBottom>
            <b>Payment Method: </b>
            <span style={{ textTransform: 'uppercase' }}>
              {transaction.paymentMethod}
            </span>
          </StyledText>
          <StyledText gutterBottom>
            <b>Last Name: </b>
            {transaction.lastName}
          </StyledText>
          <StyledText gutterBottom>
            <b>First Name: </b>
            {transaction.firstName}
          </StyledText>
          <StyledText gutterBottom>
            <b>Email: </b>
            {transaction.email}
          </StyledText>
          <StyledText gutterBottom>
            <b>Contact Number: </b>
            {transaction.contactNumber ? transaction.contactNumber : ''}
          </StyledText>
          <StyledText gutterBottom>
            <b>Church: </b>
            {transaction.churchName ? transaction.churchName : ''}
          </StyledText>
          <StyledText gutterBottom>
            <b>Area: </b>
            {transaction.area ? transaction.area : ''}
          </StyledText>
          <StyledText gutterBottom>
            <b>Date: </b>
            {formatDate(transaction.createdAt, true)}
          </StyledText>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Allocation</TableCell>
                  <TableCell align='right'>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Tithe:
                  </TableCell>
                  <TableCell align='right'>
                    ₱ {numberWithCommas(transaction.tithe)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Offering:
                  </TableCell>
                  <TableCell align='right'>
                    ₱ {numberWithCommas(transaction.offering)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    {'Other'}:{' '}
                    {transaction.otherDescription &&
                      '(' + transaction.otherDescription + ')'}
                  </TableCell>
                  <TableCell align='right'>
                    ₱ {numberWithCommas(transaction.otherAmount)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    <b>Total Amount:</b>
                  </TableCell>
                  <TableCell align='right'>
                    <b>₱ {numberWithCommas(transaction.total)}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default DragonpayDetailDialog;
