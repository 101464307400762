import { FormControlLabel, Radio , Button} from '@material-ui/core';
import React,  {useState} from 'react'

const PaymentOptions = ({ processors }: any) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div>
      <FormControlLabel
        value={'card'}
        control={<Radio color='primary' />}
        label={<div>Credit/Debit Card<img alt='' src={'/static/media/card_image.6ac58f88.png'} height={15} style={{ width: 'auto', marginLeft: 10, position: 'relative', top: 3}}/> </div>}
        style={{ display: 'flex'}}
      />
      <FormControlLabel
        value={'gcash'}
        control={<Radio color='primary' />}
        label={<div>GCash<img alt='' src={'/static/media/gcash-image.9888faaf.png'} height={15} style={{ width: 'auto', marginLeft: 10, position: 'relative', top: 3}}/> </div>}
        style={{ display: 'flex'}}
      />
      {/* <FormControlLabel
        value={'paymaya'}
        control={<Radio color='primary' />}
        label={<div>PayMaya<img alt='' src={'/static/media/paymaya_logo.3c4de2e9.png'} height={15} style={{ width: 'auto', marginLeft: 10, position: 'relative', top: 3}}/> </div>}
        style={{ display: 'flex'}}
      /> */}
      <FormControlLabel
        value={'grab_pay'}
        control={<Radio color='primary' />}
        label={<div>Grab Pay<img alt='' src={'/static/media/grabpay-image.69dd6323.png'} height={15} style={{ width: 'auto', marginLeft: 10, position: 'relative', top: 3}}/> </div>}
        style={{ display: 'flex'}}
      />
      {processors?.slice(0, showMore ? processors?.length : 15)?.map((option: any) => {
        return (
          <FormControlLabel
            value={option.procId._text}
            control={<Radio color='primary' />}
            label={<div>
              {option.longName._text}
              <img alt='' src={option.logo._text} height={15} style={{ width: 'auto', marginLeft: 10, position: 'relative', top: 3}}/> 
              </div>}
            style={{ display: 'flex'}}
  
          />
        )
      })}
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center'}}>
        <Button
          size='small'
          color='primary' 
          variant='outlined'
          style={{ display: 'flex', alignSelf: 'center', marginTop: 15 }} 
          onClick={() => setShowMore(!showMore)}
        >
          Show {showMore ? 'less' : 'more'}
        </Button>
      </div>
    </div>
  )
}

export default PaymentOptions;
