import { SERVER_URL, CLIENT_URL } from './../contant';

export const redirectToSuccessPage = () => {
  const url = CLIENT_URL + '/success';
  window.location.href = url;
};

export const CreateCardPayment = async (paymentIntentId: string) => {
  const formBody = {
    paymentIntentId: paymentIntentId
  };
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(formBody)
  };
  try {
    return await fetch(SERVER_URL + '/api/payment', requestOptions)
      .then(response => response.json())
      .then(data => {
        redirectToSuccessPage();
        if (data.success) {
          return { success: true, data };
        }
      })
      .catch(error => {
        redirectToSuccessPage();
        return { success: false, error };
      });
  } catch (error) {
    redirectToSuccessPage();
    return { success: false, error };
  }
};
