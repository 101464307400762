import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useMarkAsUnpaid } from '../secure-portal/transaction/Transaction.hook';
import { CircularProgress } from '@material-ui/core';
import MessageAlert from './MessageAlert';

export interface MarkAsUnpaidProps {
  transactionId: number;
  onSuccess: (data: any) => void;
}

const MarkAsUnpaid: React.FC<MarkAsUnpaidProps> = ({
  transactionId,
  onSuccess
}) => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const {
    transaction,
    isLoading: isUpdating,
    error: updateError,
    patch
  } = useMarkAsUnpaid(transactionId);

  useEffect(() => {
    if (transaction?.success) {
      transaction && onSuccess(transaction.data);
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  useEffect(() => {
    updateError && setErrorMessage(updateError?.message);
  }, [updateError]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    patch();
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{
          backgroundColor: '#ffb613',
          color: 'white',
          padding: 5,
          margin: 5,
          marginTop: 10,
          width: 170
        }}
      >
        Mark as Unpaid
      </Button>
      <Dialog
        open={open}
        maxWidth={'xs'}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>{'Confirm'}</DialogTitle>
        <DialogContent>
          Are you sure you want to mark this as unpaid?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          {isUpdating ? (
            <CircularProgress />
          ) : (
            <Button onClick={handleSubmit} color='primary'>
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <MessageAlert
        title={'Error'}
        open={Boolean(errorMessage)}
        body={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </div>
  );
};

export default MarkAsUnpaid;
