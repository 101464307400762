import { useApiGet, useApiPost, useApiPut, useApiDelete } from '../../common/api/Api.hooks';
import { PaginatedList } from '../../common/PaginatedList.interface';
import { District } from './district.interface';

export function useFindDistrict() {
  const { data, isLoading, error, get } = useApiGet<PaginatedList<District>>('district');
  return { districtList: data, isLoading, error, get };
}

export function useCreateDistrict() {
  const { data, isLoading, error, post } = useApiPost<District>('district');
  return { response: data, isLoading, error, post };
}

export function useUpdateDistrict(id: number) {
  const { data, isLoading, error, put } = useApiPut<District>(`district/${id}`);
  return { response: data, isLoading, error, put };
}

export function useDeleteDistrict(id: number) {
  const { data, isLoading, error, del } = useApiDelete<District>(`district/${id}`);
  return { response: data, isLoading, error, del };
}

export function useFindAllDistrict() {
  const { data, isLoading, error, get } = useApiGet<PaginatedList<District>>('district/all');
  return { list: data, isLoading, error, get };
}
