import React, { createContext, useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import { useGetCurrentUser } from './User.hooks';
import api from '../common/api/api';
import { User } from './User.interface';
import styled from 'styled-components';

const StyledSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export type UserContextType = User | null | undefined;

export interface UserContextState {
  user?: UserContextType;
  setUser: React.Dispatch<React.SetStateAction<UserContextType>>;
}

export const UserContext = createContext<UserContextState>({
  user: undefined,
  setUser: () => {}
});

interface UserProviderProps {
  children: JSX.Element;
}

export default function UserProvider(props: UserProviderProps): JSX.Element {
  const { children } = props;
  const [userContext, setUser] = useState<UserContextType>(undefined);

  const { user, get } = useGetCurrentUser();
  const accessToken = api.getAccessToken();
  useEffect(() => {
    if (accessToken) {
      get();
    } else {
      setUser(null);
    }
  }, [accessToken, get]);

  useEffect(() => {
    setUser(user);
  }, [setUser, user]);

  if (userContext === undefined) {
    // Do not load the children of the provider if the user is undefined.
    // Lets further wait...
    // undefined = we have not determined the login state yet
    // null = not logged in
    // User value = logged in
    return (
      <StyledSpinnerContainer>
        <CircularProgress size={64} />
      </StyledSpinnerContainer>
    );
  }

  return (
    <UserContext.Provider
      value={{
        user: userContext,
        setUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
