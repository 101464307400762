import { SERVER_URL } from '../contant';

const CREATE_TRANSACTION = SERVER_URL + '/api/transaction';

export interface ResponsePostTransaction {
  success: boolean;
  data: {
    transaction_id: number;
    transaction_ref: string;
  };
}

export const requestOptions = (method: any, body: any) => {
  return {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(body)
  };
};

// const requestOptions = (method: any) => {
//   return {
//     method: method,
//     headers: {
//       'Content-Type': 'application/json',
//       Accept: 'application/json'
//     }
//   };
// };

// export const ApiPostTransaction = async () => {
//   const response = await fetch(CREATE_TRANSACTION, requestOptions('POST'))
//     .then(response => response.json())
//     .then(data => data);
//   return response;
// };
