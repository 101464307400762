import { Form, Formik, FormikValues } from 'formik';
import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import FormikButton from '../../../common/formik/FormikButton';
import FormikGrid from '../../../common/formik/FormikGrid';
import FormikTextField from '../../../common/formik/FormikTextField';
import { District } from '../district.interface';
import { useUpdateDistrict } from '../district.hook';

interface CreateUserForm {
  name: string;
  area: string;
}

const validationSchema: Yup.ObjectSchema<CreateUserForm> = Yup.object().shape({
  name: Yup.string().required('Required'),
  area: Yup.string().required('Required'),
});

export interface DistrictCreateDialogProps {
  district: District | null;
  open: boolean;
  onCancel?: () => void;
  onCreated?: (church: District) => void;
}

const EditDistrictDialog: React.FC<DistrictCreateDialogProps> = ({
  district,
  open,
  onCancel,
  onCreated
}) => {
  const formikRef = useRef<FormikValues>();
  const { response, isLoading, error, put } = useUpdateDistrict(district?.id || 0);

  useEffect(() => {
    if (!isLoading) {
      if (onCreated && response) {
        onCreated(response);
      }
      if (formikRef && formikRef.current) {
        formikRef.current.setSubmitting(false);
      }
    }
  }, [isLoading, onCreated, response]);

  return (
    <Dialog open={open} onClose={onCancel} maxWidth='xs'>
      <Formik
        innerRef={formik => (formikRef.current = formik)}
        initialValues={{ 
          name: district?.name, 
          area: district?.area
        }}
        validationSchema={validationSchema}
        onSubmit={(districtDto: Partial<District>) => {
          put(districtDto);
        }}
      >
        <Form autoComplete='off'>
          <DialogTitle>Edit District</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>Church Information</DialogContentText> */}
            <FormikGrid>
              <FormikTextField
                autoFocus
                type='text'
                name='name'
                label='District Name'
              />
              <FormikTextField type='text' name='area' label='Area' />
            </FormikGrid>
            {error && (
              <Alert severity='error'> { error ? error.message : '' }</Alert>
            )}
          </DialogContent>
          <DialogActions style={{ marginBottom: 10 }}>
            <Button onClick={onCancel}>Cancel</Button>
            <FormikButton>Update</FormikButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default EditDistrictDialog;
