import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import GivingOption from './GivingOption';
import { Typography } from '@material-ui/core';

const StyledHeader = styled.div`
  width: 100%;
  // height: 1000px;
  // margin-bottom: 50px;

  // padding-top: 0.5rem;
  // padding-bottom: 0.5rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  .header-container {
    background-color: #31a27c;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-self: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  img {
    width: 150px;
  }
  .right {
    color: #fff;
    font-weight: 500;
  }
`;

const Header = ({ processors }: any) => {
  return (
    <StyledHeader>
      <Typography variant='caption' style={{ position: 'absolute', top: 5, left: 5 }}>v2.0.6</Typography>
      <div className='header-container'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <img alt='' src={require('../../assets/sda-logo.png')} />
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 5, marginBottom: 40 }}>
            <div>
              <h2>CENTRAL LUZON CONFERENCE</h2>
              <p style={{ fontSize: '3em', fontWeight: 'bold', margin: 40 }}>
                ONLINE GIVING
              </p>

              <p style={{ fontSize: 16, margin: 20 }}>
                Each one must give as he has decided in his heart, not
                reluctantly or under compulsion, for God loves a cheerful giver.
              </p>
              <p style={{ fontSize: 16 }}> – 2 Corinthians 9:7</p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          backgroundColor: '#fff',
          color: '#545454'
        }}
      >
        <div
          style={{
            width: '80%',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ fontSize: 20, fontWeight: 600, marginTop: 60 }}>
                GETTING INVOLVED
              </div>
              <div
                style={{
                  fontSize: 45,
                  fontWeight: 600,
                  marginTop: 30,
                  marginBottom: 30
                }}
              >
                How to Give
              </div>
            </Grid>
            <Grid item xs={12}>
              <h2>INTRODUCTION</h2>
              <p style={{ fontSize: '1em', lineHeight: '1.5em' }}>
                CLC Online Giving allows you to return your tithe and give your
                offerings online while you do your online banking, are on a long
                business trip, on vacation, or even if you are unable to attend
                church due to an illness.
              </p>
            </Grid>
            <Grid item xs={12}>
              <p style={{ fontSize: '1em', lineHeight: '1.5em' }}>
                But most important of all, if you desire to give back to the
                Lord all of your firstfruits as soon as you have them, you can
                do it immediately through CLC Online Giving. We are setting up
                more ways to accept payments such as links to other online
                banking and over-the-counter transactions (7eleven, Cebuana
                Padala, and MLhuillier Padala). Meanwhile, let’s continue
                practicing systematic benevolence using this form, even in times
                of difficulty.
              </p>
            </Grid>
          </Grid>
        </div>
      </div>
      <GivingOption processors={processors} />
    </StyledHeader>
  );
};

export default Header;
