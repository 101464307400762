import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  CircularProgress
} from '@material-ui/core';
import PageTemplate from '../../common/page-template/PageTemplate';
import PageTemplateCardOver from '../../common/page-template/PageTemplateCardOver';
import PageTemplateHeader from '../../common/page-template/PageTemplateHeader';
import { useFindLogs } from './Transaction.hook';
import { formatDate } from '../../common/formatter';

const LogListPage: React.FC = () => {
  const { logList, isLoading, get } = useFindLogs();
  const [search, setSearch] = useState<any>('');
  const [page, setPage] = useState<any>();
  const [limit, setLimit] = useState<any>();
  const [order, setOrder] = useState<any>();
  const [orderDirection, setOrderDirection] = useState<any>();

  useEffect(() => {
    const params = {
      search: search,
      page: Number(page) || 0,
      limit: Number(limit) || 10,
      order: String(order || '')
    } as any;
    if (order && orderDirection) {
      params.order = `${order} ${orderDirection}`;
    }
    get(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get, page, limit, order, orderDirection, search]);

  const handleSort = (column: string) => {
    setOrder(column);
    setOrderDirection(orderDirection === 'ASC' ? 'DESC' : 'ASC');
  };

  const getSortDirection = (column: string): 'asc' | 'desc' => {
    return order === column && orderDirection === 'DESC' ? 'desc' : 'asc';
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  return (
    <PageTemplate>
      <PageTemplateHeader>
        <h2>Failed Paymongo Card</h2>
      </PageTemplateHeader>
      {isLoading ? (
        <div style={{ padding: 100, height: 500 }}>
          <CircularProgress />
        </div>
      ) : (
        <PageTemplateCardOver>
          <TableContainer style={{ minHeight: 450 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Transaction ID</TableCell>
                  <TableCell>Last 4 Digits</TableCell>
                  <TableCell>Error Response</TableCell>
                  <TableCell>Status Code</TableCell>
                  <TableCell>URL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logList?.data.map((log: any) => (
                  <TableRow hover key={log.id} onClick={() => {}}>
                    <TableCell>{formatDate(log.created_at, true)}</TableCell>
                    <TableCell align='center'>{log.transaction_id}</TableCell>

                    <TableCell align='center'>{log.last_4_digits}</TableCell>
                    <TableCell>{log.response}</TableCell>
                    <TableCell align='center'>{log.status_code}</TableCell>
                    <TableCell>{log.request_url}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20]}
            component='div'
            count={logList?.total || 0}
            rowsPerPage={logList?.limit || 10}
            page={logList?.page ? logList?.page - 1 : 0}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
          />
        </PageTemplateCardOver>
      )}
    </PageTemplate>
  );
};

export default LogListPage;
