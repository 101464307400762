import { Transaction } from './interface/transaction.interface';
import { useApiGet, useApiPost, ApiResult } from '../common/api/Api.hooks';
import { PaginatedList } from '../common/PaginatedList.interface';

export function useCreateTransaction() {
  const { data, isLoading, error, post } = useApiPost<any>('transaction');
  return { response: data, isLoading, error, post };
}

export function useCreateDragonpayTransaction() {
  const { data, isLoading, error, post } = useApiPost<any>('transaction/dragonpay');
  return { dragonpayResponse: data, isLoading, error, createDragonpayTransaction :post };
}
