import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import styled from 'styled-components';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@material-ui/core';

import PageTemplate from '../../common/page-template/PageTemplate';
import PageTemplateCardOver from '../../common/page-template/PageTemplateCardOver';
import PageTemplateHeader, {
  PageTemplateHeaderActions
} from '../../common/page-template/PageTemplateHeader';
import TransactionSearchBox from '../../common/TransactionSearchBox';
import { useFindTransactions } from './Payment.hook';
import { formatDate } from '../../common/formatter';
import PaymentDetailDialog from './PaymentDetailDialog';
import { PaginatedList } from '../../common/PaginatedList.interface';
import { Payment } from './payment.interface';

const CenterText = styled.div`
  text-align: center;
`;

const PaymentListPage: React.FC = props => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [limit, setLimit] = useQueryParam('limit', NumberParam);
  const [order, setOrder] = useQueryParam('order', StringParam);
  const [orderDirection, setOrderDirection] = useQueryParam(
    'direction',
    StringParam
  );

  const [viewPayment, setViewPayment] = useState<any>();
  const [payments, setPayments] = useState<PaginatedList<Payment>>();
  const { transactionList: paymentList, get } = useFindTransactions();

  useEffect(() => {
    const params = {
      search: search,
      page: Number(page) || 0,
      limit: Number(limit) || 10,
      order: String(order || '')
    } as any;
    if (order && orderDirection) {
      params.order = `${order} ${orderDirection}`;
    }
    // console.log('params', params, page, limit, order, search);
    get(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get, page, limit, order, orderDirection]);

  useEffect(() => {
    paymentList && setPayments(paymentList);
  }, [paymentList]);

  const handleSort = (column: string) => {
    setOrder(column);
    setOrderDirection(orderDirection === 'ASC' ? 'DESC' : 'ASC');
  };

  const getSortDirection = (column: string): 'asc' | 'desc' => {
    return order === column && orderDirection === 'DESC' ? 'desc' : 'asc';
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  return (
    <PageTemplate>
      <PageTemplateHeader>
        <h2>Payments</h2>
        <TransactionSearchBox
          value={search}
          onSearch={value => setSearch(value)}
        />
        <PageTemplateHeaderActions>
          {/* <Button
            variant='contained'
            color='primary'
            onClick={() => setIsOpenDialog(true)}
          >
            Add Transaction
          </Button> */}
        </PageTemplateHeaderActions>
      </PageTemplateHeader>
      <PageTemplateCardOver>
        <TableContainer style={{ minHeight: 450 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Transaction #</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === 'lastName'}
                    direction={getSortDirection('lastName')}
                    onClick={() => handleSort('lastName')}
                  >
                    Last Name{' '}
                  </TableSortLabel>
                </TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>Church</TableCell>
                <TableCell>Area</TableCell>
                <TableCell align='right'>Total</TableCell>
                <TableCell align='right'>
                  <TableSortLabel
                    active={order === 'tithe'}
                    direction={getSortDirection('tithe')}
                    onClick={() => handleSort('tithe')}
                  >
                    Tithe
                  </TableSortLabel>
                </TableCell>
                <TableCell align='right'>Offering</TableCell>
                <TableCell align='right'>Other Amount</TableCell>
                <TableCell>Other Description</TableCell>
                <TableCell align='right'>Fee</TableCell>
                <TableCell>Payment Method</TableCell>
                <TableCell>Payment Date</TableCell>
                <TableCell>Payment ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments?.data.map((payment: any) => (
                <TableRow
                  hover
                  key={payment.referenceId}
                  onClick={() => setViewPayment(payment)}
                >
                  <TableCell>{payment.referenceId}</TableCell>
                  <TableCell>{payment.lastName}</TableCell>
                  <TableCell>{payment.firstName}</TableCell>
                  <TableCell>{payment.email}</TableCell>
                  <TableCell>
                    {payment.contactNumber ? (
                      payment.contactNumber
                    ) : (
                      <CenterText>-</CenterText>
                    )}
                  </TableCell>
                  <TableCell>
                    {payment.churchName ? (
                      payment.churchName
                    ) : (
                      <CenterText>-</CenterText>
                    )}
                  </TableCell>
                  <TableCell>
                    {payment.area ? payment.area : <CenterText>-</CenterText>}
                  </TableCell>
                  <TableCell align='right'>₱ {payment.totalAmount}</TableCell>
                  <TableCell align='right'>₱ {payment.tithe}</TableCell>
                  <TableCell align='right'>₱ {payment.offering}</TableCell>
                  <TableCell align='right'>
                    ₱{' '}
                    {payment.otherAmount ? (
                      payment.otherAmount
                    ) : (
                      <CenterText>-</CenterText>
                    )}
                  </TableCell>
                  <TableCell>
                    {payment.otherDescription ? (
                      payment.otherDescription
                    ) : (
                      <CenterText>-</CenterText>
                    )}
                  </TableCell>
                  <TableCell align='right'>₱ {payment.paymentFee}</TableCell>
                  <TableCell>
                    <CenterText>{payment.paymentMethod}</CenterText>
                  </TableCell>
                  <TableCell align='right'>
                    {formatDate(payment.paymentDate)}
                  </TableCell>
                  <TableCell>{payment.paymentId}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={paymentList?.total || 0}
          rowsPerPage={paymentList?.limit || 10}
          page={paymentList?.page ? paymentList?.page - 1 : 0}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowPerPage}
        />
      </PageTemplateCardOver>
      {Boolean(viewPayment) && (
        <PaymentDetailDialog
          open={Boolean(viewPayment)}
          payment={viewPayment}
          onCancel={() => setViewPayment(null)}
        />
      )}
    </PageTemplate>
  );
};

export default PaymentListPage;
