import React, {useEffect} from 'react';
import Header from './components/Header';
import { Grid } from '@material-ui/core';
import GiverView from './Giver';
import Footer from './components/Footer';
import { useGetAvailableProcessors } from '../common/dragonpay/dragonpay.hook';

const HomePage = () => {
  const { response, getProcessors } = useGetAvailableProcessors();
 
  useEffect(() => {
    getProcessors();
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={0} style={styles.background}>
        <Grid item xs={12} sm={12} md={7}>
          <Header processors={response?.data}/>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <GiverView processors={response?.data}/>
        </Grid>
      </Grid>
      <Footer />
    </React.Fragment>
  );
};

const styles = {
  background: {
    backgroundColor: '#f9f9f9'
  }
};
export default HomePage;
