export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
}

export enum UserRole {
  admin = 'Admin',
  accounting = 'Accounting',
  controller = 'Controller'
}
