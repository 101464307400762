import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  CircularProgress,
  SvgIcon,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PageTemplate from '../../common/page-template/PageTemplate';
import PageTemplateCardOver from '../../common/page-template/PageTemplateCardOver';
import PageTemplateHeader, {
  PageTemplateHeaderActions
} from '../../common/page-template/PageTemplateHeader';

import CreateChurchDialog from './components/CreateChurchDialog';
import SearchBox from '../../common/SearchBox';
import { User } from '../../user/User.interface';
import { useFindChurch, useDeleteChurch } from './church.hook';
import { Church } from './church.interface';
import EditChurchDialog from './components/EditChurchDialog';
import DeleteConfirmModal from '../../common/DeleteConfirmModal';

const CenterText = styled.div`
  text-align: center;
`;

const ChurchListPage: React.FC = () => {
  const [searchHolder, setSearchHolder] = useState<any>('');
  const [search, setSearch] = useState<any>('');
  const [page, setPage] = useState<any>();
  const [limit, setLimit] = useState<any>();
  const [order, setOrder] = useState<any>();
  const [orderDirection, setOrderDirection] = useState<any>();

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [editData, setEditData] = useState<Church | null>(null);
  const [deleteData, setDeleteData] = useState<Church | null>(null);

  const { churchList, get } = useFindChurch();
  const { response, del } = useDeleteChurch(deleteData?.id || 0);

  useEffect(() => {
    const params = {
      search: search,
      page: Number(page) || 0,
      limit: Number(limit) || 10,
      order: String(order || '')
    } as any;
    if (order && orderDirection) {
      params.order = `${order} ${orderDirection}`;
    }
    get(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    get,
    page,
    limit,
    order,
    orderDirection,
    search,
  ]);

  useEffect(() => {
    if (response) {
      setDeleteData(null);
      get(); // refresh data
    }
  }, [response])

  const handleSort = (column: string) => {
    setOrder(column);
    setOrderDirection(orderDirection === 'ASC' ? 'DESC' : 'ASC');
  };

  const getSortDirection = (column: string): 'asc' | 'desc' => {
    return order === column && orderDirection === 'DESC' ? 'desc' : 'asc';
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  return (
    <PageTemplate>
      <PageTemplateHeader>
        <h2>Church</h2>
        <SearchBox value={search} onSearch={value => setSearch(value)} />
        <PageTemplateHeaderActions>
          <Button
            variant='contained'
            color='primary'
            onClick={() => setIsOpenDialog(true)}
          >
            Add Church
          </Button>
        </PageTemplateHeaderActions>
      </PageTemplateHeader>
      <PageTemplateCardOver>
        <TableContainer style={{ minHeight: '70vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={order === 'merchantId'}
                    direction={getSortDirection('merchantId')}
                    onClick={() => handleSort('merchantId')}
                  >
                    Merchant ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === 'name'}
                    direction={getSortDirection('name')}
                    onClick={() => handleSort('name')}
                  >
                    Church
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === 'area'}
                    direction={getSortDirection('area')}
                    onClick={() => handleSort('area')}
                  >
                    Area
                  </TableSortLabel>
                </TableCell>
                <TableCell>District</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {churchList &&
                churchList?.data?.map((church: Church) => (
                  <TableRow hover key={church.id}>
                    <TableCell>{church.merchantId}</TableCell>
                    <TableCell>{church.name}</TableCell>
                    <TableCell>{church?.district?.area}</TableCell>
                    <TableCell>{church?.district?.name}</TableCell>
                    <TableCell>{church.address}</TableCell>
                    <TableCell>{church.contactNumber}</TableCell>
                    <TableCell>{church.email}</TableCell>
                    <TableCell align='right'>
                      <Box display='flex' flexDirection='row' justifyContent='flex-end'>
                        <IconButton onClick={() => setEditData(church)} size='small' style={{ marginRight: 10}}>
                          <SvgIcon fontSize="small">
                            <EditIcon />
                          </SvgIcon>
                        </IconButton>
                        <IconButton onClick={() => setDeleteData(church)} size='small'>
                          <SvgIcon fontSize="small">
                            <DeleteIcon/>
                          </SvgIcon>
                        </IconButton>
                      </Box>
                  </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 20]}
            component='div'
            count={churchList?.total || 0}
            rowsPerPage={churchList?.limit || 10}
            page={churchList?.page ? churchList?.page - 1 : 0}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowPerPage}
          />
      </PageTemplateCardOver>

      {isOpenDialog && (
        <CreateChurchDialog
          open={isOpenDialog}
          onCancel={() => setIsOpenDialog(false)}
          onCreated={() => {
            setIsOpenDialog(false);
            get(); // refresh data
          }}
        />
      )}
      {Boolean(editData) && (
        <EditChurchDialog
          open={Boolean(editData)}
          church={editData}
          onCancel={() => setEditData(null)}
          onCreated={() => {
            setEditData(null);
            get(); // refresh data
          }}
        />
      )}
      {Boolean(deleteData) && (
        <DeleteConfirmModal 
          open={Boolean(deleteData)} 
          onCancel={() => setDeleteData(null)} onDelete={() => del()}
        />
      )}
    </PageTemplate>
  );
};

export default ChurchListPage;
