import React, { useState } from 'react';
import styled from 'styled-components';
import {Grid, Button} from '@material-ui/core';

const StyledFooter = styled.div`
  padding-top: 60px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 15px;
  background-color: white;
`;
const GivingOption = ({ processors }: any) => {
  const [showMore, setShowMore] = useState(false);

  const renderImage = (option: any) => {
    switch (option?.procId?._text) {
      case 'CC':
        return <img alt='' src={require('../../assets/card_image.png')} />;
      case 'GCSH':
        return <img alt='' src={require('../../assets/gcash-image.png')} />;
      case 'PYMY':
        return <img alt='' src={require('../../assets/paymaya_logo.png')} />;
      case 'GRPY':
        return <img alt='' src={require('../../assets/grabpay-image.png')} />;
      case 'TAYO':
        return <img alt='' src={require('../../assets/tayocash_icon.jpg')} />;
      case 'AAA':
        return <img alt='' src={require('../../assets/cryptocurrency_icon.png')} />;
      default:
        return <img alt='' src={option.logo._text} style={{ alignSelf: 'center'}} />;
    }
  }


  return (
    <StyledFooter>
      <div>
        <Grid container spacing={0}>
          <Grid item md={12} xs={12}>
            <div style={{ textAlign: 'center', color: '#535353' }}>
              <div style={{ fontSize: 25, fontWeight: 'bold' }}>
                Giving Options
              </div>
            </div>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 30 }}>
            <Grid item md={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <img alt='' src={require('../../assets/card_image.png')} />
                <p style={{ fontSize: '1em', color: 'black'}}>Credit/Debit Card</p>
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
                <div style={{ textAlign: 'center',}}>
                  <img alt='' src={require('../../assets/gcash-image.png')} />
                  <p style={{ fontSize: '1em', color: 'black'}}>GCash</p>
                </div>
            </Grid>
            {/* <Grid item md={4} xs={12}>
                <div style={{ textAlign: 'center',}}>
                  <img alt='' src={require('../../assets/paymaya_logo.png')} />
                  <p style={{ fontSize: '1em', color: 'black'}}>PayMaya</p>
                </div>
            </Grid> */}
            <Grid item md={4} xs={12}>
                <div style={{ textAlign: 'center',}}>
                  <img alt='' src={require('../../assets/grabpay-image.png')} />
                  <p style={{ fontSize: '1em', color: 'black'}}>Grab Pay</p>
                </div>
            </Grid>
            {/* {processors?.slice(0, showMore ? processors?.length : 15)?.map((option: any) => { */}
            {processors?.map((option: any) => {
                // const html = <div dangerouslySetInnerHTML={{ __html: option.remarks }} style={{ fontSize: '1em', color: 'black'}} />;
                
                return (
                  <Grid item md={4} xs={12}>
                    <div style={{ textAlign: 'center',}}>
                      {renderImage(option)}
                      {/* <img alt='' src={option.logo} style={{ alignSelf: 'center'}} /> */}
                      <p style={{ fontSize: '1em', color: 'black'}}>{option.longName._text}</p>
                      {/* <p style={{ fontSize: '0.8em', color: 'grey', position: 'relative', top: -10}}>Surchange: ₱{option.surcharge._text}</p> */}
                  
                      {/* <div style={{ fontSize: '1em', color: 'black'}}> {ReactHtmlParser(`${<div dangerouslySetInnerHTML={{ __html: option.remarks }} style={{ fontSize: '1em', color: 'black'}} />}`) }</div> */}
                    </div>
                  </Grid>
                )
              })}
            {/* <Grid item md={3} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <img alt='' src={require('../../assets/card_image.png')} />
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <img alt='' src={require('../../assets/gcash-image.png')} />
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <img alt='' src={require('../../assets/paymaya_logo.png')} />
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <img alt='' src={require('../../assets/grabpay-image.png')} />
              </div>
            </Grid> */}
          </Grid>
        </Grid>
      </div>
      {/* <Button variant='contained' color='primary' style={{ alignSelf: 'center' }} onClick={() => setShowMore(!showMore)}>Show {showMore ? 'less' : 'more'}</Button> */}
      <div style={{ marginTop: 80, marginBottom: 50 }}>
        <Grid container spacing={0}>
          {/* <Grid item md={12} xs={12}>
            <div style={{ textAlign: 'center', color: '#535353' }}>
              <div style={{ fontSize: 25, fontWeight: 'bold' }}>
                Coming Soon!
              </div>
            </div>
          </Grid> */}
          <Grid container spacing={2} style={{ marginTop: 30 }}>
            {/* <Grid item md={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <img
                  alt=''
                  src={require('../../assets/7-eleven.png')}
                  style={{ height: 55, width: 55 }}
                />
              </div>
            </Grid>
            <Grid item md={4} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <img
                  alt=''
                  src={require('../../assets/logo_cebuana.png')}
                  style={{ marginTop: -5 }}
                />
              </div>
              <div style={{ textAlign: 'center' }}>
                <img
                  alt=''
                  src={require('../../assets/m lhuillier.png')}
                  style={{ marginTop: 10, height: 50, width: 250 }}
                />
              </div>
            </Grid> */}
            
            
          </Grid>
        </Grid>
      </div>
    </StyledFooter>
  );
};

export default React.memo(GivingOption);
