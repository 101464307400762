import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, TextField, Grid, LinearProgress } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { SERVER_URL, PUBLIC_KEY } from '../../contant';
import AppSnackbar from '../../common/AppSnackbar';
import CreditCard from './Card';
import PaymongoFooter from '../components/PaymongoFooter';
import { CreateSource } from '../interface/createSource.interface';
import TransactionConfirm from '../components/TransactionConfirm';
import ConfirmAlert from '../components/ConfirmAlert';
import { Transaction } from '../interface/transaction.interface';
import MessageAlert, { MessageAlertProps } from '../../common/MessageAlert';
import { AttachToPaymentIntent } from '../../Api/AttachPaymentIntent';
// import AwaitNext3DAuthModal from '../components/AwaitNext3DAuthModal';
import { CreateCardPayment } from '../../Api/createCardPayment';
import { twoDecimalPlaces } from '../../common/Util';

import { CreateLog } from '../../Api/createLog';
import { CreateLogDto } from '../interface/createLogDto';
import ChurchInput from './ChurchInput';
import AreaInput from './AreaInput';
import PaymentOptions from '../../common/dragonpay/PaymentOptions';
import { Processors, ProcessorInfoProps } from '../../common/dragonpay/ProcessorInfo';
import { Church } from '../../secure-portal/church/church.interface';
import { useGetAvailableProcessors } from '../../common/dragonpay/dragonpay.hook';
import { PaymongoPaymentMethodEnum } from '../interface/paymentMethod.enum';
import PaymongoTransactionConfitm from '../components/PaymongoTransactionConfitm';
import { CreatePaymentIntent } from '../interface/createPaymentIntent.interface';
import PayMayaDetailDialog from '../../secure-portal/paymaya/PayMayaDetailDialog';
import {
  formatExpirationDate,
  formatCVC,
  formatCreditCardNumber
} from './cardUtils';
import AwaitNext3DAuthModal from '../components/AwaitNext3DAuthModal';

const CREATE_SOURCE_URL = SERVER_URL + '/api/paymongo/createSource';
const CREATE_PAYMENT_INTENT_URL =
SERVER_URL + '/api/paymongo/createPaymentIntent';
const PAYMENT_OPTIONS = Processors;

const StyledCard = styled(Card)`
  max-width: 450px;
  margin-bottom: 30px;
  background-color: '#dadada';
`;

export enum Currency {
  PHP = 'PHP'
}

const GiverForm = () => {
  const { response, isLoading, getProcessors } = useGetAvailableProcessors();
  const [processors, setProcessors] = useState<any>(null);
  const [isProceed, setIsProceed] = useState<boolean>(false);
  const currency = Currency.PHP;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [church, setChurch] = useState<Church | any>('');
  const [area, setArea] = useState('');
  const [offering, setOffering] = useState(0.0);
  const [tithe, setTithe] = useState(0.0);
  const [other, setOther] = useState('');
  const [otherAmount, setOtherAmount] = useState(0.0);
  const [selectedProcessor, setSelectedProcessor] = useState<string>('');
  const [warningMessage, setWarningMessage] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [cardNumber, setCarNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  console.log("🚀 ~ file: GiverForm.tsx ~ line 80 ~ GiverForm ~ expiry", expiry)
  const [cvc, setCvc] = useState('');
  const [paymentMethod, setPaymentMethod] = useState<PaymongoPaymentMethodEnum>(
    PaymongoPaymentMethodEnum.gcash
  );

  const [awaitingNext, setAwaitingNext] = useState<any>();
  const cardLast4Degit = cardNumber.substr(cardNumber.length - 4);
  const finalTithe = tithe ? tithe : 0;
  const finalOffering = offering ? offering : 0;
  const finalOtherAmount = otherAmount ? otherAmount : 0;
  const totalAmount = finalTithe + finalOffering + finalOtherAmount;
  const [errorMessage, setErrorMessage] = useState<MessageAlertProps>({
    open: false,
    title: 'Error',
    body: 'Please try again.'
  });

  const [confirmTransaction, setConfirmTransaction] = useState(false);
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [openPayMaya, setOpenPayMaya] = useState(false);

  const [transactionID, setTransactionID] = useState<any>();

  useEffect(() => {
    response && setProcessors(response.data)
  }, [response]);

  const isPaymongo = selectedProcessor === PaymongoPaymentMethodEnum.card || selectedProcessor === PaymongoPaymentMethodEnum.gcash || selectedProcessor === PaymongoPaymentMethodEnum.payMaya || selectedProcessor === PaymongoPaymentMethodEnum.grabPay;

  const transactionDetail: Transaction = {
    // id: transactionID,
    procId: selectedProcessor,
    lastName: lastName,
    firstName: firstName,
    email: email,
    contactNumber: contactNumber,
    church: church,
    tithe: isNaN(tithe) ? 0 : tithe,
    offering: isNaN(offering) ? 0 : offering,
    currency: currency,
    otherPurpose: [
      {
        name: other,
        amount: isNaN(otherAmount) ? 0 : otherAmount
      }
    ],
    total: totalAmount,
    cardLast4Degit: cardLast4Degit ? cardLast4Degit : ''
  };

  const dragonpaytransactionDetail: Transaction = {
    // id: transactionID,
    procId: selectedProcessor,
    lastName: lastName,
    firstName: firstName,
    email: email,
    contactNumber: contactNumber,
    church: church,
    tithe: isNaN(tithe) ? 0 : tithe,
    offering: isNaN(offering) ? 0 : offering,
    currency: currency,
    otherPurpose: [
      {
        name: other,
        amount: isNaN(otherAmount) ? 0 : otherAmount
      }
    ],
    total: totalAmount,
  };

  const paymentMethodChange = (
    event: React.ChangeEvent<{ value: unknown }>
    ) => {
      setSelectedProcessor(event.target.value as string);
  };

  const sendRequestCreateSource = (createSource: CreateSource) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(createSource)
    };
    try {
      fetch(CREATE_SOURCE_URL, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.url) {
            window.location.href = data.url;
          } else {
            setErrorMessage({
              open: true,
              title: 'Error',
              body:
                'Cannot connect to server. Our server might be temporarily down.'
            });
            setConfirmTransaction(false);
          }
        });
    } catch (e) {
      setConfirmTransaction(false);
      setErrorMessage({
        open: true,
        title: 'Error',
        body: 'Cannot connect to server. Our server might be temporarily down.'
      });
    }
  };

  const createPaymentIntent = (transactionId: number) => {
    const totalAmount = tithe + offering + otherAmount;
    const createIntent: CreatePaymentIntent = {
      transactionId: transactionId,
      paymentMethod: paymentMethod,
      lastName: lastName,
      firstName: firstName,
      email: email,
      contactNumber: contactNumber,
      churchName: church,
      area: area,
      tithe: isNaN(tithe) ? 0 : tithe,
      offering: isNaN(offering) ? 0 : offering,
      currency: currency,
      otherPurpose: [
        {
          name: other,
          amount: isNaN(otherAmount) ? 0 : otherAmount
        }
      ],
      total: twoDecimalPlaces(totalAmount),
      description: `CLC Giving Online - Transaction ID: ${transactionId}`
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(createIntent)
    };
    try {
      fetch(CREATE_PAYMENT_INTENT_URL, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            sendPaymentMethod(data.client_key, transactionId);
          } else {
            if (data.error.errors) {
              setErrorMessage({
                open: true,
                title: 'Transaction Failed',
                body: data.error.errors[0].detail
              });
            } else {
              setErrorMessage({
                open: true,
                title: 'Error',
                body: 'Internal Server Error.'
              });
            }
          }
        });
    } catch (e) {
      setConfirmTransaction(false);
      setErrorMessage({
        open: true,
        title: 'Transaction Failed',
        body: 'Internal Server Error.'
      });
    }
  };

  const sendPaymentMethod = (clientKey: string, transactionId: number) => {
    const cardInfo = {
      data: {
        attributes: {
          details: {
            card_number: cardNumber.replace(/\s/g, ''),
            exp_month: parseInt(expiry.substring(0, 2)),
            exp_year: parseInt(expiry.substring(expiry.length - 2)),
            cvc: cvc
          },
          billing: {
            name: `${firstName} ${lastName}`,
            email: email
          },
          type: 'card'
        }
      }
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Basic ' + PUBLIC_KEY
      },
      body: JSON.stringify(cardInfo)
    };
    try {
      fetch('https://api.paymongo.com/v1/payment_methods', requestOptions)
        .then(response => response.json())
        .then(response => {
          if (response.data?.id) {
            const paymentMethodId = response.data?.id;

            AttachToPaymentIntent(clientKey, paymentMethodId).then(
              (response: any) => {
                attachPaymentResponse(response, transactionId);
              }
            );
          } else {
            setErrorMessage({
              open: true,
              title: 'Transaction Failed',
              body: response.errors[0].detail
            });
          }
        });
    } catch (e) {
      setConfirmTransaction(false);
      setErrorMessage({
        open: true,
        title: 'Error',
        body: 'Internal Server Error.'
      });
    }
  };

  const attachPaymentResponse = (response: any, transactionId: number) => {
    const paymentIntent = response.data;
    if (response.success) {
      var paymentIntentStatus = paymentIntent.attributes.status;

      if (paymentIntentStatus === 'awaiting_next_action') {
        setAwaitingNext(paymentIntent);
      } else if (paymentIntentStatus === 'succeeded') {
        CreateCardPayment(paymentIntent.id);
      } else if (paymentIntentStatus === 'awaiting_payment_method') {
        setErrorMessage({
          open: true,
          title: 'Transaction Failed',
          body: paymentIntent.attributes.last_payment_error.failed_message
        });
      }
    } else if (response.success === false) {
      if (response.errors[0].sub_code === 'processor_unavailable') {
        setErrorMessage({
          open: true,
          title: 'Transaction Failed',
          body:
            'Wait for a few minutes before re-trying the transaction. Else, use a different card or other form of payment.'
        });
      } else if (response.errors[0].sub_code === 'generic_decline') {
        setErrorMessage({
          open: true,
          title: 'Transaction Failed',
          body:
            'The payment failed to be processed due to unknown reasons. Kindly contact your card issuing bank for further details.'
        });
      } else if (response.errors[0].sub_code === 'insufficient_funds') {
        setErrorMessage({
          open: true,
          title: 'Transaction Failed',
          body:
            'The card does not have sufficient funds to complete the transaction.\n\nUse a different card or other form of payment.'
        });
      } else if (response.errors[0].sub_code === 'card_expired') {
        setErrorMessage({
          open: true,
          title: 'Transaction Failed',
          body:
            'The card used has already expired. Use a different card or other form of payment.'
        });
      } else if (response.errors[0].sub_code === 'cvn_invalid') {
        setErrorMessage({
          open: true,
          title: 'Transaction Failed',
          body:
            'The inputted CVC/CVN is incorrect for the the card number passed. Correct the CVC/CVN before re-submitting.'
        });
      } else {
        setErrorMessage({
          open: true,
          title: 'Transaction Failed',
          body: 'The payment failed to be processed due to unknown reasons.'
        });
      }
      const logDto: CreateLogDto = {
        transactionId: transactionId,
        last4digits: cardLast4Degit,
        statusCode: response.statusCode,
        requestUrl: response.url,
        response: JSON.stringify(response.errors)
      };
      CreateLog(logDto);
    }
  };

  const validateEmailInput = (email: any) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(email)) {
      setIsEmailValid(true);
      setEmail(email);
    } else {
      setIsEmailValid(false);
    }
  };

  const handleConfirm = () => {
    if (firstName.length === 0) {
      setWarningMessage(`First name is required!`);
    } else if (lastName.length === 0) {
      setWarningMessage(`Last name is required!`);
    } else if (!isEmailValid) {
      setWarningMessage(`Invalid email address.`);
    } else if (email.length === 0) {
      setWarningMessage(`Invalid email address.`);
    } else if (area.length < 1) {
      setWarningMessage(`Please select area!`);
    } else if (totalAmount < 100) {
      setWarningMessage(`Total amount should be at least PHP 100.`);
    } else {
      if (
        firstName !== '' &&
        lastName !== '' &&
        email !== '' &&
        isEmailValid &&
        totalAmount >= 100
      ) {
        getProcessors({ amount: totalAmount });
        setIsProceed(true);
        // setConfirmTransaction(true);
      }
    }
  };

  const handleSubmit = () => {
    setOpenConfirmAlert(false);
    setConfirmTransaction(true);
  };

  const confirmSubmit = (transactionId: number) => {
    if (selectedProcessor === PaymongoPaymentMethodEnum.card) {
      createPaymentIntent(transactionId);
    } else if (
      selectedProcessor === PaymongoPaymentMethodEnum.grabPay ||
      selectedProcessor === PaymongoPaymentMethodEnum.gcash
    ) {
      const createSource: CreateSource = {
        transactionId: transactionId,
        paymentMethod: selectedProcessor,
        lastName: lastName,
        firstName: firstName,
        email: email,
        contactNumber: contactNumber,
        churchName: church,
        area: area,
        tithe: tithe,
        offering: offering,
        currency: Currency.PHP,
        otherPurpose: [
          {
            name: other,
            amount: otherAmount
          }
        ]
      };
      sendRequestCreateSource(createSource);
    } else if (selectedProcessor === PaymongoPaymentMethodEnum.payMaya) {
      setConfirmTransaction(false);
      setOpenPayMaya(true);
    }
  };

  const onNext = () => {
    if (selectedProcessor === 'card' && cardNumber.length === 0) {
      setWarningMessage( `Double-check the Credit/Debit card number, expiration date, and CVC.`);
    } else {
      setConfirmTransaction(true)
    }
  }

  return (
    <React.Fragment>
      <StyledCard>
        <div style={{ padding: 30 }}>
          <Grid container spacing={3}>
            <Fragment>
              <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <FormControl
                  variant='outlined'
                  style={{ width: '100%', textAlign: 'left' }}
                >
                  <FormLabel component='legend' style={{ alignItems: 'left' }}>
                    Details
                  </FormLabel>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  label='First Name:'
                  variant='outlined'
                  onChange={(e: any) => {
                    setFirstName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  label='Last Name:'
                  variant='outlined'
                  onChange={(e: any) => {
                    setLastName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label='Email Address:'
                  variant='outlined'
                  onChange={(e: any) => {
                    validateEmailInput(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Contact Number:'
                  variant='outlined'
                  onChange={(e: any) => {
                    setContactNumber(e.target.value);
                  }}
                />
              </Grid>
            </Fragment>

            <Fragment>
              <Grid item xs={8}>
                <ChurchInput
                  church={church}
                  area={area}
                  setChurch={(i: Church| any) => setChurch(i)}
                  setArea={(i: string) => setArea(i)}
                />
              </Grid>
              <Grid item xs={4}>
                <AreaInput area={area} setArea={(i: string) => {
                  setArea(i)
                  setChurch('')
                }} />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='outlined-adornment-amount'>
                    Tithe
                  </InputLabel>
                  <OutlinedInput
                    placeholder={'0.00'}
                    type='number'
                    // value='700'
                    onChange={(e: any) => {
                      setTithe(parseFloat(e.target.value));
                    }}
                    startAdornment={
                      <InputAdornment position='start'>₱</InputAdornment>
                    }
                    labelWidth={60}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='outlined-adornment-amount'>
                    Offering
                  </InputLabel>
                  <OutlinedInput
                    placeholder={'0.00'}
                    type='number'
                    // value='500'
                    onChange={(e: any) => {
                      setOffering(parseFloat(e.target.value));
                    }}
                    startAdornment={
                      <InputAdornment position='start'>₱</InputAdornment>
                    }
                    labelWidth={60}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant='outlined'>
                  <InputLabel htmlFor='outlined-adornment-amount'>
                    Other
                  </InputLabel>
                  <OutlinedInput
                    placeholder={'0.00'}
                    type='number'
                    // value='100'
                    onChange={(e: any) => {
                      setOtherAmount(parseFloat(e.target.value));
                    }}
                    startAdornment={
                      <InputAdornment position='start'>₱</InputAdornment>
                    }
                    labelWidth={60}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label='Description:'
                  variant='outlined'
                  // value='Others'
                  onChange={(e: any) => {
                    setOther(e.target.value);
                  }}
                />
              </Grid>
            </Fragment>
            { isLoading &&  <LinearProgress /> }
            { processors && (
              <>
            <Grid item xs={12}>
              <FormControl
                variant='outlined'
                style={{
                  width: '100%',
                  textAlign: 'left',
                  marginTop: 5,
                  marginBottom: 5
                }}
              >
                <FormLabel
                  component='legend'
                  style={{ alignItems: 'left' }}
                  color='primary'
                >
                  Payment Method:
                </FormLabel>
                <RadioGroup
                  style={{ padding: 10 }}
                  value={selectedProcessor}
                  onChange={paymentMethodChange}
                >
                  {processors && <PaymentOptions processors={processors}/> }
                </RadioGroup>
              </FormControl>
            </Grid>
            {selectedProcessor === PaymongoPaymentMethodEnum.card && (
              <React.Fragment>
                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                  <FormControl
                    variant='outlined'
                    style={{ width: '100%', textAlign: 'left' }}
                  >
                    <FormLabel
                      component='legend'
                      style={{ alignItems: 'left' }}
                    >
                      Credit/Debit Card
                    </FormLabel>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <form>
                    <CreditCard
                      name={''}
                      number={cardNumber}
                      expiry={expiry}
                      cvc={cvc}
                      focused={'number'}
                    />
                  </form>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label='Card Number:'
                    variant='outlined'
                    value={formatCreditCardNumber(cardNumber)}
                    onChange={(e: any) => {
                      setCarNumber(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label='Valid Thru:'
                    variant='outlined'
                    value={formatExpirationDate(expiry)}
                    onChange={(e: any) => {
                      setExpiry(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label='CVC:'
                    variant='outlined'
                    value={formatCVC(cvc)}
                    onChange={(e: any) => {
                      setCvc(e.target.value);
                    }}
                  />
                </Grid>
              </React.Fragment>
            )}
            </>
            )}
            <Grid item xs={12}>
              <Button
                style={{
                  backgroundColor: '#00c1ff',
                  color: 'white',
                  padding: 10,
                  width: 150
                }}
                onClick={isProceed ?  onNext : handleConfirm  }
                disabled={!(totalAmount > 0)}
              >
                {isProceed ? 'Next' : 'Proceed'}
              </Button>
            </Grid>
          </Grid>
        </div>
        <PaymongoFooter />
      </StyledCard>
      <ConfirmAlert
        open={openConfirmAlert}
        onCancel={() => setOpenConfirmAlert(false)}
        onSubmit={() => handleSubmit()}
      />
      {!!confirmTransaction && (
        isPaymongo ? (
          <PaymongoTransactionConfitm
            transaction={transactionDetail}
            open={confirmTransaction}
            onCancel={() => setConfirmTransaction(false)}
            onSubmit={(id: number) => {
              confirmSubmit(id);
              setTransactionID(id);
            }}
            onError={(e: any) => setErrorMessage(e)}
          />
        ) : (
          <TransactionConfirm
            processors={processors}
            transaction={transactionDetail}
            open={confirmTransaction}
            onCancel={() => setConfirmTransaction(false)}
            onSubmit={(id: number) => {
              // confirmSubmit(id);
              // setTransactionID(id);
            }}
            onError={(e: any) => setErrorMessage(e)}
          />
        )
      )}
      {Boolean(awaitingNext) && (
        <AwaitNext3DAuthModal
          props={awaitingNext}
          open={Boolean(awaitingNext)}
          onClose={() => setAwaitingNext(null)}
          onSubmit={() => {}}
          onError={(error: MessageAlertProps) => {
            setAwaitingNext(null);
            setErrorMessage(error);
          }}
        />
      )}
      {/* <PayMayaDetailDialog
        open={openPayMaya}
        transaction={transactionDetail}
        onCancel={() => setOpenPayMaya(false)}
        onError={(e: any) => setErrorMessage(e)}
      /> */}
      <MessageAlert
        open={errorMessage.open}
        title={errorMessage.title}
        body={errorMessage.body}
        onClose={() => {
          setErrorMessage({ ...errorMessage, open: false });
          setConfirmTransaction(false);
        }}
      />
      <AppSnackbar
        severity='error'
        open={Boolean(warningMessage)}
        onClose={() => setWarningMessage('')}
      >
        {warningMessage}
      </AppSnackbar>
    </React.Fragment>
  );
};

export default GiverForm;
