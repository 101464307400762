import { CreateLogDto } from './../home/interface/createLogDto';
import { SERVER_URL } from '../contant';

export const CreateLog = async (logDto: CreateLogDto) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(logDto)
  };
  try {
    return await fetch(SERVER_URL + '/api/transaction/log', requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log('Log created.');
      })
      .catch(error => {
        // console.log('CreateLog -> error', error);
        return { success: false, error };
      });
  } catch (error) {
    return { success: false, error };
  }
};
