import { reloadApp } from '../../user/User.hooks';
import { useApiGet } from '../../common/api/Api.hooks';
import { useEffect } from 'react';

interface ApiResponse {
  success: boolean;
  data?: any;
}

export function useChartMonthly() {
  const { data, isLoading, error, get } = useApiGet<any>(
    'transaction/chart-monthly'
  );
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  return { monthly: data, isLoading, error, get };
}

export function useTotalTransactionPerArea() {
  const { data, isLoading, error, get } = useApiGet<any>(
    'transaction/totalTransactionPerArea'
  );
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  return { areaPercentage: data, isLoading, error, getArea: get };
}

export function usePercentagePerPaymentMethod() {
  const { data, isLoading, error, get } = useApiGet<any>(
    'transaction/totalTransactionPerPaymentMethod'
  );
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  return { platformPercentage: data, isLoading, error, getPlatform: get };
}
