import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import AdminRoute from './common/AdminRoute';
import ProtectedRoute from './common/ProtectedRoute';
import AdminGuard from './guards/admin.guard';
import UserGuard from './guards/user.guard';
import AppThemeProvider from './AppThemeProvider';
import HomePage from './home';
import './App.css';
import FailedPage from './page/FailedPage';
import SuccessPage from './page/SuccessPage';
import FAQPage from './page/FAQ';
import SecurePortal from './secure-portal/SecurePortal';
import { SessionProvider } from './secure-portal/session/Session.context';
import { QueryParamProvider } from 'use-query-params';
import UserProvider, { UserContext } from './user/User.context';
import Login from './login/Login';
import CreateUserPage from './page/CreateUserPage';
import NotFound404 from './page/NotFound404';
import ThankYouPage from './page/ThankYouPage';

//TODO: study about 'socket.io-client' then implement <SessionProvider>
function App() {
  return (
    <AppThemeProvider>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <UserProvider>
            <div className='App'>
              <header className='App-header'>
                <Switch>
                  <Route exact path='/'>
                    <HomePage />
                  </Route>
                  {/* <Route exact path='/'>
                    <UserContext.Consumer>
                      {user =>
                        user ? (
                          <Redirect to='/portal/transactions' />
                        ) : (
                          <Redirect to='/login' />
                        )
                      }
                    </UserContext.Consumer>
                  </Route> */}
                  <Route exact path='/login' component={Login} />
                  <ProtectedRoute path='/portal' component={SecurePortal} />
                  <Route exact path='/failed'>
                    <FailedPage />
                  </Route>
                  <Route exact path='/success'>
                    <SuccessPage />
                  </Route>
                  <Route exact path='/thank-you'>
                    <ThankYouPage />
                  </Route>
                  <Route exact path='/FAQ'>
                    <FAQPage />
                  </Route>
                  <Route exact path='/404'>
                    <NotFound404 />
                  </Route>
                </Switch>
              </header>
            </div>
          </UserProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </AppThemeProvider>
  );
}

export default App;
