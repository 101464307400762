import React from 'react';

const NotFound404 = () => {
  return (
    <div>
      <h2>Oops! </h2>
      <h1>404 </h1>
      <h1>PAGE NOT FOUND</h1>
    </div>
  );
};

export default NotFound404;
