import React, { useContext } from 'react';
import styled from 'styled-components';

import { List } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import MapIcon from '@material-ui/icons/Map';

import AppMenuItem from './AppMenuItem';
import PaymentIcon from '@material-ui/icons/Payment';

import { UserContext } from '../user/User.context';
import { UserRole } from './users/user.interface';

export interface NavigationItem {
  heading?: string;
  icon?: JSX.Element;
  label?: string;
  path?: string;
  roles?: string[];
  items?: NavigationItem[];
}

const navigationList: NavigationItem[] = [
  {
    icon: <DashboardIcon />,
    label: 'Dashboard',
    path: '/portal/dashboard',
    roles: [UserRole.admin, UserRole.accounting, UserRole.controller]
  },
  // {
  //   icon: <ReceiptIcon />,
  //   label: 'All Paid Transaction',
  //   path: '/portal/transactions',
  //   roles: [UserRole.admin, UserRole.accounting, UserRole.controller]
  // },
  {
    icon: <PaymentIcon />,
    label: 'Dragonpay',
    roles: [UserRole.admin, UserRole.accounting, UserRole.controller],
    items: [
      {
        label: 'Payments',
        path: '/portal/payments-dragonpay'
      },
      {
        label: 'Transactions',
        path: '/portal/transactions-dragonpay'
      },
    ]
  },
  {
    icon: <PaymentIcon />,
    label: 'Paymongo',
    roles: [UserRole.admin, UserRole.accounting, UserRole.controller],
    items: [
      {
        label: 'All Paid Transaction',
        path: '/portal/transactions'
      },
      {
        label: 'GCash',
        path: '/portal/gcash'
      },
      {
        label: 'PayMaya',
        path: '/portal/paymaya'
      },
      {
        label: 'Paymongo',
        path: '/portal/paymongo'
      },
      {
        label: 'Failed Paymongo Card',
        path: '/portal/logs'
      }
    ]
  },
  // {
  //   icon: <ErrorIcon />,
  //   label: 'Failed Paymongo',
  //   path: '/portal/logs',
  //   roles: [UserRole.admin]
  // },
  // {
  //   icon: <ContactlessIcon />,
  //   label: 'GCash',
  //   path: '/portal/gcash',
  //   roles: [UserRole.admin, UserRole.accounting, UserRole.controller]
  // },
  // {
  //   icon: <AccountBalanceWalletIcon />,
  //   label: 'PayMaya',
  //   path: '/portal/paymaya',
  //   roles: [UserRole.admin, UserRole.accounting, UserRole.controller]
  // },
  {
    icon: <MapIcon />,
    label: 'Districts',
    path: '/portal/district',
    roles: [UserRole.admin]
  },
  {
    icon: <LocationCityIcon />,
    label: 'Church',
    path: '/portal/church',
    roles: [UserRole.admin]
  },
  {
    icon: <PeopleIcon />,
    label: 'Users',
    path: '/portal/users',
    roles: [UserRole.admin]
  }
];

const StyledList = styled(List)`
  overflow: auto;
`;

export interface NavigationListProps {
  onNavigationItemClicked?: (item: NavigationItem) => void;
  renderAfterItem?: (item: NavigationItem) => JSX.Element | null;
}

const NavigationList: React.FC<NavigationListProps> = props => {
  const userContext = useContext(UserContext);
  const { user } = userContext;

  function hasAccess(item: NavigationItem) {
    return item?.roles?.some(role => user?.role === role);
  }

  return (
    <StyledList dense>
      {navigationList.map((item, index) => {
        if (item && user && hasAccess(item)) {
          return <AppMenuItem {...item} key={index} />;
        }
        return null;
      })}
    </StyledList>
  );
};

export default NavigationList;
