import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis
} from '@devexpress/dx-react-chart-material-ui';
import { numberWithCommas } from '../../../common/Util';
import { Animation } from '@devexpress/dx-react-chart';

const ValueLabel = props => {
  const { text } = props;
  return <ValueAxis.Label {...props} text={`₱ ${numberWithCommas(text)}`} />;
};

export const monthName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const MonthlyTotal = props => {
  const monthly = props?.data?.data ? props?.data?.data : [];

  const parsed = monthly.map(item => {
    return {
      total: Number(item.total),
      month: monthName[item.month - 1],
      year: item.year
    };
  });

  return (
    <Paper>
      <Chart data={parsed}>
        <ArgumentAxis />
        <ValueAxis max={12} labelComponent={ValueLabel} />

        <BarSeries valueField='total' argumentField='month' />
        <Title text='Payments' />
        <Animation />
      </Chart>
    </Paper>
  );
};

export default MonthlyTotal;
