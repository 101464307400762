import { Form, Formik, FormikValues } from 'formik';
import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import styled from 'styled-components';
import FormikButton from '../../../common/formik/FormikButton';
import FormikGrid from '../../../common/formik/FormikGrid';
import FormikTextField from '../../../common/formik/FormikTextField';
import { Church } from '../church.interface';
import { useUpdateChurch } from '../church.hook';
import FormikAutocomplete from '../../../common/formik/FormikAutocomplete';
import { useFindAllDistrict } from '../../district/district.hook';
import Autocomplete from '@material-ui/lab/Autocomplete';

const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  .select-field {
    margin-right: 22px;
  }
  .MuiAutocomplete-root {
    display: flex;
    flex-direction: column;
  }
`;

interface CreateUserForm {
  name: string;
  districtId: number;
  address: string;
}

const validationSchema: Yup.ObjectSchema<CreateUserForm> = Yup.object().shape({
  name: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  districtId: Yup.number().required('Required'),
  // area: Yup.string().required('Required'),
});

export interface ChurchCreateDialogProps {
  church: Church | null;
  open: boolean;
  onCancel?: () => void;
  onCreated?: (church: Church) => void;
}

const EditChurchDialog: React.FC<ChurchCreateDialogProps> = ({
  church,
  open,
  onCancel,
  onCreated
}) => {
  const formikRef = useRef<FormikValues>();
  const { response, isLoading, error, put } = useUpdateChurch(church?.id || 0);
  const { list, isLoading: loadingDistrict, get } = useFindAllDistrict();

  useEffect(() => {
    get();
  }, [])

  useEffect(() => {
    if (!isLoading) {
      if (onCreated && response) {
        onCreated(response);
      }
      if (formikRef && formikRef.current) {
        formikRef.current.setSubmitting(false);
      }
    }
  }, [isLoading, onCreated, response]);

  return (
    <Dialog open={open} onClose={onCancel} maxWidth='xs' disableBackdropClick>
      <Formik
        innerRef={formik => (formikRef.current = formik)}
        initialValues={{ 
          name: church?.name, 
          merchantId: church?.merchantId, 
          merchantKey: church?.merchantKey,
          districtId: church?.districtId,
          address: church?.address,
          email: church?.email,
          contactNumber: church?.contactNumber,
          accountant: church?.accountant,
          accountantContactNumber: church?.accountantContactNumber 
        }}
        validationSchema={validationSchema}
        onSubmit={(churchDto: Partial<Church>) => {
          put(churchDto);
        }}
      >
        {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values
      }) => {
        return (
        <Form autoComplete='off'>
          <DialogTitle>Edit Church</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>Church Information</DialogContentText> */}
            <FormikGrid>
              <FormikTextField
                autoFocus
                type='text'
                name='name'
                label='Name'
              />

              {list?.data && 
                <StyledGrid item className='formik-autocomplete' {...{ xs: 12 }}>
                  <Autocomplete
                      id="districtId"
                      options={list?.data}
                      defaultValue={church?.district}
                      getOptionLabel={(option: any) => `${option.name} - Area ${option.area}`}
                      onChange={(_: any, value: any) => {
                        value?.id && setFieldValue('districtId', value.id)
                      }}
                      renderInput={(params) => 
                        <TextField 
                          {...params} 
                          label="District" 
                          variant="outlined" 
                        />
                      }
                  />
                </StyledGrid>
              }
              <FormikTextField type='text' name='address' label='Address' />
              <FormikTextField type='text' name='email' label='Email' />
              <FormikTextField type='text' name='contactNumber' label='Contact Number' />
              <FormikTextField type='text' name='merchantId' label='Merchant ID' />
              <FormikTextField type='text' name='merchantKey' label='Merchant Key' />
              <div style={{ marginTop: 25, marginLeft: 10 }}>Accountant: </div>
              <FormikTextField type='text' name='accountant' label='Name' />
              <FormikTextField type='text' name='accountantContactNumber' label='Contact Number' />
            </FormikGrid>
            {error && (
              <Alert severity='error'> { error ? error.message : '' }</Alert>
            )}
          </DialogContent>
          <DialogActions style={{ marginBottom: 10 }}>
            <Button onClick={onCancel}>Cancel</Button>
            <FormikButton>Update</FormikButton>
          </DialogActions>
        </Form>
        )}}
      </Formik>
    </Dialog>
  );
};

export default EditChurchDialog;
