import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import MonthlyTotal from './monthlyTotalBarChart/monthlyTotal';
import {
  useChartMonthly,
  useTotalTransactionPerArea,
  usePercentagePerPaymentMethod
} from './Dashboard.hook';
import PlatformChart from './PlatformChart';
import AreaChart from './AreaChart';

const DashboardPage: React.FC = () => {
  const { monthly, isLoading, get } = useChartMonthly();
  const { areaPercentage, getArea } = useTotalTransactionPerArea();
  const { platformPercentage, getPlatform } = usePercentagePerPaymentMethod();
  console.log('DashboardPage:React.FC -> areaList', platformPercentage);

  useEffect(() => {
    get();
    getArea();
    getPlatform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div style={{ width: '100%', height: 100, backgroundColor: '#1769aa' }}>
        <div
          style={{
            textAlign: 'left',
            color: '#fff',
            marginLeft: 20,
            marginTop: 30,
            position: 'absolute'
          }}
        >
          <h2>Dashboard</h2>
        </div>
      </div>
      <div style={{ padding: 25 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <MonthlyTotal data={monthly} />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <PlatformChart platformPercentage={platformPercentage} />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <AreaChart areaPercentage={areaPercentage} />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default DashboardPage;
