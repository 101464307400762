import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { DateRangePicker } from 'react-date-range';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CSVLink } from 'react-csv';
import { CircularProgress } from '@material-ui/core';
import { useExportTransactions } from '../transaction/Transaction.hook';
import { ExportTransactionDto } from '../transaction/transaction.interface';
import { formatExportDate, formatDate } from '../../common/formatter';

export interface ExportDialogProps {
  open: boolean;
  onCancel: () => void;
}
const ExportDragonpayDialog: React.FC<ExportDialogProps> = ({ open, onCancel }) => {
  const [{ data: exports, error, isLoading }, post] = useExportTransactions();
  const [exportData, setExportData] = useState<any>();

  useEffect(() => {
    exports && setExportData(exports.data);
  }, [exports]);

  const handleClose = () => {
    onCancel();
  };

  const [state, setState] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: 'exportDate'
  });

  const handleExport = () => {
    const formatedDate: ExportTransactionDto = {
      startDate: formatExportDate(state.startDate),
      endDate: formatExportDate(state.endDate)
    };
    post(formatedDate);
  };

  const startDate = formatDate(state.startDate);
  const endDate = formatDate(state.endDate);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Export Dragonpay Payments</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify='space-around'>
                <DateRangePicker
                  onChange={(item: any) => setState(item.exportDate)}
                  ranges={[state]}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ marginBottom: 8 }}>
          {exportData && (
            <CSVLink
              filename={`CLC_Giving_Paid_Transaction_${startDate}-${endDate}.csv`}
              data={exportData}
              target='_blank'
              style={{
                textDecoration: 'none',
                backgroundColor: '#2196F3',
                borderRadius: 3,
                marginRight: 234
              }}
            >
              <Button color='primary' autoFocus style={{ color: '#fff' }}>
                <GetAppIcon style={{ marginRight: 0 }} />
                Download
              </Button>
            </CSVLink>
          )}
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          {isLoading ? (
            <CircularProgress />
          ) : (
              <Button
                onClick={handleExport}
                color='primary'
                autoFocus
                style={{
                  textDecoration: 'none',
                  backgroundColor: '#2196F3',
                  borderRadius: 3,
                  color: '#fff'
                }}
              >
                Generate
              </Button>
            )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExportDragonpayDialog;
