import styled from 'styled-components';
import { Form as FormikForm } from 'formik';
import {
  Card as MuiCard,
  CardContent as MuiCardContent
} from '@material-ui/core';

export const StyledLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #eeeeee;
`;

export const StyledLoginCard = styled(MuiCard)`
  display: flex;
  flex-direction: column;
  max-width: 320px;
  width: 80%;
  padding: 40px;
  align-items: center;
  .logo {
    width: 220px;
  }
  h2 {
    margin: 20px 0 40px 0;
  }
  .forgot-password-link {
    margin: 20px 10px 0px 10px;
  }
`;

export const StyledLoginForm = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
  .form-field {
    margin-bottom: 20px;
  }
  button {
    color: #fff;
    background-color: #2196f3;
  }
  .error {
    color: red;
    font-size: 12px;
  }
`;

export const StyledLoginCardContent = styled(MuiCardContent)`
  padding: 0;
  text-align: center;
`;
