import { reloadApp } from './../../user/User.hooks';
import {
  PayMayaTransaction,
  ExportPayMayaTransactionDto
} from './paymaya.interface';
import { useApiGet, useApiPost, ApiResult } from '../../common/api/Api.hooks';
import { PaginatedList } from '../../common/PaginatedList.interface';
import { useEffect } from 'react';

interface ApiResponse {
  success: boolean;
  data?: any;
}

export function useFindPayMayaTransaction() {
  const { data, isLoading, error, get } = useApiGet<
    PaginatedList<PayMayaTransaction>
  >('transaction/paymaya');
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  return { transactionList: data, isLoading, error, get };
}

export function useExportTransactions(): [
  ApiResult<any>,
  (date: ExportPayMayaTransactionDto) => void
] {
  const { post: apiPost, error, ...result } = useApiPost<
    ExportPayMayaTransactionDto
  >('transaction/export-paymaya');
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  const post = (date: ExportPayMayaTransactionDto) => apiPost(date);
  return [result, post];
}
