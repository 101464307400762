import React from 'react';
import Chip from '@material-ui/core/Chip';

const TransactionStatusPill = (props: { paymentId?: any }) => {
  const isPaid = props.paymentId ? true : false;
  return (
    <Chip
      label={isPaid ? 'PAID' : 'PENDING'}
      variant='outlined'
      size='small'
      style={isPaid ? styles.paid : styles.pending}
    />
  );
};

const styles = {
  paid: {
    minWidth: 78,
    backgroundColor: '#59c726',
    color: '#fff',
    borderColor: '#59c726'
  },
  pending: {
    minWidth: 78,
    backgroundColor: '#ffb612',
    color: '#fff',
    borderColor: '#ffb612'
  }
};

export default TransactionStatusPill;
