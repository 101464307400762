import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import { numberWithCommas } from '../../common/Util';

const StyledTitle = styled.div`
  font-size: 1.5rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  margin-bottom: 10px;
  padding-top: 20px;
`;

const PlatformChart: React.FC<{ platformPercentage: any }> = ({
  platformPercentage
}) => {
  let data = [];

  if (platformPercentage?.success) {
    data = platformPercentage.data.reverse().map((item: any) => {
      let platformName: string = '';

      if (item.paymentMethod === 'card') {
        platformName = 'Visa/MasterCard';
      } else if (item.paymentMethod === 'gcash') {
        platformName = 'GCash';
      } else if (item.paymentMethod === 'grab_pay') {
        platformName = 'Grab Pay';
      } else if (item.paymentMethod === 'paymaya') {
        platformName = 'PayMaya';
      } else {
        platformName = 'Others';
      }

      return {
        platform: platformName,
        value: item.total
      };
    });
  }
  return (
    <Paper>
      <StyledTitle>Percentage Per Platform</StyledTitle>
      <div style={{ height: 400 }}>
        <ResponsiveBar
          data={data}
          keys={['value']}
          indexBy='platform'
          margin={{ top: 20, right: 60, bottom: 80, left: 100 }}
          padding={0.3}
          layout='horizontal'
          colors={['#61cdbb', '#3498db', '#f1c40f', '#95a5a6']}
          label={d => `₱ ${numberWithCommas(d.value)}`}
          // defs={[
          //   {
          //     id: 'dots',
          //     type: 'patternDots',
          //     background: 'inherit',
          //     color: '#38bcb2',
          //     size: 4,
          //     padding: 1,
          //     stagger: true
          //   },
          //   {
          //     id: 'lines',
          //     type: 'patternLines',
          //     background: 'inherit',
          //     color: '#eed312',
          //     rotation: -45,
          //     lineWidth: 6,
          //     spacing: 10
          //   }
          // ]}
          // fill={[
          //   {
          //     match: {
          //       id: 'fries'
          //     },
          //     id: 'dots'
          //   },
          //   {
          //     match: {
          //       id: 'sandwich'
          //     },
          //     id: 'lines'
          //   }
          // ]}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 64
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: 'food',
            legendPosition: 'middle',
            legendOffset: -100
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          // legends={[
          //   {
          //     dataFrom: 'keys',
          //     anchor: 'bottom',
          //     direction: 'row',
          //     justify: false,
          //     translateX: 0,
          //     translateY: 56,
          //     itemsSpacing: 2,
          //     itemWidth: 100,
          //     itemHeight: 18,
          //     itemDirection: 'left-to-right',
          //     symbolSize: 18,
          //     symbolShape: 'circle',
          //     effects: [
          //       {
          //         on: 'hover',
          //         style: {
          //           itemOpacity: 1
          //         }
          //       }
          //     ]
          //   }
          // ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    </Paper>
  );
};

export default PlatformChart;
