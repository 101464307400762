import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useMarkAsPaid } from '../secure-portal/transaction/Transaction.hook';
import { CircularProgress } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MessageAlert from './MessageAlert';

export interface MarkAsPaidProps {
  title?: string;
  transactionId: number;
  onSuccess: (data: any) => void;
}

const MarkAsPaid: React.FC<MarkAsPaidProps> = ({
  title,
  transactionId,
  onSuccess
}) => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [inputRef, setInputRef] = useState<any>('');
  const [isSendEmail, setiIsSendEmail] = useState(true);

  const {
    transaction,
    isLoading: isUpdating,
    error: updateError,
    patch
  } = useMarkAsPaid(transactionId);

  useEffect(() => {
    if (transaction?.success) {
      transaction && onSuccess(transaction.data);
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  useEffect(() => {
    updateError && setErrorMessage(updateError?.message);
  }, [updateError]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    patch({ paymentId: inputRef, isSendEmail });
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{
          backgroundColor: '#00c1ff',
          color: 'white',
          padding: 5,
          margin: 5,
          marginTop: 10,
          width: 170
        }}
      >
        Mark as Paid
      </Button>
      <Dialog
        open={open}
        maxWidth={'xs'}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            size='medium'
            margin='dense'
            id='name'
            label='Reference No.'
            value={inputRef}
            onChange={(e: any) => setInputRef(e.target.value)}
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isSendEmail}
                onChange={() => setiIsSendEmail(!isSendEmail)}
                name='sendEmail'
                color='primary'
              />
            }
            label='Send email confirmation.'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          {isUpdating ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={handleSubmit}
              color='primary'
              disabled={!Boolean(inputRef)}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <MessageAlert
        title={'Error'}
        open={Boolean(errorMessage)}
        body={errorMessage}
        onClose={() => setErrorMessage(null)}
      />
    </div>
  );
};

export default MarkAsPaid;
