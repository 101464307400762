import { Payment } from './payment.interface';
import { useApiGet, useApiPost, ApiResult } from '../../common/api/Api.hooks';
import { PaginatedList } from '../../common/PaginatedList.interface';

export function useFindTransactions() {
  const { data, isLoading, error, get } = useApiGet<PaginatedList<Payment>>(
    'transaction'
  );
  return { transactionList: data, isLoading, error, get };
}

export function useFindDragonpayPayments() {
  const { data, isLoading, error, get } = useApiGet<PaginatedList<any>>(
    'payment/dragonpay'
  );
  return { paymentList: data, isLoading, error, get };
}