export interface Environment {
  apiBaseUrl: string;
  accessTokenKey: string;
  bearerPrefix: string;
  accessDeniedPath: string;
  expires?: number;
  secure?: boolean;
  domain?: string;
}
const env = process.env.REACT_APP_CLIENT_ENV || 'development';
const environment = require(`./environment.${env}`).default;
export default environment as Environment;
