import { useCallback, useContext, useEffect, useState } from 'react';
import api from '../common/api/api';
import {
  useApiGet,
  useApiPatch,
  useApiPost,
  useApiPut
} from '../common/api/Api.hooks';
import { JwtToken } from './JwtToken.interface';
import { UserContext, UserContextType } from './User.context';
import { User, VerifyEmail } from './User.interface';

export const reloadApp = (error: any) => {
  if (error && error.statusCode === 401) {
    alert('Session expired, please login again.');
    window.location.reload(true);
  }
};
export interface LoginRequest {
  email: string;
  password: string;
}

export interface PasswordRecoverRequest {
  email: string;
}

export function useGetCurrentUser() {
  const { data, isLoading, error, get } = useApiGet<UserContextType>('auth/me');
  let user = data;
  if (!api.getAccessToken() || error) {
    // if no access token set cached user to null
    user = null; // user == null (not logged in)
  }
  return { user, isLoading, error, get };
}

export function useLogin() {
  const [jwtToken, setJwtToken] = useState<JwtToken>();
  const { user, setUser } = useContext(UserContext);

  const { data, isLoading, error, post } = useApiPost<JwtToken>('auth/login');

  useEffect(() => {
    if (data) {
      const jwt = data;
      setJwtToken(jwt);
      api.setAccessToken(jwt.accessToken);
      setUser(jwt.user);
    }
  }, [data, setUser]);

  const doLogin = useCallback(
    (email: string, password: string) => {
      post({ email, password });
    },
    [post]
  );
  return { jwtToken, user, isLoading, error, doLogin };
}

export function useLogout() {
  const { setUser } = useContext(UserContext);
  const { isLoading, error, post } = useApiPost<User>('auth/logout');

  // useEffect(() => {
  //   if (response && response.ok) {
  //     console.log('user logged out', response.ok);
  //     api.removeAccessToken();
  //     setUser(null);
  //   }
  // }, [response, setUser]);

  const doLogout = useCallback(() => {
    post();
    // NOTE: logout endpoint doesn't return anything so above
    // useEffect() won't work
    api.removeAccessToken();
    setUser(null);
  }, [post, setUser]);
  return { isLoading, error, doLogout };
}

export function useFindUsers(shouldFetchOnMount = false) {
  const { data, isLoading, error, get } = useApiGet<any>('users');
  useEffect(() => {
    if (shouldFetchOnMount) {
      get();
    }
  }, [get, shouldFetchOnMount]);
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  return { userList: data, isLoading, error, get };
}

export function useCreateUser() {
  const { data, isLoading, error, post } = useApiPost<User>('users');
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  return { user: data, isLoading, error, post };
}

export function useRegisterUser() {
  const { data, isLoading, error, post } = useApiPost<User>('users/register');
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  return { user: data, isLoading, error, post };
}

export function useSendInvite(id: number) {
  const { data, isLoading, error, post } = useApiPost<User>(
    `users/${id}/send-invitaion`
  );
  return { data, isLoading, error, post };
}

export function useVerifyEmail() {
  const { data, isLoading, error, get } = useApiGet<VerifyEmail>(
    'users/verify/email'
  );
  return { emailVerified: data, isLoading, error, get };
}

export function useFindUser(id: number, shouldFetchOnMount = false) {
  const { data, isLoading, error, get } = useApiGet<User>(`users/${id}`);
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  useEffect(() => {
    if (shouldFetchOnMount) {
      get();
    }
  }, [get, id, shouldFetchOnMount]);
  return { user: data, isLoading, error, get };
}

export function usePasswordRecover() {
  const { data: success, isLoading, error, post } = useApiPost<boolean>(
    'users/password/recover'
  );

  const doPasswordRecover = useCallback(
    (email: string) => {
      post({ email });
    },
    [post]
  );

  return { success, isLoading, error, doPasswordRecover };
}

export function usePasswordVerifyResetToken() {
  const { data: user, isLoading, error, get } = useApiGet<boolean>(
    'users/password/verify/reset-token'
  );

  const doVerifyResetToken = useCallback(
    (token: string) => {
      get({ token });
    },
    [get]
  );

  return { user, isLoading, error, doVerifyResetToken };
}

export function usePasswordReset() {
  const { user, doVerifyResetToken, ...verify } = usePasswordVerifyResetToken();

  let { data: success, isLoading, error, put } = useApiPut<boolean>(
    'users/password/reset'
  );

  isLoading = isLoading || verify.isLoading;

  const doPasswordReset = useCallback(
    (password: string, token: string) => {
      put({ password, token });
    },
    [put]
  );

  return {
    user,
    success,
    isLoading,
    error,
    verifyError: verify.error,
    doPasswordReset,
    doVerifyResetToken
  };
}

export function usePatchUser(id: number) {
  const { data, isLoading, error, patch } = useApiPatch<User>(`users/${id}`);
  return { user: data, isLoading, error, patch };
}

export function useUpdateUser(id: number) {
  const { data, isLoading, error, put } = useApiPut<User>(`users/${id}`);
  return { user: data, isLoading, error, put };
}
