import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';
import { formatDate } from '../../common/formatter';

const StyledText = styled(Typography)`
  font-size: 15px;
`;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface PaymentDetailDialogProps {
  payment: any;
  open: boolean;
  onCancel: () => void;
}

const PaymentDetailDialog: React.FC<PaymentDetailDialogProps> = ({
  payment,
  open,
  onCancel
}) => {
  const handleClose = () => {
    onCancel();
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {payment.referenceId}
        </DialogTitle>
        <DialogContent dividers>
          <StyledText gutterBottom>
            <b>Last Name: </b>
            {payment.lastName}
          </StyledText>
          <StyledText gutterBottom>
            <b>First Name: </b>
            {payment.firstName}
          </StyledText>
          <StyledText gutterBottom>
            <b>Email: </b>
            {payment.email}
          </StyledText>
          <StyledText gutterBottom>
            <b>Contact Number: </b>
            {payment.contactNumber ? payment.contactNumber : '-'}
          </StyledText>
          <StyledText gutterBottom>
            <b>Church: </b>
            {payment.churchName ? payment.churchName : '-'}
          </StyledText>
          <StyledText gutterBottom>
            <b>Area: </b>
            {payment.area ? payment.area : '-'}
          </StyledText>
          <StyledText gutterBottom>
            <b>Date: </b>
            {formatDate(payment.paymentDate)}
          </StyledText>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Allocation</TableCell>
                  <TableCell align='right'>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Tithe:
                  </TableCell>
                  <TableCell align='right'>₱ {payment.tithe}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Offering:
                  </TableCell>
                  <TableCell align='right'>₱ {payment.offering}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    {'Other'}:
                  </TableCell>
                  <TableCell align='right'>₱ {payment.otherAmount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    <b>Total Amount:</b>
                  </TableCell>
                  <TableCell align='right'>₱ {payment.totalAmount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <StyledText gutterBottom style={{ marginTop: 20 }}>
            <b>Fee: </b>
            <span>₱ {payment.paymentFee}</span>
          </StyledText>
          <StyledText gutterBottom>
            <b>Payment Method: </b>
            <span style={{ textTransform: 'uppercase' }}>
              {payment.paymentMethod}
            </span>
          </StyledText>
          <StyledText gutterBottom>
            <b>Status: </b>
            {payment.paymentId ? 'Paid' : <i>Pending</i>}
          </StyledText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default PaymentDetailDialog;
