import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import { OUTSIDE_CLC } from './AreaInput';
import { useFindAllChurch } from '../../secure-portal/church/church.hook';
import { Church } from '../../secure-portal/church/church.interface';

export interface ChurchInputProps {
  church: Church;
  area: string;
  setChurch: (value: string) => void;
  setArea: (value: string) => void;
}

interface ChurchOptionType {
  inputValue?: string;
  church: string;
  area?: any;
  district?: string;
}

// const filter = createFilterOptions<ChurchOptionType>();

const ChurchInput: React.FC<ChurchInputProps> = ({
  church,
  area,
  setChurch,
  setArea
}) => {
  const [selectArea, setSelectArea] = useState<string>(area);
  const { list, get } = useFindAllChurch();

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    setSelectArea(area);
  }, [area]);

  const options = list?.data?.filter((church: any) => {
      if (church.area.toString() === selectArea) {
        return true;
      } else if (selectArea === '') {
        return true;
      }
      return false;
    })
    .map((option: any) => ({ district: option.district, ...option }));

  const handleValueChange = (value: any) => {
    if (value?.inputValue) {
      setChurch(value ? value.inputValue : '');
    } else {
      setChurch(value ? value : '');
      value?.area && setArea(value?.area.toString());
    }
  };

  return (
    <React.Fragment>
      {selectArea === OUTSIDE_CLC.value ? (
        <TextField
          fullWidth
          label='Church'
          variant='outlined'
          placeholder='Specify Church'
          onChange={(e: any) => {
            setChurch(e.target.value);
          }}
        />
      ) : (
        <Autocomplete
          onChange={(event: any, value: any) => {
            handleValueChange(value);
          }}
          // filterOptions={(options: any, params: any) => {
          //   const filtered = filter(options, params);

          //   // Suggest the creation of a new value
          //   if (params.inputValue !== '') {
          //     filtered.push({
          //       inputValue: params.inputValue,
          //       church: `Add "${params.inputValue}" church`
          //     });
          //   }

          //   return filtered;
          // }}
          value={church?.name || ''}
          selectOnFocus
          options={options || []}
          groupBy={(option: any) => option.district}
          getOptionLabel={option => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          renderOption={option => option.name}
          freeSolo
          style={{ width: '100%' }}
          renderInput={params => (
            <TextField
              {...params}
              value={params}
              label='Church'
              variant='outlined'
            />
          )}
        />
      )}
    </React.Fragment>
  );
};

export default ChurchInput;

const churches = [
  { area: 1, district: 'Baler', church: 'Baler' },
  { area: 1, district: 'Baler', church: 'Dicapinisan' },
  { area: 1, district: 'Baler', church: 'Diotorin' },
  { area: 1, district: 'Baler', church: 'Duongan' },
  { area: 1, district: 'Baler', church: 'Maria' },
  { area: 1, district: 'Baler', church: 'Dipaculao' },
  { area: 1, district: 'Baler', church: 'Dinadiawan' },
  { area: 1, district: 'Baler', church: 'Diarabasin' },
  { area: 1, district: 'Baler', church: 'Borlongan' },
  { area: 1, district: 'Baler', church: 'Bayanihan' },

  { area: 1, district: 'South Aurora', church: 'Bacong' },
  { area: 1, district: 'South Aurora', church: 'Diabuyo' },
  { area: 1, district: 'South Aurora', church: 'San Luis' },
  { area: 1, district: 'South Aurora', church: 'Dimani' },
  { area: 1, district: 'South Aurora', church: 'Ditumabo' },
  { area: 1, district: 'South Aurora', church: 'Suguit' },
  { area: 1, district: 'South Aurora', church: 'Camalatan' },
  { area: 1, district: 'South Aurora', church: 'San Isidro Co.' },

  { area: 1, district: 'North Aurora 1', church: 'Casiguran' },
  { area: 1, district: 'North Aurora 1', church: 'Dinalungan' },
  { area: 1, district: 'North Aurora 1', church: 'Bianoan' },
  { area: 1, district: 'North Aurora 1', church: 'Simbahan' },
  { area: 1, district: 'North Aurora 1', church: 'Maravini' },
  { area: 1, district: 'North Aurora 1', church: 'Calabgan' },
  { area: 1, district: 'North Aurora 1', church: 'Marikit' },
  { area: 1, district: 'North Aurora 1', church: 'Dibacong' },
  { area: 1, district: 'North Aurora 1', church: 'Paraiso' },
  { area: 1, district: 'North Aurora 1', church: 'Esperanza' },

  { area: 1, district: 'Northern Coast Aurora', church: 'Dilaguidi' },
  { area: 1, district: 'Northern Coast Aurora', church: 'Dilasag' },
  { area: 1, district: 'Northern Coast Aurora', church: 'Dinapigue' },
  { area: 1, district: 'Northern Coast Aurora', church: 'Gumacas' },

  { area: 1, district: 'Palanan', church: 'Palanan Centro' },
  { area: 1, district: 'Palanan', church: 'Didian' },
  { area: 1, district: 'Palanan', church: 'Dipagsangjan' },
  { area: 1, district: 'Palanan', church: 'Bisag' },
  { area: 1, district: 'Palanan', church: 'Dipangpang' },
  { area: 1, district: 'Palanan', church: 'Dipaguiden' },
  { area: 1, district: 'Palanan', church: 'Ditambali' },
  { area: 1, district: 'Palanan', church: 'Culasi' },
  { area: 1, district: 'Palanan', church: 'Dialaoyao' },

  { area: 2, district: 'North Nueva Ecija', church: 'Abebeg' },
  { area: 2, district: 'North Nueva Ecija', church: 'Bunga' },
  { area: 2, district: 'North Nueva Ecija', church: 'Camiling' },
  { area: 2, district: 'North Nueva Ecija', church: 'Carranglan' },
  { area: 2, district: 'North Nueva Ecija', church: 'Muñoz' },
  { area: 2, district: 'North Nueva Ecija', church: 'San Jose City' },
  { area: 2, district: 'North Nueva Ecija', church: 'Digdig' },

  { area: 2, district: 'West Nueva Ecija', church: 'Camiing' },
  { area: 2, district: 'West Nueva Ecija', church: 'Cawayan Bugtong' },
  { area: 2, district: 'West Nueva Ecija', church: 'Guimba' },
  { area: 2, district: 'West Nueva Ecija', church: 'Nangabulan' },
  { area: 2, district: 'West Nueva Ecija', church: 'Sto. Domingo' },
  { area: 2, district: 'West Nueva Ecija', church: 'Talavera' },
  { area: 2, district: 'West Nueva Ecija', church: 'Saguingan' },
  { area: 2, district: 'West Nueva Ecija', church: 'Licab' },

  { area: 2, district: 'Central Nueva Ecija 1', church: 'Cabanatuan' },
  { area: 2, district: 'Central Nueva Ecija 1', church: 'Zaragosa' },
  { area: 2, district: 'Central Nueva Ecija 1', church: 'Sta. Rosa' },
  { area: 2, district: 'Central Nueva Ecija 1', church: 'Aliaga' },

  { area: 2, district: 'Central Nueva Ecija 2', church: 'Agbanawag' },
  { area: 2, district: 'Central Nueva Ecija 2', church: 'Llanera' },
  { area: 2, district: 'Central Nueva Ecija 2', church: 'Pantabangan' },
  { area: 2, district: 'Central Nueva Ecija 2', church: 'Rizal' },
  {
    area: 2,
    district: 'Central Nueva Ecija 2',
    church: 'Gen. Mamerto Natividad'
  },

  { area: 2, district: 'South Nueva Ecija 1', church: 'Cabiao' },
  { area: 2, district: 'South Nueva Ecija 1', church: 'Sinipit' },
  { area: 2, district: 'South Nueva Ecija 1', church: 'San Roque' },
  { area: 2, district: 'South Nueva Ecija 1', church: 'Concepcion' },
  { area: 2, district: 'South Nueva Ecija 1', church: 'Sta Isabel' },

  { area: 2, district: 'South Nueva Ecija 2', church: 'Campugo' },
  { area: 2, district: 'South Nueva Ecija 2', church: 'Jaen' },
  { area: 2, district: 'South Nueva Ecija 2', church: 'Gapan' },
  { area: 2, district: 'South Nueva Ecija 2', church: 'San Antonio' },
  { area: 2, district: 'South Nueva Ecija 2', church: 'Peñaranda ' },
  { area: 2, district: 'South Nueva Ecija 2', church: 'Bulak' },
  { area: 2, district: 'South Nueva Ecija 2', church: 'KC1 ' },
  { area: 2, district: 'South Nueva Ecija 2', church: 'San Leonardo' },
  { area: 2, district: 'South Nueva Ecija 2', church: 'Mabalas' },
  { area: 2, district: 'South Nueva Ecija 2', church: 'General Tinio ' },

  { area: 2, district: 'East Nueva Ecija', church: 'Bugnan' },
  { area: 2, district: 'East Nueva Ecija', church: 'Dingalan' },
  { area: 2, district: 'East Nueva Ecija', church: 'Gabaldon' },
  { area: 2, district: 'East Nueva Ecija', church: 'Laur' },
  { area: 2, district: 'East Nueva Ecija', church: 'San Joseph' },
  { area: 2, district: 'East Nueva Ecija', church: 'San Vicente' },
  { area: 2, district: 'East Nueva Ecija', church: 'Palayan' },
  { area: 2, district: 'East Nueva Ecija', church: 'Fort Magsaysay' },
  { area: 2, district: 'East Nueva Ecija', church: 'Siclong' },

  { area: 2, district: 'East Central N.E.', church: 'Bongabon' },
  { area: 2, district: 'East Central N.E.', church: 'Macapsing' },
  { area: 2, district: 'East Central N.E.', church: 'Olivette' },
  { area: 2, district: 'East Central N.E.', church: 'Salabusob' },
  { area: 2, district: 'East Central N.E.', church: 'Tamale (Digmala)' },
  { area: 2, district: 'East Central N.E.', church: 'Tugatog' },
  { area: 2, district: 'East Central N.E.', church: 'Palomaria' },
  { area: 2, district: 'East Central N.E.', church: 'Pesa' },
  { area: 2, district: 'East Central N.E.', church: 'GB Ariendo' },

  { area: 2, district: 'Calaanan', church: 'Calaanan' },

  { area: 3, district: 'Central Bataan', church: 'Binukawan' },
  { area: 3, district: 'Central Bataan', church: 'Balanga' },
  { area: 3, district: 'Central Bataan', church: 'Liyang' },
  { area: 3, district: 'Central Bataan', church: 'Morong' },
  { area: 3, district: 'Central Bataan', church: 'Abucay' },
  { area: 3, district: 'Central Bataan', church: 'Tonato' },
  { area: 3, district: 'Central Bataan', church: 'Banawang' },

  { area: 3, district: 'North Bataan', church: 'Dinalupihan' },
  { area: 3, district: 'North Bataan', church: 'Talimundoc' },
  { area: 3, district: 'North Bataan', church: 'Pita' },
  { area: 3, district: 'North Bataan', church: 'Saguing' },
  { area: 3, district: 'North Bataan', church: 'Tipo' },
  { area: 3, district: 'North Bataan', church: 'Imelda' },
  { area: 3, district: 'North Bataan', church: 'Hermosa Ch Planting' },

  { area: 3, district: 'South Bataan', church: 'South Bataan' },
  { area: 3, district: 'South Bataan', church: 'Orion' },
  { area: 3, district: 'South Bataan', church: 'Limay' },
  { area: 3, district: 'South Bataan', church: 'Cabcaben' },
  { area: 3, district: 'South Bataan', church: 'Mariveles' },
  { area: 3, district: 'South Bataan', church: 'Biaan' },
  { area: 3, district: 'South Bataan', church: 'Bilolo Highland' },
  { area: 3, district: 'South Bataan', church: 'Mabato' },
  { area: 3, district: 'South Bataan', church: 'GEN. LIM' },
  { area: 3, district: 'South Bataan', church: 'SAAY' },
  { area: 3, district: 'South Bataan', church: 'Bayanbayanan' },

  { area: 3, district: 'South Zambales', church: 'Tabacuhan' },
  { area: 3, district: 'South Zambales', church: 'Ilwas' },
  { area: 3, district: 'South Zambales', church: 'San Isidro' },
  { area: 3, district: 'South Zambales', church: 'Nagbunga' },
  { area: 3, district: 'South Zambales', church: 'Gordon Heights' },

  { area: 3, district: 'North Zambales 1', church: 'Botolan' },
  { area: 3, district: 'North Zambales 1', church: 'Iba' },
  { area: 3, district: 'North Zambales 1', church: 'Palauig' },
  { area: 3, district: 'North Zambales 1', church: 'Bulawen' },
  { area: 3, district: 'North Zambales 1', church: 'Kabatuan' },
  { area: 3, district: 'North Zambales 1', church: 'Baquilan' },
  { area: 3, district: 'North Zambales 1', church: 'Belbel' },
  { area: 3, district: 'North Zambales 1', church: 'Palis' },
  { area: 3, district: 'North Zambales 1', church: 'Moraza' },

  { area: 3, district: 'North Zambales 2', church: 'Manggahan' },
  { area: 3, district: 'North Zambales 2', church: 'Sta. Cruz' },
  { area: 3, district: 'North Zambales 2', church: 'Uacon' },
  { area: 3, district: 'North Zambales 2', church: 'Masinloc' },

  { area: 3, district: 'Central Zambales', church: 'CENTRAL ZAMBALES' },
  { area: 3, district: 'Central Zambales', church: 'Cabangan' },
  { area: 3, district: 'Central Zambales', church: 'Casantacruzan' },
  { area: 3, district: 'Central Zambales', church: 'San Antonio' },
  { area: 3, district: 'Central Zambales', church: 'San Marcelino' },
  { area: 3, district: 'Central Zambales', church: 'San Narciso' },
  { area: 3, district: 'Central Zambales', church: 'Sta. Fe' },
  { area: 3, district: 'Central Zambales', church: 'Kamangahan' },
  { area: 3, district: 'Central Zambales', church: 'Kakilingan' },
  { area: 3, district: 'Central Zambales', church: 'Omaya Ch. Planting' },
  { area: 3, district: 'Central Zambales', church: 'LALEC' },
  { area: 3, district: 'Central Zambales', church: 'Gabor' },
  { area: 3, district: 'Central Zambales', church: 'San Esteban' },
  { area: 3, district: 'Central Zambales', church: 'Baliwet' },

  { area: 3, district: 'Olongapo', church: 'Olongapo' },
  { area: 3, district: 'Olongapo', church: 'New Cabalan ' },

  { area: 4, district: 'Central Pampanga 1', church: 'Apalit' },
  { area: 4, district: 'Central Pampanga 1', church: 'Mexico' },
  { area: 4, district: 'Central Pampanga 1', church: 'San Fernando' },
  { area: 4, district: 'Central Pampanga 1', church: 'San Pedro' },
  { area: 4, district: 'Central Pampanga 1', church: 'Northville' },
  { area: 4, district: 'Central Pampanga 1', church: 'Cansinala' },
  { area: 4, district: 'Central Pampanga 1', church: 'Macabebe' },
  { area: 4, district: 'Central Pampanga 1', church: 'Masantol' },
  { area: 4, district: 'Central Pampanga 1', church: 'Minalin' },
  { area: 4, district: 'Central Pampanga 1', church: 'Maniwalo, Bacolor' },
  { area: 4, district: 'Central Pampanga 1', church: 'Sta Rita' },
  { area: 4, district: 'Central Pampanga 1', church: 'Bala' },

  { area: 4, district: 'Central Pampanga 2', church: 'Ebus' },
  { area: 4, district: 'Central Pampanga 2', church: 'Natividad' },
  { area: 4, district: 'Central Pampanga 2', church: 'Guagua' },
  { area: 4, district: 'Central Pampanga 2', church: 'Lubao' },
  { area: 4, district: 'Central Pampanga 2', church: 'Dimson' },
  { area: 4, district: 'Central Pampanga 2', church: 'Sta Rita ' },
  { area: 4, district: 'Central Pampanga 2', church: 'Sasmuan' },
  { area: 4, district: 'Central Pampanga 2', church: 'Sto Domingo' },

  { area: 4, district: 'Florida District', church: 'San Nicholas' },
  { area: 4, district: 'Florida District', church: 'Palmayo' },
  { area: 4, district: 'Florida District', church: 'Bucaran' },
  { area: 4, district: 'Florida District', church: 'Shining Jewels ' },
  { area: 4, district: 'Florida District', church: "San Ramon Aeta's" },
  { area: 4, district: 'Florida District', church: "Sampaloc Aeta's" },
  { area: 4, district: 'Florida District', church: "Nabuklod Aeta's" },
  { area: 4, district: 'Florida District', church: "MalipanoAeta's" },
  { area: 4, district: 'Florida District', church: "Mawakat Aeta's" },
  { area: 4, district: 'Florida District', church: 'Charitas' },
  { area: 4, district: 'Florida District', church: 'Consuelo' },
  { area: 4, district: 'Florida District', church: 'Dampi' },

  { area: 4, district: 'CLAA', church: 'CLAA' },

  { area: 4, district: 'Angels Worship Center', church: 'Angeles SDA' },
  { area: 4, district: 'Angels Worship Center', church: 'Haduan' },
  { area: 4, district: 'Angels Worship Center', church: 'San Vicente' },
  { area: 4, district: 'Angels Worship Center', church: 'Anunas' },

  { area: 4, district: 'North Pampanga 1', church: 'Manibaug' },
  { area: 4, district: 'North Pampanga 1', church: 'Pandacaqui' },
  { area: 4, district: 'North Pampanga 1', church: 'Pulong Santol' },
  { area: 4, district: 'North Pampanga 1', church: 'Sapang Maisak' },
  { area: 4, district: 'North Pampanga 1', church: 'Sta. Cruz' },
  { area: 4, district: 'North Pampanga 1', church: 'Sapalibutad' },
  { area: 4, district: 'North Pampanga 1', church: 'Pasbol/Minority' },
  { area: 4, district: 'North Pampanga 1', church: 'Villa Maria/Minority' },
  { area: 4, district: 'North Pampanga 1', church: 'Palata' },

  { area: 4, district: 'North Pampanga 2', church: 'Dau' },
  { area: 4, district: 'North Pampanga 2', church: 'Balitucan' },
  { area: 4, district: 'North Pampanga 2', church: 'Mabalacat' },
  { area: 4, district: 'North Pampanga 2', church: 'Magalang' },
  { area: 4, district: 'North Pampanga 2', church: 'Mawaque' },
  { area: 4, district: 'North Pampanga 2', church: 'Sta. Lucia' },
  { area: 4, district: 'North Pampanga 2', church: 'Atlobola (NorthVille)' },
  { area: 4, district: 'North Pampanga 2', church: 'Madapdap' },
  { area: 4, district: 'North Pampanga 2', church: 'Sto. Nino' },
  { area: 4, district: 'North Pampanga 2', church: 'Tabun' },
  { area: 4, district: 'North Pampanga 2', church: 'Camatchile' },
  { area: 4, district: 'North Pampanga 2', church: 'Mabiga' },

  { area: 4, district: 'Balibago', church: 'Balibago' },

  { area: 4, district: 'Arayat District', church: 'Arayat' },
  { area: 4, district: 'Arayat District', church: 'Gatiawin' },
  { area: 4, district: 'Arayat District', church: 'San Nicolas' },
  { area: 4, district: 'Arayat District', church: 'Camba Ch. Planting' },
  { area: 4, district: 'Arayat District', church: 'Palinlang ' },
  { area: 4, district: 'Arayat District', church: 'Santa Ana' },
  { area: 4, district: 'Arayat District', church: 'Baliti' },
  { area: 4, district: 'Arayat District', church: 'Mapalad' },
  { area: 4, district: 'Arayat District', church: 'Lacyos' },

  { area: 4, district: 'South Pampanga ', church: 'Brookside' },
  { area: 4, district: 'South Pampanga ', church: 'Candaba' },
  { area: 4, district: 'South Pampanga ', church: 'San Carlos' },
  { area: 4, district: 'South Pampanga ', church: 'San Juan' },
  { area: 4, district: 'South Pampanga ', church: 'Sta. Catalina' },
  { area: 4, district: 'South Pampanga ', church: 'Bahay Pare' },

  { area: 4, district: 'Central Tarlac', church: 'Central Tarlac' },
  { area: 4, district: 'Central Tarlac', church: 'San Miguel' },
  { area: 4, district: 'Central Tarlac', church: 'Dela Paz' },
  { area: 4, district: 'Central Tarlac', church: 'Villa Aglipay' },
  { area: 4, district: 'Central Tarlac', church: 'San Juan de Valdez' },
  { area: 4, district: 'Central Tarlac', church: 'Pao' },
  { area: 4, district: 'Central Tarlac', church: 'Balete' },
  {
    area: 4,
    district: 'Central Tarlac',
    church: 'San Jose de Urquico Ch. Planting'
  },
  { area: 4, district: 'Central Tarlac', church: 'Sapang Maragul' },
  { area: 4, district: 'Central Tarlac', church: 'Maliwalo' },
  { area: 4, district: 'Central Tarlac', church: 'Tibag' },

  { area: 4, district: 'North Tarlac', church: 'Buenlag' },
  { area: 4, district: 'North Tarlac', church: 'Amacalan' },
  { area: 4, district: 'North Tarlac', church: 'Victoria' },
  { area: 4, district: 'North Tarlac', church: 'Estipona' },
  { area: 4, district: 'North Tarlac', church: 'Matayumtayum' },
  { area: 4, district: 'North Tarlac', church: 'Lapaz' },
  { area: 4, district: 'North Tarlac', church: 'Dalayap' },
  { area: 4, district: 'North Tarlac', church: 'Cardona' },
  { area: 4, district: 'North Tarlac', church: 'Pura' },

  { area: 4, district: 'South Tarlac', church: 'Bamban' },
  { area: 4, district: 'South Tarlac', church: 'Capas' },
  { area: 4, district: 'South Tarlac', church: 'Dutung-A-Matas' },
  { area: 4, district: 'South Tarlac', church: 'Lawy' },
  { area: 4, district: 'South Tarlac', church: 'Navy' },
  { area: 4, district: 'South Tarlac', church: 'Sta. Lucia' },
  { area: 4, district: 'South Tarlac', church: 'Sto. Domingo' },
  { area: 4, district: 'South Tarlac', church: 'Café' },
  { area: 4, district: 'South Tarlac', church: 'Acacia' },
  { area: 4, district: 'South Tarlac', church: 'Dapdap' },
  { area: 4, district: 'South Tarlac', church: 'Calangitan' },
  { area: 4, district: 'South Tarlac', church: 'Castillo' },
  { area: 4, district: 'South Tarlac', church: 'Culatingan' },

  { area: 5, district: 'Central Bulacan', church: 'Meysulao' },
  { area: 5, district: 'Central Bulacan', church: 'Tabang' },
  { area: 5, district: 'Central Bulacan', church: 'Tikay (Riverside)' },
  { area: 5, district: 'Central Bulacan', church: 'Bangkal' },
  { area: 5, district: 'Central Bulacan', church: 'Hagonoy' },
  { area: 5, district: 'Central Bulacan', church: 'Calumpit' },

  { area: 5, district: 'Malolos', church: 'Malolos' },
  { area: 5, district: 'Malolos', church: 'Paombong' },

  { area: 5, district: 'North Bulacan 1', church: 'Baliuag' },
  { area: 5, district: 'North Bulacan 1', church: 'Bustos' },
  { area: 5, district: 'North Bulacan 1', church: 'Inaon' },
  { area: 5, district: 'North Bulacan 1', church: 'Talacsan' },
  { area: 5, district: 'North Bulacan 1', church: 'Tiaong' },
  { area: 5, district: 'North Bulacan 1', church: 'Bintog' },
  { area: 5, district: 'North Bulacan 1', church: 'Plaridel ' },
  { area: 5, district: 'North Bulacan 1', church: 'Catakte' },

  { area: 5, district: 'North Bulacan 2', church: 'Akle' },
  { area: 5, district: 'North Bulacan 2', church: 'Upig' },
  { area: 5, district: 'North Bulacan 2', church: 'Sapang Dayap' },
  { area: 5, district: 'North Bulacan 2', church: 'Tulay na Patpat' },
  { area: 5, district: 'North Bulacan 2', church: 'San Ildefonso' },
  { area: 5, district: 'North Bulacan 2', church: 'Bulusukan' },
  { area: 5, district: 'North Bulacan 2', church: 'Diliman' },

  { area: 5, district: 'North Bulacan 3', church: 'San Miguel' },
  { area: 5, district: 'North Bulacan 3', church: 'Pinambaran' },
  { area: 5, district: 'North Bulacan 3', church: 'Camp Tecson' },
  { area: 5, district: 'North Bulacan 3', church: 'DC Nicholas ' },
  { area: 5, district: 'North Bulacan 3', church: 'Sacdalan Group' },

  { area: 5, district: 'South Bulacan 1', church: 'Bocaue' },
  { area: 5, district: 'South Bulacan 1', church: 'Bagumbayan' },
  { area: 5, district: 'South Bulacan 1', church: 'Marilao' },
  { area: 5, district: 'South Bulacan 1', church: 'Triple Junction' },
  { area: 5, district: 'South Bulacan 1', church: 'Hanging Bridge' },
  { area: 5, district: 'South Bulacan 1', church: 'Obando' },
  {
    area: 5,
    district: 'South Bulacan 1',
    church: 'Bulakan, Bulacan Ch. Planting'
  },
  { area: 5, district: 'South Bulacan 1', church: 'Bulakan-Bulawayo' },

  { area: 5, district: 'Meycauayan', church: 'Meycauayan' },
  { area: 5, district: 'Meycauayan', church: 'Pantoc' },

  { area: 5, district: 'East Bulacan 1', church: 'Buasao' },
  { area: 5, district: 'East Bulacan 1', church: 'Norzagaray' },
  { area: 5, district: 'East Bulacan 1', church: 'Pallorina' },
  { area: 5, district: 'East Bulacan 1', church: 'Bato' },
  { area: 5, district: 'East Bulacan 1', church: 'Bayabas' },
  { area: 5, district: 'East Bulacan 1', church: 'DRT' },
  { area: 5, district: 'East Bulacan 1', church: 'Angat' },
  { area: 5, district: 'East Bulacan 1', church: 'NHV' },
  { area: 5, district: 'East Bulacan 1', church: 'Larin' },
  { area: 5, district: 'East Bulacan 1', church: 'Pinag-anakan' },

  { area: 5, district: 'East Bulacan 2A', church: 'Sapang Palay' },
  { area: 5, district: 'East Bulacan 2A', church: 'San Jose Del Monte' },
  { area: 5, district: 'East Bulacan 2A', church: 'Area H' },
  { area: 5, district: 'East Bulacan 2A', church: 'Citrus Group' },
  { area: 5, district: 'East Bulacan 2A', church: 'Palmera Group' },
  { area: 5, district: 'East Bulacan 2A', church: 'Towerville Group' },

  { area: 5, district: 'East Bulacan 2B', church: 'Francisco Homes' },
  { area: 5, district: 'East Bulacan 2B', church: 'Paradise II' },
  { area: 5, district: 'East Bulacan 2B', church: 'Paradise III' },
  { area: 5, district: 'East Bulacan 2B', church: 'Katutubo (Dumagat)' },
  { area: 5, district: 'East Bulacan 2B', church: 'Del Monte (Ilas)' },
  { area: 5, district: 'East Bulacan 2B', church: 'Katutubo/Sitio Inuman' },
  { area: 5, district: 'East Bulacan 2B', church: 'Marangal' },
  { area: 5, district: 'East Bulacan 2B', church: 'Macabud' },

  { area: 5, district: 'East Bulacan 3', church: 'Patnubay' },
  { area: 5, district: 'East Bulacan 3', church: 'Linawan' },
  { area: 5, district: 'East Bulacan 3', church: 'Bulac' },
  { area: 5, district: 'East Bulacan 3', church: 'San Vicente' },
  { area: 5, district: 'East Bulacan 3', church: 'Sta. Maria (Guyong)' },
  { area: 5, district: 'East Bulacan 3', church: 'Pabahay Muzon' },
  { area: 5, district: 'East Bulacan 3', church: 'Pandi-Mapulang Lupa ' },
  { area: 5, district: 'East Bulacan 3', church: 'Balasing' },
  { area: 5, district: 'East Bulacan 3', church: 'Camangyanan' },
  { area: 5, district: 'East Bulacan 3', church: 'Kaypian' },
  { area: 5, district: 'East Bulacan 3', church: 'Las Palmas' },

  { area: 6, district: 'BAESA', church: 'Baesa' },
  { area: 6, district: 'BAESA', church: 'Single Pastorate Church' },
  { area: 6, district: 'BAESA', church: 'Rodriguez Phase II' },
  { area: 6, district: 'BAESA', church: 'GSIS Hills Extention Division' },

  { area: 6, district: 'BAA', church: 'BAA' },

  { area: 6, district: 'PPH', church: 'PPH' },
  { area: 6, district: 'PPH', church: 'Single Pastorate Church' },
  { area: 6, district: 'PPH', church: 'PPH Employers' },

  { area: 6, district: 'South Caloocan 2', church: 'Bayanihan' },
  { area: 6, district: 'South Caloocan 2', church: 'Imelda' },
  { area: 6, district: 'South Caloocan 2', church: 'Milagrosa' },
  { area: 6, district: 'South Caloocan 2', church: 'PUC Remnant' },
  { area: 6, district: 'South Caloocan 2', church: 'West Reparo' },
  { area: 6, district: 'South Caloocan 2', church: 'Bagong Barrio' },

  { area: 6, district: 'North Caloocan 2', church: 'Battle Creek (Ph 5)' },
  { area: 6, district: 'North Caloocan 2', church: 'Bagong Silang (Ph 2)' },
  { area: 6, district: 'North Caloocan 2', church: 'Hillsview' },
  { area: 6, district: 'North Caloocan 2', church: 'Tala' },

  { area: 6, district: 'North Caloocan 1', church: 'Mt. Moriah' },
  { area: 6, district: 'North Caloocan 1', church: 'Mt.Sinai' },
  { area: 6, district: 'North Caloocan 1', church: 'Camarin' },
  { area: 6, district: 'North Caloocan 1', church: 'Bagumbong' },
  { area: 6, district: 'North Caloocan 1', church: 'BF Homes' },
  { area: 6, district: 'North Caloocan 1', church: 'Congress Adv. Center' },
  { area: 6, district: 'North Caloocan 1', church: 'Northville' },
  {
    area: 6,
    district: 'North Caloocan 1',
    church: 'North Caloocan Urban Center'
  },
  { area: 6, district: 'North Caloocan 1', church: 'N.C Urban Center' },

  { area: 6, district: 'CALMA', church: 'Caloocan Center' },
  { area: 6, district: 'CALMA', church: 'Kaunlaran Center' },
  { area: 6, district: 'CALMA', church: 'Bgy. 35, Maypajo Kaunlaran' },
  { area: 6, district: 'CALMA', church: 'Tamban Group, Kaunlaran' },

  { area: 6, district: 'Mal-Nav District', church: 'Navotas' },
  { area: 6, district: 'Mal-Nav District', church: 'Panghulo' },
  { area: 6, district: 'Mal-Nav District', church: 'Malabon' },
  { area: 6, district: 'Mal-Nav District', church: 'University Hills' },
  { area: 6, district: 'Mal-Nav District', church: 'Tanza Navotas' },
  { area: 6, district: 'Mal-Nav District', church: 'Merge to Malabon Church' },

  { area: 6, district: 'North Caloocan 3', church: 'North Caloocan 3 ' },
  { area: 6, district: 'North Caloocan 3', church: 'Pangarap' },
  { area: 6, district: 'North Caloocan 3', church: 'Maligaya Parkland' },
  { area: 6, district: 'North Caloocan 3', church: 'Green Valley' },
  { area: 6, district: 'North Caloocan 3', church: 'NCCC/Lilies Ville' },
  { area: 6, district: 'North Caloocan 3', church: 'Miramonte Patk' },

  { area: 6, district: 'Valenzuela District', church: 'Valenzuela District' },
  { area: 6, district: 'Valenzuela District', church: 'Polo' },
  { area: 6, district: 'Valenzuela District', church: 'Canumay' },
  { area: 6, district: 'Valenzuela District', church: 'Rockville ' },
  { area: 6, district: 'Valenzuela District', church: 'Isla, Valenzuela' },
  { area: 6, district: 'Valenzuela District', church: 'Bignay Valenzuela' },
  { area: 6, district: 'Valenzuela District', church: 'Malinta Valenzuela' },

  { area: 7, district: 'Manila Center', church: 'Manila Center' },
  { area: 7, district: 'Manila Center', church: 'Manresa' },
  { area: 7, district: 'Manila Center', church: 'Bagong Bantay Ch Planting' },

  { area: 7, district: 'QC1- Sampaloc District', church: 'Sampaloc' },
  { area: 7, district: 'QC1- Sampaloc District', church: 'Damayan Lagi' },
  { area: 7, district: 'QC1- Sampaloc District', church: 'Sta. Mesa Heights' },
  { area: 7, district: 'QC1- Sampaloc District', church: 'East Fairview ' },

  { area: 7, district: 'QC2- UP District', church: 'UPD AMICUS Youth Centre' },
  {
    area: 7,
    district: 'QC2- UP District',
    church: 'Camp General Emilio Aguinaldo'
  },
  { area: 7, district: 'QC2- UP District', church: 'Tree of Life Cubao' },

  { area: 7, district: 'QC3- Quezon Central District', church: 'Pag-asa' },
  {
    area: 7,
    district: 'QC3- Quezon Central District',
    church: 'Quezon Central (Diliman)'
  },
  { area: 7, district: 'QC3- Quezon Central District', church: 'Kaingin' },
  { area: 7, district: 'QC3- Quezon Central District', church: 'Taguite' },

  {
    area: 7,
    district: 'Project 4 (Quirino)',
    church: 'Single Pastorate Church'
  },

  { area: 7, district: 'Cubao Crame II', church: 'Shekinah Church' },

  { area: 7, district: 'Gao', church: 'Gao' },

  { area: 7, district: 'QC4 - Luzon District', church: 'Sherwood' },
  { area: 7, district: 'QC4 - Luzon District', church: 'Luzon' },
  { area: 7, district: 'QC4 - Luzon District', church: 'Tandang Sora (NPC)' },
  { area: 7, district: 'QC4 - Luzon District', church: 'Pingkian 3' },

  { area: 7, district: 'QC5 - Payatas District', church: 'NIV' },
  { area: 7, district: 'QC5 - Payatas District', church: 'Feria' },
  { area: 7, district: 'QC5 - Payatas District', church: 'Payatas A' },
  { area: 7, district: 'QC5 - Payatas District', church: 'Payatas B' },
  { area: 7, district: 'QC5 - Payatas District', church: 'Promised Land' },
  { area: 7, district: 'QC5 - Payatas District', church: 'Jordan Heights' },

  { area: 7, district: 'QC6 - Fairview District', church: 'Lagro' },
  { area: 7, district: 'QC6 - Fairview District', church: 'Sta. Lucia' },
  {
    area: 7,
    district: 'QC6 - Fairview District',
    church: 'East Fairview Ch Planting'
  },
  { area: 7, district: 'QC6 - Fairview District', church: 'North Fairview' },

  { area: 7, district: 'QC7- Frisco District', church: 'Frisco' },
  { area: 7, district: 'QC7- Frisco District', church: 'Project 8' },
  { area: 7, district: 'QC7- Frisco District', church: 'Sitio Militar' },
  { area: 7, district: 'QC7- Frisco District', church: 'GSIS Village ' },
  { area: 7, district: 'QC7- Frisco District', church: 'Sauyo' },
  { area: 7, district: 'QC7- Frisco District', church: 'Balara' },

  { area: 7, district: 'QC- 8 Novaliches District', church: 'Novaliches' },
  { area: 7, district: 'QC- 8 Novaliches District', church: 'Aguardiente' },
  { area: 7, district: 'QC- 8 Novaliches District', church: 'Sushila' },
  { area: 7, district: 'QC- 8 Novaliches District', church: 'SAUYO ' },

  { area: 8, district: 'Pasig', church: 'Pasig' },
  { area: 8, district: 'Pasig', church: 'Single Pastorate Church' },
  { area: 8, district: 'Pasig', church: 'Santolan Pasig City' },
  { area: 8, district: 'Pasig', church: 'Manggahan Church Planting' },

  { area: 8, district: 'Rizal 1', church: 'Central Cainta' },
  { area: 8, district: 'Rizal 1', church: 'East Cainta' },
  { area: 8, district: 'Rizal 1', church: 'Exodus Floodway' },
  { area: 8, district: 'Rizal 1', church: 'Nagpayong' },
  { area: 8, district: 'Rizal 1', church: 'West Cainta' },
  { area: 8, district: 'Rizal 1', church: 'Sakbit' },
  { area: 8, district: 'Rizal 1', church: 'Lakeview(Damayan)SDA Bible Center' },

  { area: 8, district: 'Lower Rizal 2', church: 'Champaca' },
  { area: 8, district: 'Lower Rizal 2', church: 'Silangan' },
  { area: 8, district: 'Lower Rizal 2', church: 'Provident' },
  { area: 8, district: 'Lower Rizal 2', church: 'San Mateo' },
  { area: 8, district: 'Lower Rizal 2', church: 'Tumana' },
  { area: 8, district: 'Lower Rizal 2', church: 'Cupang' },

  { area: 8, district: 'Upper Rizal 2', church: 'Montalban Central' },
  { area: 8, district: 'Upper Rizal 2', church: 'Montalban Heights' },
  { area: 8, district: 'Upper Rizal 2', church: 'Kasiglahan' },
  { area: 8, district: 'Upper Rizal 2', church: 'Lukutan' },
  { area: 8, district: 'Upper Rizal 2', church: 'Mascap' },
  { area: 8, district: 'Upper Rizal 2', church: 'Hillside' },
  { area: 8, district: 'Upper Rizal 2', church: 'Maislap' },
  { area: 8, district: 'Upper Rizal 2', church: 'Puray (Bagong Sigla)' },
  { area: 8, district: 'Upper Rizal 2', church: 'Southville 8b SDA' },
  { area: 8, district: 'Upper Rizal 2', church: 'IK2' },
  { area: 8, district: 'Upper Rizal 2', church: 'Easwind' },
  { area: 8, district: 'Upper Rizal 2', church: 'ABATEXT' },

  { area: 8, district: 'Marikina', church: 'Marikina' },

  { area: 8, district: 'Rizal 3', church: 'Mambog' },
  { area: 8, district: 'Rizal 3', church: 'Morong' },
  { area: 8, district: 'Rizal 3', church: 'Angono' },
  { area: 8, district: 'Rizal 3', church: 'Montevilla Bible Center' },
  { area: 8, district: 'Rizal 3', church: 'Cardona Ch Planting' },
  { area: 8, district: 'Rizal 3', church: 'Mahabang Parang' },

  { area: 8, district: 'Rizal 4', church: 'Sampaloc' },
  { area: 8, district: 'Rizal 4', church: 'Jala-Jala' },
  { area: 8, district: 'Rizal 4', church: 'Daraitan' },
  { area: 8, district: 'Rizal 4', church: 'Quisao' },
  { area: 8, district: 'Rizal 4', church: 'Tanay' },
  { area: 8, district: 'Rizal 4', church: 'Pinagsabiran' },
  { area: 8, district: 'Rizal 4', church: 'Sitio Batangas' },
  { area: 8, district: 'Rizal 4', church: 'Cayabu ' },
  { area: 8, district: 'Rizal 4', church: 'Dayapa ' },
  { area: 8, district: 'Rizal 4', church: 'Nayon' },
  { area: 8, district: 'Rizal 4', church: 'Bathala' },
  { area: 8, district: 'Rizal 4', church: 'Manggahan (Malibay)' },

  { area: 8, district: 'Rizal 5', church: 'Abuyod' },
  { area: 8, district: 'Rizal 5', church: 'Paenaan' },
  { area: 8, district: 'Rizal 5', church: 'Valley SDA Church' },
  { area: 8, district: 'Rizal 5', church: 'Villa Guido' },
  { area: 8, district: 'Rizal 5', church: 'Sitio Maligaya' },
  { area: 8, district: 'Rizal 5', church: 'Sitio Mata' },

  { area: 8, district: 'Antipolo City', church: 'Antipolo City' },
  { area: 8, district: 'Antipolo City', church: 'Antipolo Hills' },
  { area: 8, district: 'Antipolo City', church: 'El Dorado' },

  { area: 8, district: 'Rizal 6', church: 'Antipolo Central' },
  { area: 8, district: 'Rizal 6', church: 'Calawis' },
  { area: 8, district: 'Rizal 6', church: 'Cogeo-UMG' },
  { area: 8, district: 'Rizal 6', church: 'Pagrai' },
  { area: 8, district: 'Rizal 6', church: 'Puting Bato' },
  { area: 8, district: 'Rizal 6', church: 'Antipolo East' },
  { area: 8, district: 'Rizal 6', church: 'Unit 7' },
  { area: 8, district: 'Rizal 6', church: 'Sapinit' },
  { area: 8, district: 'Rizal 6', church: 'Inarawan' },
  { area: 8, district: 'Rizal 6', church: 'Bimayoyo' },

  { area: 9, district: 'PAC', church: 'Pasay Adventist Church' },

  { area: 9, district: 'Project Grow District', church: 'Grow Community-BGC' },
  { area: 9, district: 'Project Grow District', church: 'Project Grow Makati' },
  {
    area: 9,
    district: 'Project Grow District',
    church: 'Project Grow Eastwood'
  },
  {
    area: 9,
    district: 'Project Grow District',
    church: 'Project Grow Alabang'
  },

  { area: 9, district: 'PCAC', church: 'PCA Church' },

  { area: 9, district: 'MAMC', church: 'MAMC' },
  { area: 9, district: 'MAMC', church: 'Adventist Bible Center Church' },

  { area: 9, district: 'MAC', church: 'MAC' },
  { area: 9, district: 'MAC', church: 'CIA-Church in Ayala' },
  { area: 9, district: 'MAC', church: 'Manila Adventist College Church' },

  { area: 9, district: 'Pasay Central', church: 'Pasay Central Church' },
  { area: 9, district: 'Pasay Central', church: 'Visayan Adventist Church' },

  {
    area: 9,
    district: 'Pasay District',
    church: 'Villamor Ecumenical Church '
  },
  { area: 9, district: 'Pasay District', church: 'NAIA' },
  { area: 9, district: 'Pasay District', church: 'Malibay' },
  { area: 9, district: 'Pasay District', church: 'Kalayaan' },

  { area: 9, district: 'Makati District', church: 'Fort Andres Bonifacio' },
  { area: 9, district: 'Makati District', church: 'Comembo' },
  { area: 9, district: 'Makati District', church: 'Cembo' },

  { area: 9, district: 'Central Metro Manila 1', church: 'Pandacan' },
  { area: 9, district: 'Central Metro Manila 1', church: 'Singalong' },
  { area: 9, district: 'Central Metro Manila 1', church: 'Sta. Ana' },
  { area: 9, district: 'Central Metro Manila 1', church: 'Punta' },

  { area: 9, district: 'Central Metro Manila 2', church: 'Mandaluyong' },
  { area: 9, district: 'Central Metro Manila 2', church: 'Arcillas' },
  {
    area: 9,
    district: 'Central Metro Manila 2',
    church: 'San Juan Center Church'
  },
  {
    area: 9,
    district: 'Central Metro Manila 2',
    church: 'Correctional Institute Women'
  },
  { area: 9, district: 'Central Metro Manila 2', church: 'Ebenezer' },
  {
    area: 9,
    district: 'Central Metro Manila 2',
    church: 'Win Wellness Center Mandaluyong'
  },

  {
    area: 9,
    district: 'Sta Mesa Adventist Church',
    church: 'UBelt Manila Church'
  },

  { area: 9, district: 'Manila District 1', church: 'Manila Central' },
  {
    area: 9,
    district: 'Manila District 1',
    church: 'Juan Luna SDA Bible Center'
  },
  { area: 9, district: 'Manila District 1', church: 'Dapitan' },
  { area: 9, district: 'Manila District 1', church: 'Binondo ' },

  { area: 9, district: 'Manila District 2', church: 'Gagalangin' },
  { area: 9, district: 'Manila District 2', church: 'Magsaysay' },
  { area: 9, district: 'Manila District 2', church: 'Masinop ' },
  { area: 9, district: 'Manila District 2', church: 'Baseco' },
  { area: 9, district: 'Manila District 2', church: 'Bliss ' },
  {
    area: 9,
    district: 'Manila District 2',
    church: 'Manila Korean Ch. (Makati)'
  },

  { area: 9, district: 'Ortigas', church: 'House of Prayer' },
  { area: 9, district: 'Ortigas', church: 'Deaf Ministry' },

  { area: 10, district: 'Muntinlupa District', church: 'Muntinlupa Central' },
  { area: 10, district: 'Muntinlupa District', church: 'Victoria' },
  { area: 10, district: 'Muntinlupa District', church: 'Sucat' },
  { area: 10, district: 'Muntinlupa District', church: 'Parkhomes SDA Church' },
  { area: 10, district: 'Muntinlupa District', church: 'Bagong Silang Com.' },
  { area: 10, district: 'Muntinlupa District', church: 'Sampaguita SDA Ch.' },
  { area: 10, district: 'Muntinlupa District', church: 'Depot SDA Church' },
  { area: 10, district: 'Muntinlupa District', church: 'Carcel' },
  { area: 10, district: 'Muntinlupa District', church: 'Presidio' },

  {
    area: 10,
    district: 'Las Piñas District ',
    church: 'Las Piñas (Pillar Vill)'
  },
  { area: 10, district: 'Las Piñas District ', church: 'TS Cruz' },
  { area: 10, district: 'Las Piñas District ', church: 'Timog' },
  { area: 10, district: 'Las Piñas District ', church: 'Tramo' },
  { area: 10, district: 'Las Piñas District ', church: 'Doña Josefa' },
  {
    area: 10,
    district: 'Las Piñas District ',
    church: 'Golden Acres Congregation'
  },

  { area: 10, district: 'Bethel (Pamplona)', church: 'Bethel (Pamplona)' },
  { area: 10, district: 'Bethel (Pamplona)', church: 'Lopez' },

  { area: 10, district: 'CAA', church: 'CAA' },

  {
    area: 10,
    district: 'South Taguig District',
    church: 'South Taguig District'
  },
  { area: 10, district: 'South Taguig District', church: 'Signal Rooftop' },
  { area: 10, district: 'South Taguig District', church: 'Upper Bicutan' },
  { area: 10, district: 'South Taguig District', church: 'Western Bicutan' },
  { area: 10, district: 'South Taguig District', church: 'Tanyag Taguig' },
  { area: 10, district: 'South Taguig District', church: 'Creekland' },
  { area: 10, district: 'South Taguig District', church: 'Bagumbayan ' },
  {
    area: 10,
    district: 'South Taguig District',
    church: 'New Lower Bicutan Group'
  },

  {
    area: 10,
    district: 'North Taguig District',
    church: 'North Taguig District'
  },
  { area: 10, district: 'North Taguig District', church: 'AFP-O-VAI' },
  { area: 10, district: 'North Taguig District', church: 'Centennial' },
  { area: 10, district: 'North Taguig District', church: 'C-5 SDA Church' },
  { area: 10, district: 'North Taguig District', church: 'Signal Village' },
  { area: 10, district: 'North Taguig District', church: 'Tipas' },
  { area: 10, district: 'North Taguig District', church: 'Sun Valley' },
  { area: 10, district: 'North Taguig District', church: 'Pateros' },
  {
    area: 10,
    district: 'North Taguig District',
    church: 'Life Hope Center-Sun Valley'
  },
  { area: 10, district: 'North Taguig District', church: 'PAE, Bicutan' },

  { area: 10, district: 'Parañaque District', church: 'Baclaran SDA Ch' },
  { area: 10, district: 'Parañaque District', church: 'Parañaque Center Ch' },
  { area: 10, district: 'Parañaque District', church: 'Coastal ' },
  { area: 10, district: 'Parañaque District', church: 'Moonwalk' }
];
