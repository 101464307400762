import React, { useState, useEffect, useContext } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';
import { Grid } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';
import { formatDate } from '../../common/formatter';
import { PayMayaTransaction } from './paymaya.interface';
import MarkAsPaid from '../../common/MarkAsPaid';
import MarkAsUnpaid from '../../common/MarkAsUnpaid';
import { numberWithCommas } from '../../common/Util';
import TransactionStatusPill from '../../common/TransactionStatusPill';
import { UserContext } from '../../user/User.context';
import { UserRole } from '../users/user.interface';

const StyledText = styled(Typography)`
  font-size: 15px;
`;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface PayMayaDetailDialogProps {
  transaction: PayMayaTransaction;
  open: boolean;
  onPaid: () => void;
  onCancel: () => void;
}

const PayMayaDetailDialog: React.FC<PayMayaDetailDialogProps> = ({
  transaction,
  open,
  onPaid,
  onCancel
}) => {
  const { user } = useContext(UserContext);
  const [paymentId, setPaymentId] = useState<any>(transaction.paymentId);
  const [transactionDate, setTransactionDate] = useState<any>(transaction.transactionDate);
  const [isPaid, setIsPaid] = useState<any>(false);

  const handleClose = () => {
    onCancel();
  };

  useEffect(() => {
    if (isPaid) {
      onPaid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaid]);

  const Logo = () => {
    return (
      <Grid item md={12} xs={12}>
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <img
            alt=''
            src={require('../../assets/paymaya_logo.png')}
            style={{ height: 40 }}
          />
        </div>
      </Grid>
    );
  };

  const ActionButton = () => {
    return (
      <DialogActions
        style={{ textAlign: 'center', justifyContent: 'center', marginTop: 10 }}
      >
        {paymentId ? (
          <MarkAsUnpaid
            transactionId={transaction.id}
            onSuccess={(data: any) => {
              setPaymentId(data.paymentId);
              setIsPaid(true);
              setTransactionDate(data.updatedAt);
            }}
          />
        ) : (
          <MarkAsPaid
            title={'Enter PayMaya Reference No.'}
            transactionId={transaction.id}
            onSuccess={(data: any) => {
              setPaymentId(data.paymentId);
              setIsPaid(true);
              setTransactionDate(data.updatedAt);
            }}
          />
        )}
      </DialogActions>
    );
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {transaction.referenceId}{' '}
          <TransactionStatusPill paymentId={paymentId} />
        </DialogTitle>
        <DialogContent dividers>
          <Logo />
          <StyledText gutterBottom>
            <b>Last Name: </b>
            {transaction.lastName}
          </StyledText>
          <StyledText gutterBottom>
            <b>First Name: </b>
            {transaction.firstName}
          </StyledText>
          <StyledText gutterBottom>
            <b>Email: </b>
            {transaction.email}
          </StyledText>
          <StyledText gutterBottom>
            <b>Contact Number: </b>
            {transaction.contactNumber ? transaction.contactNumber : ''}
          </StyledText>
          <StyledText gutterBottom>
            <b>Church: </b>
            {transaction.churchName ? transaction.churchName : ''}
          </StyledText>
          <StyledText gutterBottom>
            <b>Area: </b>
            {transaction.area ? transaction.area : ''}
          </StyledText>
          <StyledText gutterBottom>
            <b>Date: </b>
            {formatDate(transactionDate, true)}
          </StyledText>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Allocation</TableCell>
                  <TableCell align='right'>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Tithe:
                  </TableCell>
                  <TableCell align='right'>
                    ₱ {numberWithCommas(transaction.tithe)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    Offering:
                  </TableCell>
                  <TableCell align='right'>
                    ₱ {numberWithCommas(transaction.offering)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    {'Other'}:{' '}
                    {transaction.otherDescription &&
                      '(' + transaction.otherDescription + ')'}
                  </TableCell>
                  <TableCell align='right'>
                    ₱ {numberWithCommas(transaction.otherAmount)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row'>
                    <b>Total Amount:</b>
                  </TableCell>
                  <TableCell align='right'>
                    <b>₱ {numberWithCommas(transaction.totalAmount)}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {paymentId && (
            <StyledText gutterBottom style={{ marginTop: 20 }}>
              <b>PayMaya Reference No.: </b>
              {paymentId}
            </StyledText>
          )}
          {user?.role === UserRole.controller && <ActionButton />}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default PayMayaDetailDialog;
