import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface ConfirmAlertProps {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}
const ConfirmAlert: React.FC<ConfirmAlertProps> = ({
  open,
  onCancel,
  onSubmit
}) => {
  const handleClose = () => {
    onCancel();
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
        <DialogTitle>{'Proceed to payment?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Submit giving form and proceed to payment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color='primary' autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmAlert;
