import React from 'react';

import { Card, Grid } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import GroupIcon from '@material-ui/icons/Group';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import CardContent from '../../common/card/CardContent';
import CardTitle from '../../common/card/CardTitle';
import PageTemplate from '../../common/page-template/PageTemplate';
import PageTemplateDashboard from '../../common/page-template/PageTemplateDashboard';
import PageTemplateHeader from '../../common/page-template/PageTemplateHeader';

const AdminDashboardPage: React.FC = () => {
  return (
    <PageTemplate>
      <PageTemplateHeader>
        <h2>Dashboard</h2>
      </PageTemplateHeader>
      <PageTemplateDashboard>
        <div style={{ height: '100%' }}></div>
        {/*<Grid container spacing={3}>
           <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                   
                    <Card>
                      <CardTitle>
                        <GroupIcon />
                        Users
                      </CardTitle>
                      <CardContent></CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                   
                    <Card>
                      <CardTitle>
                        <ErrorIcon />
                        Notifications
                      </CardTitle>
                      <CardContent></CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
               
                <Card>
                  <CardTitle>
                    <InsertDriveFileIcon />
                    Transactions
                  </CardTitle>
                  <CardContent>
                    <div style={{ height: 400 }}></div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}></Grid> 
        </Grid>*/}
      </PageTemplateDashboard>
    </PageTemplate>
  );
};

export default AdminDashboardPage;
