import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Footer from '../home/components/Footer';

const StyledHeader = styled.div`
  width: 100%;
  background-color: #31a27c;
  padding-top: 0.5rem;
  color: #FFF;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  .header-container {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-self: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  img {
    width: 150px;
  }
  .right {
    color: #fff;
    font-weight: 500;
  }
`;

const ThankYouPage = () => {
  const [state, setState] = useState<any>({
    txnid: null,
    refno: null,
    status: null,
    message: null,
  });

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const params = new URLSearchParams(location.search);
    const txnid: string | null = params.get('txnid');
    const refno: string | null = params.get('refno');
    const status: string | null = params.get('status');
    const message: string | null = params.get('message');
    setState({ txnid, refno, message, status });
    // eslint-disable-next-line no-restricted-globals
  }, [location.search]);

  const getStatus = (status: any) => {
    switch (status) {
      case "S":
        return "Success"
      case "P":
        return "Pending"
      case "F":
        return "Failed"
      default:
        return status;
    }
  }

  return (
    <React.Fragment>
      <StyledHeader>
        <div className='header-container'>
          <Grid container spacing={3}>
            {state?.status ? (
              <>
                <Grid item xs={12}>
                  <p style={{ fontSize: '2.7em' }}>Status: <b>{getStatus(state?.status)}</b></p>
                  <p style={{ fontSize: '1.6em' }}><b>Message:</b> {state?.message}</p>
                  {/* <p style={{ fontSize: '2.0em' }}>Transaction ID: {state?.txnid}</p> */}
                  <p style={{ fontSize: '2.0em' }}>Reference Number: <b>{state?.refno}</b></p>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20, marginBottom: 40 }}>
                  <div>
                    {state?.status === 'Pending' ? (
                      <p style={{ fontSize: '1.8em', lineHeight: '1.5em' }}>
                        Please check your email/mobile for instructions.
                      </p>
                    ) : (
                        <h1 style={{ fontSize: '1.6em', lineHeight: '1.5em' }}>
                          Thank you for honoring God with your tithes and offerings!
                        </h1>
                      )}
                    {state?.status === 'Success' && (
                      <p style={{ fontSize: '1.5em', lineHeight: '1.5em' }}>
                        We’ll send the receipt to you the soonest possible time.
                        Meanwhile, if you have any inquiries, you may reach us through
                        the email address giving@adventist.ph.
                      </p>
                    )}
                  </div>
                </Grid>
              </>
            ) : (
                <Grid item xs={12}>
                  <p style={{ fontSize: '2.7em' }}><b>Thank You!</b></p>
                  <p style={{ fontSize: '1.5em', lineHeight: '1.5em' }}>
                    If you have any inquiries, you may reach us through
                    the email address giving@adventist.ph.
                  </p>
                </Grid>
              )}
            {/* <Grid item xs={12} style={{ marginTop: 50, marginBottom: 50 }}>
              <div>
                <Link
                  to='/'
                  className='btn btn-primary'
                  style={{
                    backgroundColor: '#fb8c04',
                    color: 'white',
                    padding: 15,
                    width: 150,
                    height: 100,
                    textDecoration: 'none'
                  }}
                >
                  Go Back to Home Page
                </Link>
              </div>
            </Grid> */}
          </Grid>
        </div>
      </StyledHeader>
      {/* <Footer /> */}
      {/* <PaymongoFooter /> */}
    </React.Fragment>
  );
};

export default ThankYouPage;
