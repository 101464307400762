import React from 'react';

import FormikSelectField, {
  FormikSelectFieldProps,
  FormikSelectOptions
} from '../../../common/formik/FormikSelectField';
import { UserRole } from '../user.interface';

const selectOptions: FormikSelectOptions[] = [
  { value: UserRole.controller, label: UserRole.controller },
  { value: UserRole.accounting, label: UserRole.accounting }
];

const UserRoleSelectField: React.FC<FormikSelectFieldProps> = (
  props: FormikSelectFieldProps
) => {
  return <FormikSelectField {...props} type='number' options={selectOptions} />;
};

export default UserRoleSelectField;
