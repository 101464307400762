import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface MessageAlertProps {
  title: string;
  body: any;
  open: boolean;
  onClose?: () => void;
}
const MessageAlert: React.FC<MessageAlertProps> = ({
  open,
  title,
  body,
  onClose
}) => {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ minWidth: 230 }}>
          <DialogContentText>{body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MessageAlert;
