import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Collapse
} from '@material-ui/core';
import { NavigationItem } from './NavigationList';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';

const AppMenuItem = (props: NavigationItem) => {
  const classes = useStyles();
  const { label, icon, path, roles, items = [] } = props;
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = useState(false);

  function handleClick() {
    if (!isExpandable) {
    } else {
      setOpen(!open);
    }
  }

  const MenuItemRoot = () => {
    if (path) {
      return (
        <Fragment>
          <ListItem
            button
            className={classes.menuItem}
            onClick={handleClick}
            to={path}
            activeClassName='active'
            component={NavLink}
          >
            {!!icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={label} inset={!icon} />
            {isExpandable && !open && <IconExpandMore />}
            {isExpandable && open && <IconExpandLess />}
          </ListItem>
        </Fragment>
      );
    } else {
      return (
        <ListItem button className={classes.menuItem} onClick={handleClick}>
          {!!icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText primary={label} inset={!icon} />
          {isExpandable && !open && <IconExpandMore />}
          {isExpandable && open && <IconExpandLess />}
        </ListItem>
      );
    }
  };

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout='auto' unmountOnExit>
      <Divider />
      <List component='div' disablePadding>
        {items.map((item, index) => (
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      <MenuItemRoot />
      {MenuItemChildren}
    </>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    menuItem: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 16,
      paddingRight: 16
    },
    menuItemIcon: {}
  })
);

export default AppMenuItem;
