import React, { useContext, useEffect, useRef, useState } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import NavigationList, { NavigationItem } from './NavigationList';
import logo from '../assets/logo';
import NavThemeProvider from '../NavThemeProvider';
import ProtectedRoute from '../common/ProtectedRoute';
import AdminDashboardPage from './admin-dashboard/AdminDashboardPage';
import TransactionListPage from './transaction/TransactionListPage';
import PaymentListPage from './payment/PaymentListPage';
import { UserContext } from '../user/User.context';
import { useLogout } from '../user/User.hooks';
import UserProfilePage from './users/UserProfilePage';
import PayMayaListPage from './paymaya/PayMayaListPage';
import GCashListPage from './gcash/GCashListPage';
import UserListPage from './users/UserListPage';
import LogListPage from './transaction/LogListPage';
import AdminRoute from '../common/AdminRoute';
import PaymongoListPage from './paymongo/PaymongoListPage';
import DashboardPage from './dashboard/DashboardPage';
import ChurchListPage from './church/ChurchListPage';
import DistrictListPage from './district/DistrictListPage';
import DragonpayTransactionListPage from './dragonpay/transactions';
import DragonpayPaymentListPage from './dragonpay/payments';

const StyledSecurePortal = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  nav {
    display: flex;
    flex-direction: column;
    z-index: 1;
    &.nav-close {
      transition-property: margin-left;
      transition-duration: ${props =>
        props.theme.transitions.duration.leavingScreen}ms;
      transition-timing-function: ${props =>
        props.theme.transitions.easing.sharp};
      margin-left: -260px;
      /* margin-left: -242px; */
    }
    &.nav-open {
      transition-property: margin-left;
      transition-duration: ${props =>
        props.theme.transitions.duration.enteringScreen}ms;
      transition-timing-function: ${props =>
        props.theme.transitions.easing.easeOut};
      margin-left: 0;
    }
    ${props => props.theme.breakpoints.down('md')} {
      &.nav-close {
        margin-left: 0;
      }
    }
  }
  .MuiToolbar-root .MuiSvgIcon-root {
    color: black;
  }
  main {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    z-index: 0;
  }
`;

const StyledDrawer = styled(Drawer)`
  width: 260px;
  .paper {
    width: 260px;
    background-color: #f1f3f3;
  }
`;

const StyledNavAppBar = styled(AppBar)``;

const StyledToolbar = styled(Toolbar)`
  padding-left: 0px;
  padding-right: 0px;
  align-items: flex-start;
  flex-direction: column;

  .content {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-container {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;

      .logo {
        display: block;
        margin: auto auto 5px auto;
      }
    }
  }
`;

const StyledMainAppBar = styled(AppBar)`
  background-color: #fff;
  .flex {
    flex: 1;
  }
`;

const useNavOpen = (
  isAboveMediumSize: boolean
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [isNavOpen, setIsNavOpen] = useState(isAboveMediumSize);
  const previousIsAboveMediumSizeRef = useRef(isAboveMediumSize);
  // only force nav open/close when a breakpoint change occurs
  if (isAboveMediumSize !== previousIsAboveMediumSizeRef.current) {
    if (!isNavOpen && isAboveMediumSize) {
      setIsNavOpen(true);
    } else if (isNavOpen && !isAboveMediumSize) {
      setIsNavOpen(false);
    }
  }
  useEffect(() => {
    // Store the previous breakpoint change
    previousIsAboveMediumSizeRef.current = isAboveMediumSize;
  }, [isAboveMediumSize]);

  return [isNavOpen, setIsNavOpen];
};

const SecurePortal = () => {
  const [
    profileMenuAnchor,
    setProfileMenuAnchor
  ] = useState<null | HTMLElement>(null);
  const { user } = useContext(UserContext);
  const { doLogout } = useLogout();
  const history = useHistory();
  const isAboveMediumSize = useMediaQuery<Theme>(
    theme => theme.breakpoints.up('lg'),
    { noSsr: true } // prevents 2 render calls on load
  );
  const [isNavOpen, setIsNavOpen] = useNavOpen(isAboveMediumSize);

  const handleDrawerToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleClickLogout = () => {
    setProfileMenuAnchor(null);
    doLogout();
  };

  const handleNavigationItemClick = () => {
    if (!isAboveMediumSize) {
      setIsNavOpen(false);
    }
  };

  return (
    <StyledSecurePortal>
      <nav className={isNavOpen ? 'nav-open' : 'nav-close'}>
        <NavThemeProvider>
          <StyledDrawer
            variant={isAboveMediumSize ? 'persistent' : 'temporary'}
            anchor='left'
            open={isNavOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: 'paper'
            }}
            PaperProps={{ elevation: 10 }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            <StyledNavAppBar position='static' elevation={1} square>
              <StyledToolbar>
                <div className='content'>
                  <Typography variant='h6' noWrap className='logo-container'>
                    <img
                      src={logo}
                      className='logo'
                      alt='CLC Online Giving'
                      width={180}
                    />
                  </Typography>
                </div>
              </StyledToolbar>
            </StyledNavAppBar>

            <NavigationList
              onNavigationItemClicked={handleNavigationItemClick}
              renderAfterItem={(item: NavigationItem) => {
                return null;
              }}
            />
          </StyledDrawer>
        </NavThemeProvider>
      </nav>
      <main>
        <StyledMainAppBar position='static' square>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <span className='flex'></span>
            <Button
              className='user-button'
              onClick={e => setProfileMenuAnchor(e.currentTarget)}
            >
              {`${user?.firstName} ${user?.lastName}`}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              id='profile-menu'
              anchorEl={profileMenuAnchor}
              keepMounted
              open={Boolean(profileMenuAnchor)}
              onClose={e => setProfileMenuAnchor(null)}
              style={{ marginLeft: 30, marginTop: 30 }}
            >
              <MenuItem onClick={() => history.push(`/portal/user-profile/`)}>
                Profile
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </StyledMainAppBar>
        <Switch>
          <ProtectedRoute
            exact
            path='/portal/admin-dashboard'
            component={AdminDashboardPage}
          />
          <ProtectedRoute
            exact
            path='/portal/dashboard'
            component={DashboardPage}
          />
          <ProtectedRoute
            exact
            path='/portal/transactions-dragonpay'
            component={DragonpayTransactionListPage}
          />
          <ProtectedRoute
            exact
            path='/portal/payments-dragonpay'
            component={DragonpayPaymentListPage}
          />
          <ProtectedRoute
            exact
            path='/portal/transactions'
            component={TransactionListPage}
          />
          <ProtectedRoute
            exact
            path='/portal/paymongo'
            component={PaymongoListPage}
          />
          <ProtectedRoute
            exact
            path='/portal/gcash'
            component={GCashListPage}
          />
          <ProtectedRoute
            exact
            path='/portal/paymaya'
            component={PayMayaListPage}
          />
          <ProtectedRoute
            exact
            path='/portal/payments'
            component={PaymentListPage}
          />
          <ProtectedRoute
            exact
            path='/portal/user-profile'
            component={UserProfilePage}
          />
          <ProtectedRoute
            exact
            path='/portal/district'
            component={DistrictListPage}
          />
          <ProtectedRoute
            exact
            path='/portal/church'
            component={ChurchListPage}
          />
          <ProtectedRoute exact path='/portal/logs' component={LogListPage} />
          <AdminRoute exact path='/portal/users' component={UserListPage} />
        </Switch>
      </main>
    </StyledSecurePortal>
  );
};

export default SecurePortal;
