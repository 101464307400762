import React from 'react';
import { ThemeProvider } from 'styled-components';

import blueGrey from '@material-ui/core/colors/blueGrey';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const NavThemeProvider: React.FC = ({ children }) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#fff'
      },
      secondary: {
        main: '#2196f3'
      }
    },
    overrides: {
      MuiListItem: {
        root: {
          '&.active': { backgroundColor: blueGrey[100] }
        }
      }
    }
  });
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  );
};

export default NavThemeProvider;
