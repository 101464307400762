import { Form, Formik, FormikValues } from 'formik';
import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import FormikButton from '../../common/formik/FormikButton';
import FormikGrid from '../../common/formik/FormikGrid';
import FormikTextField from '../../common/formik/FormikTextField';
import { useCreateUser } from '../../user/User.hooks';
import { User } from '../../user/User.interface';
import UserRoleSelectField from './components/UserRoleSelectField';
import { UserRole } from './user.interface';

const passwordMinLength = 8;
const passwordRegex: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/;
const passwordRegexErrorMessage =
  'Need at least an uppercase and a lowercase letter, and a number.';

interface CreateUserForm {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  password: string;
}

const validationSchema: Yup.ObjectSchema<CreateUserForm> = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  role: Yup.string().required('Required'),
  email: Yup.string().email().required('Email is Required.'),
  password: Yup.string()
    .required('Password is required.')
    .matches(passwordRegex, passwordRegexErrorMessage)
    .min(passwordMinLength, `Minimum ${passwordMinLength} characters.`)
});

export interface UserCreateDialogProps {
  open: boolean;
  onCancel?: () => void;
  onCreated?: (user: User) => void;
}

const UserCreateDialog: React.FC<UserCreateDialogProps> = ({
  open,
  onCancel,
  onCreated
}) => {
  const formikRef = useRef<FormikValues>();
  const { user, isLoading, error, post } = useCreateUser();
  useEffect(() => {
    if (!isLoading) {
      if (onCreated && user) {
        onCreated(user);
      }
      if (formikRef && formikRef.current) {
        formikRef.current.setSubmitting(false);
      }
    }
  }, [isLoading, onCreated, user]);

  return (
    <Dialog open={open} onClose={onCancel} maxWidth='xs'>
      <Formik
        innerRef={formik => (formikRef.current = formik)}
        initialValues={{ firstName: '', lastName: '', email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={(userDto: Partial<User>) => {
          post(userDto);
        }}
      >
        <Form autoComplete='off'>
          <DialogTitle>New User</DialogTitle>
          <DialogContent>
            <DialogContentText>Personal Information</DialogContentText>
            <FormikGrid>
              <FormikTextField
                autoFocus
                type='text'
                name='firstName'
                label='First Name'
              />
              <FormikTextField type='text' name='lastName' label='Last Name' />
              <FormikTextField type='text' name='email' label='Email' />
              <UserRoleSelectField
                required
                name='role'
                label='Role'
                placeholder='Select user role'
              />
              <FormikTextField
                type='password'
                name='password'
                label='Password'
              />
            </FormikGrid>

            {error && (
              <Alert severity='error'>
                {
                  error ? error.message.message : ''
                }
              </Alert>
            )}
          </DialogContent>
          <DialogActions style={{ marginBottom: 10 }}>
            <Button onClick={onCancel}>Cancel</Button>
            <FormikButton>Create</FormikButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default UserCreateDialog;
