import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CircularProgress, Grid } from '@material-ui/core';
import { Transaction } from '../interface/transaction.interface';
import { numberWithCommas } from '../../common/Util';
import { SERVER_URL } from '../../contant';
import { requestOptions } from '../../common/ApiCall';
import { MessageAlertProps } from '../../common/MessageAlert';
import { generateReferanceNo } from './generateReferenceNo';
import { PaymongoPaymentMethodEnum } from '../interface/paymentMethod.enum';

const CREATE_TRANSACTION = SERVER_URL + '/api/transaction';

export interface PaymongoTransactionConfitmProps {
  transaction: Transaction;
  open: boolean;
  onCancel: () => void;
  onSubmit: (id: number) => void;
  onError: (error: MessageAlertProps) => void;
}

const PaymongoTransactionConfitm: React.FC<PaymongoTransactionConfitmProps> = ({
  transaction,
  open,
  onCancel,
  onSubmit,
  onError
}) => {
  console.log("🚀 ~ file: PaymongoTransactionConfitm.tsx ~ line 202 ~ transaction", transaction)

  const [state, setState] = useState({
    transactionId: 0
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (open && isSubmitting) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const cardNumber = '**** **** **** ' + transaction.cardLast4Degit;

  const transactionRef = state.transactionId
    ? generateReferanceNo(state.transactionId, transaction.procId)
    : '';

  const saveTransaction = async (body: Transaction) => {
    try {
      await fetch(CREATE_TRANSACTION, requestOptions('POST', body))
        .then(response => response.json())
        .then(res => {
          if (res.success) {
            setState({
              transactionId: res.data.transaction_id
            });
          } else {
            onError({
              open: true,
              title: 'Error',
              body: 'Something went wrong. Please try again.'
            });
          }
        });
    } catch (e) {
      onError({
        open: true,
        title: 'Error',
        body: 'Cannot connect to server. Our server might be temporarily down.'
      });
    }
  };

  useEffect(() => {
    open && saveTransaction(transaction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setIsSubmitting(false);
    onCancel();
  };

  const handleSubmit = () => {
    if (state.transactionId) {
      setIsSubmitting(true);
      onSubmit(state.transactionId);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth={'xs'}
        disableBackdropClick={true}
        onClose={handleClose}
        open={open}
      >
        {transactionRef ? (
          <React.Fragment>
            <DialogTitle id='customized-dialog-title' onClose={handleClose}>
              Transaction Reference
            </DialogTitle>
            <DialogContent dividers style={{ width: '93%' }}>
              <Typography gutterBottom>
                <b>Reference Number:</b>{' '}
                <span style={{ textTransform: 'uppercase' }}>
                  {transactionRef}
                </span>
              </Typography>
              <Typography gutterBottom>
                <b>Name: </b>
                {`${transaction.firstName} ${transaction.lastName}`}
              </Typography>
              <Typography gutterBottom>
                <b>Email: </b>
                {transaction.email}
              </Typography>
              <TableContainer>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Allocation</TableCell>
                      <TableCell align='right'>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        Tithe:
                      </TableCell>
                      <TableCell align='right'>
                        {transaction.tithe
                          ? Number.isInteger(transaction.tithe)
                            ? numberWithCommas(transaction.tithe) + '.00'
                            : numberWithCommas(transaction.tithe)
                          : '0.00'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        Offering:
                      </TableCell>
                      <TableCell align='right'>
                        {transaction.offering
                          ? Number.isInteger(transaction.offering)
                            ? numberWithCommas(transaction.offering) + '.00'
                            : numberWithCommas(transaction.offering)
                          : '0.00'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        {'Other'}:
                      </TableCell>
                      <TableCell align='right'>
                        {transaction.otherPurpose[0].amount
                          ? Number.isInteger(transaction.otherPurpose[0].amount)
                            ? numberWithCommas(
                                transaction.otherPurpose[0].amount
                              ) + '.00'
                            : numberWithCommas(
                                transaction.otherPurpose[0].amount
                              )
                          : '0.00'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        <b>Total Amount:</b>
                      </TableCell>
                      <TableCell align='right'>
                        {Number.isInteger(transaction.total)
                          ? numberWithCommas(transaction.total) + '.00'
                          : numberWithCommas(transaction.total)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <PaymentMethodView method={transaction.procId as any} />
              {transaction.procId === PaymongoPaymentMethodEnum.card && (
                <Typography gutterBottom>
                  <b>Card Number: </b>
                  {cardNumber}
                </Typography>
              )}
              <Typography gutterBottom>
                <b>Status: </b>
                <i>Pending</i>
              </Typography>
            </DialogContent>
            <DialogActions
              style={{ textAlign: 'center', justifyContent: 'center' }}
            >
              {isSubmitting ? (
                <CircularProgress style={{ margin: 20 }} />
              ) : (
                <Button
                  autoFocus
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: '#00c1ff',
                    color: 'white',
                    padding: 5,
                    margin: 5,
                    width: 100
                  }}
                >
                  Submit
                </Button>
              )}
            </DialogActions>
          </React.Fragment>
        ) : (
          <div>
            <CircularProgress style={{ margin: 50 }} />
          </div>
        )}
      </Dialog>
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const GCashImage = require('../../assets/gcash-image.png');
const PayMayaImage = require('../../assets/paymaya_logo.png');
const GrabPayImage = require('../../assets/grabpay-image.png');
const CardImage = require('../../assets/card_image.png');

const PaymentMethodView = (props: { method: PaymongoPaymentMethodEnum }) => {
  const { method } = props;

  const MethodImage = () => {
    if (method === PaymongoPaymentMethodEnum.gcash) {
      return (
        <img
          alt={method}
          src={GCashImage}
          style={{ marginLeft: 5, height: 20, position: "absolute", marginTop: 0 }}
        />
      );
    }
    if (method === PaymongoPaymentMethodEnum.payMaya) {
      return (
        <img
          alt={method}
          src={PayMayaImage}
          style={{ marginLeft: 5, height: 20, position: "absolute", marginTop: 0 }}
        />
      );
    }
    if (method === PaymongoPaymentMethodEnum.grabPay) {
      return (
        <img
          alt={method}
          src={GrabPayImage}
          style={{ marginLeft: 5, height: 20, position: "absolute", marginTop: 0 }}
        />
      );
    }
    if (method === PaymongoPaymentMethodEnum.card) {
      return (
        <img
          alt={method}
          src={CardImage}
          style={{ marginLeft: 5, height: 20, position: "absolute", marginTop: 0 }}
        />
      );
    }

    return null;
  };
  return (
    <Grid item md={12} xs={12} style={{ marginTop: 15, marginBottom: 10 }}>
      <b>Payment Method: </b>
      <MethodImage />
    </Grid>
  );
};

export default PaymongoTransactionConfitm;