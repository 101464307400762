import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  CircularProgress
} from '@material-ui/core';
// import PageTemplate from '../../common/page-template/PageTemplate';
// import PageTemplateCardOver from '../../common/page-template/PageTemplateCardOver';
// import PageTemplateHeader, {
//   PageTemplateHeaderActions
// } from '../../common/page-template/PageTemplateHeader';
// import TransactionSearchBox from '../../common/TransactionSearchBox';
// import { useFindTransactions } from './paymongo.hook';
// import { formatDate } from '../../common/formatter';
// import PaymongoDetailDialog from './PaymongoDetailDialog';
import { numberWithCommas } from '../../../common/Util';
import { useFindDragonpayTransactions, useFindTransactions } from '../../transaction/Transaction.hook';
import { formatDate } from '../../../common/formatter';
import PageTemplate from '../../../common/page-template/PageTemplate';
import PageTemplateCardOver from '../../../common/page-template/PageTemplateCardOver';
import PageTemplateHeader, { PageTemplateHeaderActions } from '../../../common/page-template/PageTemplateHeader';
import TransactionSearchBox from '../../../common/TransactionSearchBox';
import DragonpayDetailDialog from '../DragonpayDetailDialog';
import { useFindDragonpayPayments } from '../../payment/Payment.hook';
import ExportDragonpayDialog from '../ExportDialog';

const CenterText = styled.div`
  text-align: center;
`;

const DragonpayPaymentListPage: React.FC = () => {
  const [openExport, setOpenExport] = useState<boolean>(false);
  const [searchHolder, setSearchHolder] = useState<any>('');
  const [search, setSearch] = useState<any>('');
  const [onSubmitSearch, setOnSubmitSearch] = useState(false);
  const [searchType, setSearchType] = useState<any>('id');
  const [page, setPage] = useState<any>();
  const [limit, setLimit] = useState<any>();
  const [order, setOrder] = useState<any>();
  const [orderDirection, setOrderDirection] = useState<any>();
  const [viewTransaction, setViewTransaction] = useState<any>();
  const { paymentList, isLoading, get } = useFindDragonpayPayments();

  useEffect(() => {
    const params = {
      search: search,
      searchType: searchType,
      page: Number(page) || 0,
      limit: Number(limit) || 10,
      order: String(order || '')
    } as any;
    if (order && orderDirection) {
      params.order = `${order} ${orderDirection}`;
    }
    get(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get, page, limit, order, orderDirection, search, onSubmitSearch]);

  const handleSort = (column: string) => {
    setOrder(column);
    setOrderDirection(orderDirection === 'ASC' ? 'DESC' : 'ASC');
  };

  const getSortDirection = (column: string): 'asc' | 'desc' => {
    return order === column && orderDirection === 'DESC' ? 'desc' : 'asc';
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  return (
    <PageTemplate>
      <PageTemplateHeader>
        <h2>Payments</h2>
        <TransactionSearchBox
          value={searchHolder}
          onSearch={value => setSearchHolder(value)}
          onSubmit={() => {
            setSearch(searchHolder);
            setOnSubmitSearch(!onSubmitSearch);
          }}
          onSelect={data => setSearchType(data)}
        />
        {/* <PageTemplateHeaderActions>
          <Button
            variant='contained'
            color='primary'
            onClick={() => setOpenExport(true)}
          >
            Export
          </Button>
        </PageTemplateHeaderActions> */}
      </PageTemplateHeader>
      {isLoading ? (
        <div style={{ padding: 100, height: 500 }}>
          <CircularProgress />
        </div>
      ) : (
          <PageTemplateCardOver>
            <TableContainer style={{ minHeight: 450 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>

                    <TableCell>
                      <TableSortLabel
                        active={order === 'transaction.created_at'}
                        direction={getSortDirection('transaction.created_at')}
                        onClick={() => handleSort('transaction.created_at')}
                      >
                        Date
                    </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      Reference Number
                  </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={order === 'transaction.id'}
                        direction={getSortDirection('transaction.id')}
                        onClick={() => handleSort('transaction.id')}
                      >
                        Payment Method
                    </TableSortLabel>
                    </TableCell>
                    <TableCell align='right'>Total Amount</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={order === 'last_name'}
                        direction={getSortDirection('last_name')}
                        onClick={() => handleSort('last_name')}
                      >
                        Last Name
                    </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={order === 'first_name'}
                        direction={getSortDirection('first_name')}
                        onClick={() => handleSort('first_name')}
                      >
                        First Name
                    </TableSortLabel>
                    </TableCell>

                    <TableCell>
                      <TableSortLabel
                        active={order === 'email'}
                        direction={getSortDirection('email')}
                        onClick={() => handleSort('email')}
                      >
                        Email Address
                    </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={order === 'church_name'}
                        direction={getSortDirection('church_name')}
                        onClick={() => handleSort('church_name')}
                      >
                        Church
                    </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={order === 'area'}
                        direction={getSortDirection('area')}
                        onClick={() => handleSort('area')}
                      >
                        Area
                    </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      Transaction ID
                  </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentList?.data?.map((transaction: any) => (
                    <TableRow
                      hover
                      key={transaction.referenceId}
                      onClick={() => setViewTransaction(transaction)}
                    >
                      <TableCell>
                        {formatDate(transaction.createdAt, true)}
                      </TableCell>
                      <TableCell>
                        {transaction.referenceNumber}
                      </TableCell>
                      <TableCell>{transaction.paymentMethod}</TableCell>
                      <TableCell align='right'>
                        ₱ {numberWithCommas(transaction.total)}
                      </TableCell>
                      <TableCell>{transaction.lastName}</TableCell>
                      <TableCell>{transaction.firstName}</TableCell>
                      <TableCell>{transaction.email}</TableCell>
                      <TableCell>
                        {transaction.churchName ? (
                          transaction.churchName
                        ) : (
                            <CenterText>-</CenterText>
                          )}
                      </TableCell>
                      <TableCell>
                        {transaction.area ? transaction.area : <CenterText>-</CenterText>}
                      </TableCell>
                      <TableCell>
                        {transaction.txnid}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20]}
              component='div'
              count={paymentList?.total || 0}
              rowsPerPage={paymentList?.limit || 10}
              page={paymentList?.page ? paymentList?.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowPerPage}
            />
          </PageTemplateCardOver>
        )}
      {Boolean(viewTransaction) && (
        <DragonpayDetailDialog
          open={Boolean(viewTransaction)}
          transaction={viewTransaction}
          onCancel={() => setViewTransaction(null)}
        />
      )}
      <ExportDragonpayDialog open={openExport} onCancel={() => setOpenExport(false)} />
    </PageTemplate>
  );
};

export default DragonpayPaymentListPage;
