import { reloadApp } from '../../user/User.hooks';
import { useEffect } from 'react';
import { TransactionPaid, ExportTransactionDto } from './paymongo.interface';
import {
  useApiGet,
  useApiPost,
  useApiPatch,
  ApiResult
} from '../../common/api/Api.hooks';
import { PaginatedList } from '../../common/PaginatedList.interface';

interface ApiResponse {
  success: boolean;
  data?: any;
}

export function useFindTransactions() {
  const { data, isLoading, error, get } = useApiGet<
    PaginatedList<TransactionPaid>
  >('transaction/paymongo');
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  return { transactionList: data, isLoading, error, get };
}

export function useExportTransactions(): [
  ApiResult<any>,
  (date: ExportTransactionDto) => void
] {
  const { post: apiPost, error, ...result } = useApiPost<ExportTransactionDto>(
    'transaction/export-paymongo'
  );
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  const post = (date: ExportTransactionDto) => apiPost(date);
  return [result, post];
}

export function useMarkAsPaid(id: number) {
  const { data, isLoading, error, patch } = useApiPatch<any>(
    `transaction/${id}`
  );
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  return { transaction: data, isLoading, error, patch };
}

export function useFindLogs() {
  const { data, isLoading, error, get } = useApiGet<PaginatedList<any>>(
    'transaction/log'
  );
  useEffect(() => {
    reloadApp(error);
  }, [error]);
  return { logList: data, isLoading, error, get };
}
