import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

const StyledFooter = styled.div`
  background-color: #31a27c;
  padding: 30px;
  font-size: 15px;
  color: #fff;
`;
const Footer = () => {
  return (
    <StyledFooter>
      <div>
        <Grid container spacing={0}>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <b>ADDRESS </b>
                <p style={{ fontSize: '1em', lineHeight: '1.5em' }}>
                  20 Governor Pascual Avenue, Potrero, Malabon City, Metro
                  Manila 1475
                </p>
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <b>CONTACT</b>
                <p style={{ fontSize: '1em', lineHeight: '1.5em' }}>
                  PLDT- (02) 361 8850 / (02) 361 8839 / (02) 361 8817 BayanTel –
                  (02) 448-24-98 / (02) 448 1522 Fax – 361-99-18
                </p>
              </div>
            </Grid>
            <Grid item md={2} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <b>EMAIL</b>
                <p>giving@adventist.ph</p>
                <p>support@clcgiving.online</p>
              </div>
            </Grid>
            <Grid item md={2} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <b>SOCIAL MEDIA</b>
                <div>
                  <a href='https://www.facebook.com/centralluzonconference'>
                    <div style={{ marginTop: 20 }}>
                      <img
                        alt=''
                        style={{ height: 35 }}
                        src={require('../../assets/find us on facebook.png')}
                      />
                    </div>
                  </a>
                </div>
              </div>
            </Grid>
            <Grid item md={2} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <a href='/FAQ' style={{ color: 'white' }}>
                  <p>Frequently Asked Questions</p>
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </StyledFooter>
  );
};

export default Footer;
