import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Footer from '../home/components/Footer';

const StyledHeader = styled.div`
  width: 100%;
  background-color: #31a27c;
  padding-top: 0.5rem;
  color: #FFF;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  .header-container {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-self: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  img {
    width: 150px;
  }
  .right {
    color: #fff;
    font-weight: 500;
  }
`;

const StyledBody = styled.div`
  width: 100%;
  font-size: 15px;
  padding-bottom: 40px;
  .body-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .item {
    margin-top: 50px;
  }
  .question {
    text-align: left;
    font-size: 20px;
    font-weight: 1000;
  }
  .answer {
    text-align: left;
    font-size: 15px;
    font-weight: 400;
  }
`;

const FAQPage = () => {
  return (
    <React.Fragment>
        <StyledHeader>
            <div className='header-container'>
            <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                <h1 style={{ fontSize: '2.5em' }}>Thank You!</h1>
                </Grid> */}
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <div style={{ textAlign: 'center' }}>
                        <img alt='' src={require('../assets/sda-logo.png')} />
                        </div>
                        <div>
                        <h2>CENTRAL LUZON CONFERENCE</h2>
                        <p style={{ fontSize: '3em', fontWeight: 'bold', margin: 20 }}>
                            ONLINE GIVING
                        </p>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
                <div>
                    <h1 style={{ fontSize: '2.6em'}}>
                    Frequently Asked Questions
                    </h1>
                </div>
                </Grid>
                {/* <Grid item xs={12} style={{ marginTop: 10, marginBottom: 50 }}>
                <div>
                    <Link
                    to='/'
                    className='btn btn-primary'
                    style={{
                        backgroundColor: '#fb8c04',
                        color: 'white',
                        padding: 15,
                        width: 150,
                        height: 100,
                        textDecoration: 'none'
                    }}
                    >
                    Go Back to Home Page
                    </Link>
                </div>
                </Grid> */}
            </Grid>
            </div>
        </StyledHeader>
        <StyledBody>
            <Grid container spacing={1}>
                <Grid item md={3}></Grid>
                <Grid item md={6} xs={12}>
                    <div className='body-container' style={{ textAlign: "center"}}>
                        <div className='item'>
                            <p className='question'>When can we start using the program?</p>
                            <p className='answer'>- The online giving system is now available for use.</p>
                        </div>
                        <div className='item'>
                            <p className='question'>What payment types do you accept?</p>
                            <p className='answer'>- We currently accept VISA and Mastercard debit and credit cards, GCash, PayMaya and GrabPay.</p>
                        </div>
                        <div className='item'>
                            <p className='question'>Who will know what I am giving?</p>
                            <p className='answer'>- We value your privacy and prioritize your security in this online program. Security measures are in place to keep you protected. For more information, read our Privacy Policy.</p>
                        </div>
                        <div className='item'>
                            <p className='question'>How will I know if my transaction is successful?</p>
                            <p className='answer'>- You will receive an email confirmation.</p>
                        </div>
                        <div className='item'>
                            <p className='question'>Can I give specific donations other than tithes and offerings?</p>
                            <p className='answer'>- Yes, simply fill the "others" portion and specify the fund or donation you want to give.</p>
                        </div>
                        <div className='item'>
                            <p className='question'>How do the local church keep track of the donation given thru this online giving?</p>
                            <p className='answer'>- We will send a monthly summary report to the local church treasurers. </p>
                        </div>
                        <div className='item'>
                            <p className='question'>Who will issue my receipt?</p>
                            <p className='answer'>- The Central Luzon Conference will send your receipt to your email a week after your successful transaction.</p>
                        </div>
                        <div className='item'>
                            <p className='question'>Who will pay the bank and other transaction fees?</p>
                            <p className='answer'>- Central Luzon Conference will shoulder any transaction fees incurred in this online giving.</p>
                        </div>
                        <div className='item'>
                            <p className='question'>Can I still use the cash and check method of giving?</p>
                            <p className='answer'>- Yes. We are just introducing a new platform where you can easily return your tithes and give your offerings.</p>
                        </div>
                        <div className='item'>
                            <p className='question'>What if I made an error and need a correction or refund?</p>
                            <p className='answer'>- In case you have  processed the same transaction/donation twice or there's an error in the amount you've given or any other corrections you may email giving@adventist.ph. </p>
                            <p className='answer'>- We may need to verify that your transaction was successful before processing the refund.</p>
                            <p className='answer'>- You may only request refund within 30 days and any bank fees or transaction incurred will be charged to the giver.</p>
                        </div>
                        <div className='item'>
                            <p className='question'>Does this system give more money to the conference?</p>
                            <p className='answer'>- No. All percentages and destinations remain the same as the regular system</p>
                        </div>
                        <div className='item'>
                            <p className='question'>If I give one offering, will my local church still receive the 50% share?</p>
                            <p className='answer'>- Yes, the local church will still receive the 50% offering. We will record the 50%  under the church account release it to the local church..</p>
                        </div>
                        <div className='item'>
                            <p className='question'>Can I give donation for local church fund and other local church projects?</p>
                            <p className='answer'>- Yes. We will record it under the church account and release the fund to the specific local church</p>
                        </div>
                        <div className='item'>
                            <p className='question'>Is it acceptable to go into credit card debt when making a contribution?</p>
                            <p className='answer'>- The program does not encourage debt. The CLC online giving  option is for the convenience of those who prefer to do their banking and financial transactions online.</p>
                        </div>
                        <div className='item'>
                            <p className='question'>Is this just a strategy to get more money from church members?</p>
                            <p className='answer'>- No. Many church members are expressing an interest in online giving, and technology offers the means to do so conveniently and securely.</p>
                            <p className='answer'>- Although we realize this option is not ideal for everyone, it is an instrument that serves the needs of many people.</p>
                        </div>
                        
                    </div>
                </Grid>
                <Grid item md={3}></Grid>
            </Grid>
        </StyledBody>
        <Footer />
    </React.Fragment>
  );
};

export default FAQPage;
