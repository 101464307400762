import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import PaymongoFooter from '../home/components/PaymongoFooter';
import Footer from '../home/components/Footer';

const StyledHeader = styled.div`
  width: 100%;
  background-color: #31a27c;
  color: #FFF;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  .header-container {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-self: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  img {
    width: 150px;
  }
  .right {
    color: #fff;
    font-weight: 500;
  }
`;

const SuccessPage = () => {
  return (
    <React.Fragment>
      <StyledHeader>
        <div className='header-container'>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h1 style={{ fontSize: '2.5em' }}>Payment Successful!</h1>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 40 }}>
              <div>
                <h1 style={{ fontSize: '1.5em', lineHeight: '1.5em' }}>
                  Thank you for honoring God with your tithes and offerings!
                </h1>
                <p style={{ fontSize: '1.5em', lineHeight: '1.5em' }}>
                  We’ll send the receipt to you the soonest possible time.
                  Meanwhile, if you have any inquiries, you may reach us through
                  the email address giving@adventist.ph.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10, marginBottom: 50 }}>
              <div>
                <Link
                  to='/'
                  className='btn btn-primary'
                  style={{
                    backgroundColor: '#fb8c04',
                    color: 'white',
                    padding: 15,
                    width: 150,
                    height: 100,
                    textDecoration: 'none'
                  }}
                >
                  Go Back to Home Page
                </Link>
              </div>
            </Grid>
          </Grid>
        </div>
      </StyledHeader>
      <Footer />
      {/* <PaymongoFooter /> */}
    </React.Fragment>
  );
};

export default SuccessPage;
