import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export const OUTSIDE_CLC = { label: 'Outside CLC', value: '0' };

export interface AreaInputProps {
  area: string;
  setArea: (value: string) => void;
}
const AreaInput: React.FC<AreaInputProps> = ({ area, setArea }) => {
  const [selectedArea, setSelectedArea] = useState(area);

  useEffect(() => {
    area && setSelectedArea(area);
  }, [area]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setArea(event.target.value as string);
    setSelectedArea(event.target.value as string);
  };

  return (
    <FormControl variant='outlined' style={{ width: '100%' }}>
      <InputLabel>Area</InputLabel>
      <Select value={selectedArea} onChange={handleChange} label='Area'>
        <MenuItem value=''>
          <em>Select Area</em>
        </MenuItem>
        <MenuItem value={'1'}>1</MenuItem>
        <MenuItem value={'2'}>2</MenuItem>
        <MenuItem value={'3'}>3</MenuItem>
        <MenuItem value={'4'}>4</MenuItem>
        <MenuItem value={'5'}>5</MenuItem>
        <MenuItem value={'6'}>6</MenuItem>
        <MenuItem value={'7'}>7</MenuItem>
        <MenuItem value={'8'}>8</MenuItem>
        <MenuItem value={'9'}>9</MenuItem>
        <MenuItem value={'10'}>10</MenuItem>
        <MenuItem value={OUTSIDE_CLC.value}>{OUTSIDE_CLC.label}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default AreaInput;
