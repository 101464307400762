import React from 'react';
import GiverForm from './GiverForm';

const GiverView = ({ processors }: any) => {
  return (
    <div style={styles.container}>
      <div style={styles.heading}>
        <div style={styles.title}>Giving Form</div>
      </div>
      <GiverForm/>
    </div>
  );
};

const styles: any = {
  container: {
    flex: 1,
    flexDirection: 'column',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f9f9f9'
  },
  heading: {
    color: '#545454',
    fontWeight: 500,
    margin: 30,
    fontSize: '1.3em'
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold'
  }
};

export default GiverView;
