import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Axios from 'axios';
import { PUBLIC_KEY } from '../../contant';
import { CreateCardPayment } from '../../Api/createCardPayment';
import { MessageAlertProps } from '../../common/MessageAlert';

export interface AwaitNext3DAuthModalProps {
  open: boolean;
  props: any;
  onClose: any;
  onSubmit: any;
  onError: (message: MessageAlertProps) => void;
}
const AwaitNext3DAuthModal: React.FC<AwaitNext3DAuthModalProps> = ({
  open,
  props,
  onError,
  onClose,
  onSubmit
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    onClose();
  };

  const handleError = (message: string) => {
    onError({ open: true, title: 'Transaction failed', body: message });
  };

  const iframe =
    '<iframe src="' +
    props.attributes.next_action.redirect.url +
    '" width="400" height="700" ></iframe>';

  const Iframe = () => {
    return {
      __html: iframe
    };
  };

  const getPaymentIntent = () => {
    var clientKey = props.attributes.client_key;

    Axios.get(
      'https://api.paymongo.com/v1/payment_intents/' +
        props.id +
        '?client_key=' +
        clientKey,
      {
        headers: {
          // Base64 encoded public PayMongo API key.
          Authorization: `Basic ${PUBLIC_KEY}`
        }
      }
    ).then((response: any) => {
      var paymentIntent = response.data.data;
      var paymentIntentStatus = paymentIntent.attributes.status;

      if (paymentIntentStatus === 'succeeded') {
        CreateCardPayment(paymentIntent.id);
      } else if (paymentIntentStatus === 'awaiting_payment_method') {
        const { last_payment_error } = paymentIntent.attributes;
        if (last_payment_error.failed_code === 'processor_unavailable') {
          handleError(
            'Wait for a few minutes before re-trying the transaction. Else, use a different card or other form of payment.'
          );
        } else {
          handleError(last_payment_error.failed_message);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener(
      'message',
      ev => {
        if (ev.data === '3DS-authentication-complete') {
          getPaymentIntent();
        }
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Dialog
        maxWidth={'xs'}
        disableBackdropClick={false}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <div dangerouslySetInnerHTML={Iframe()} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AwaitNext3DAuthModal;
