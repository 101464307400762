import React, { useEffect, useState } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { Transaction } from '../interface/transaction.interface';
import { numberWithCommas } from '../../common/Util';
import { SERVER_URL } from '../../contant';
import { requestOptions } from '../../common/ApiCall';
import { MessageAlertProps } from '../../common/MessageAlert';
import { generateReferanceNo } from './generateReferenceNo';
import PaymentMethodView from './PaymentMethodView';
import { Processors } from '../../common/dragonpay/ProcessorInfo';
import { useCreateDragonpayTransaction } from '../Giver.hook';

const CREATE_TRANSACTION = SERVER_URL + '/api/transaction';

export interface TransactionConfirmProps {
  processors: any;
  transaction: Transaction | any;
  open: boolean;
  onCancel: () => void;
  onSubmit: (id: number) => void;
  onError: (error: MessageAlertProps) => void;
}

const TransactionConfirm: React.FC<TransactionConfirmProps> = ({
  processors,
  transaction,
  open,
  onCancel,
  onSubmit,
  onError
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const processor = processors.find((i: any) => i.procId._text === transaction.procId);
  const { dragonpayResponse, isLoading, createDragonpayTransaction} = useCreateDragonpayTransaction()
  const [showMaxAmountError, setShowMaxAmountError] = useState<boolean>(false);
  const [paymentURL, setPaymentURL] = useState<any>(null);

  if (processor) {
    const maxAmount = processor?.maxAmount;
    if (maxAmount && transaction?.total > maxAmount) {
      setShowMaxAmountError(true)
    }
  }

  useEffect(() => {
    if (dragonpayResponse?.data?.Url && dragonpayResponse?.data?.Status === 'S') {
      setPaymentURL(dragonpayResponse?.data?.Url)
    }
  }, [dragonpayResponse])
 
  const iframe = '<iframe src="' + paymentURL + '" width="768" height="700" ></iframe>';

  const Iframe = () => {
    return {
      __html: iframe
    };
  };
 

  const handleClose = () => {
    if (paymentURL) {
      window.location.reload();
    } else {
      setIsSubmitting(false);
      onCancel();
    }
  };

  const handleSubmit = () => {
    createDragonpayTransaction({...transaction, processor})
  };

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        disableBackdropClick={true}
        onClose={handleClose}
        open={open}
      >
            <React.Fragment>
            <DialogTitle id='customized-dialog-title' onClose={handleClose}>
              { paymentURL ? 'Transaction' : 'Transaction'}
            </DialogTitle>
            { paymentURL ? <div dangerouslySetInnerHTML={Iframe()} /> : (
              <>
              <DialogContent dividers style={{ width: '93%' }}>
                <Typography gutterBottom>
                <b>Name: </b>
                {`${transaction.firstName} ${transaction.lastName}`}
              </Typography>
              <Typography gutterBottom>
                <b>Email: </b>
                {transaction.email}
              </Typography>
              <Typography gutterBottom>
                <b>Contact Number: </b>
                <span>
                  {transaction.contactNumber}
                </span>
              </Typography>
              <Typography gutterBottom>
                <b>Church: </b>
                <span>
                  {transaction.church.name}
                </span>
              </Typography>
              <Typography gutterBottom>
                <b>District: </b>
                <span>
                  {transaction?.church.district as any}
                </span>
              </Typography>
              <Typography gutterBottom>
                <b>Area: </b>
                <span>
                  {transaction?.church.area as any}
                </span>
              </Typography>
              <TableContainer>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Allocation</TableCell>
                      <TableCell align='right'>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        Tithe:
                      </TableCell>
                      <TableCell align='right'>
                      ₱{transaction.tithe
                          ? Number.isInteger(transaction.tithe)
                            ? numberWithCommas(transaction.tithe) + '.00'
                            : numberWithCommas(transaction.tithe)
                          : '0.00'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        Offering:
                      </TableCell>
                      <TableCell align='right'>
                      ₱{transaction.offering
                          ? Number.isInteger(transaction.offering)
                            ? numberWithCommas(transaction.offering) + '.00'
                            : numberWithCommas(transaction.offering)
                          : '0.00'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        {'Other'}:
                      </TableCell>
                      <TableCell align='right'>
                      ₱{transaction.otherPurpose[0].amount
                          ? Number.isInteger(transaction.otherPurpose[0].amount)
                            ? numberWithCommas(
                                transaction.otherPurpose[0].amount
                              ) + '.00'
                            : numberWithCommas(
                                transaction.otherPurpose[0].amount
                              )
                          : '0.00'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        <b>Total Amount:</b>
                      </TableCell>
                      <TableCell align='right'>
                        <b>₱{Number.isInteger(transaction.total)
                            ? numberWithCommas(transaction.total) + '.00'
                            : numberWithCommas(transaction.total)}
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {processor && <PaymentMethodView processor={processor} />}
            </DialogContent>
            <DialogActions
              style={{ textAlign: 'center', justifyContent: 'center' }}
            >
              {isSubmitting ? (
                <CircularProgress style={{ margin: 20 }} />
              ) : (
                <>
                <Button
                  autoFocus
                  onClick={handleClose}
                  style={{
                    backgroundColor: 'rgb(189, 189, 189)',
                    color: 'white',
                    padding: 5,
                    margin: 5,
                    width: 100
                  }}
                >
                  Cancel
                </Button>
                <Button
                  autoFocus
                  onClick={handleSubmit}
                  disabled={isLoading}
                  style={{
                    backgroundColor: '#00c1ff',
                    color: 'white',
                    padding: 5,
                    margin: 5,
                    width: 100
                  }}
                >
                  Submit
                </Button>
                </>
              )}
            </DialogActions>
            {isLoading && <LinearProgress />}
            </>
            )}   
          </React.Fragment>
      </Dialog>
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default TransactionConfirm;
